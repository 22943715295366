var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.afficherPage)?_c('v-container',{class:{ 'col-8': _vm.estTailleDesktop }},[_c('formulaire',{on:{"soumettre":_vm.onCreerDemande}},[_c('alerte',{attrs:{"type":"info","message":_vm.$t('demande.programme.avertissement').toString()}}),_c('section-formulaire',{attrs:{"titre":_vm.$t('demande.programme.programme-a-modifier').toString()}},[_c('champ-formulaire',{attrs:{"validation":"requis","nom":_vm.$t('demande.modification.champs.session.nom').toString()},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return _c('select-autocomplete',{attrs:{"errors":errors,"label":_vm.$t(
              'demande.programme.formulaire.champs.session-vigueur'
            ).toString(),"items":_vm.itemsSessions},model:{value:(_vm.demandeModificationProgramme.codeSession),callback:function ($$v) {_vm.$set(_vm.demandeModificationProgramme, "codeSession", $$v)},expression:"demandeModificationProgramme.codeSession"}})}}],null,false,728225219)}),_c('section-identification-programme-majeure',{attrs:{"facultes":_vm.facultes,"facultes-rattachement":_vm.facultesRattachement,"roles":_vm.roles,"est-en-modification":_vm.estEnModification},model:{value:(_vm.demandeModificationProgramme),callback:function ($$v) {_vm.demandeModificationProgramme=$$v},expression:"demandeModificationProgramme"}})],1),_c('section-formulaire',{staticClass:"mt-4",attrs:{"titre":_vm.titreSectionApprobation}},[(_vm.afficherSectionModificationsApportees)?_c('section-modifications-apportees',{attrs:{"indicateurs-programme":_vm.indicateursModificationProgramme},on:{"changeIndicateursProgramme":_vm.onChangeIndicateursProgramme}}):_c('p',{staticStyle:{"font-size":"16px"},domProps:{"innerHTML":_vm._s(_vm.$t('demande.programme.nouvelle-majeure.information'))}})],1),_c('section-approbation',{attrs:{"indicateurs-programme":_vm.indicateursModificationProgramme},model:{value:(_vm.demandeModificationProgramme),callback:function ($$v) {_vm.demandeModificationProgramme=$$v},expression:"demandeModificationProgramme"}}),_c('section-documents',{attrs:{"demande-document-existants":_vm.demandeDocumentExistants},on:{"change":function($event){_vm.nouveauxFichiers = $event},"supprimer-demande-document":function($event){return _vm.documentExistantsASupprimer.push($event)},"supprimer-all-documents":_vm.supprimerAllDocuments}}),_c('div',{class:[
        _vm.estTailleDesktop ? 'flex-row' : 'flex-column',
        'd-flex',
        'mt-8',
      ]},[_c('v-btn',{ref:"creation-demande-faculte-formulaire__enregistrer-et-continuer",staticClass:"primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.enregistrer-et-continuer'))+" ")]),_c('v-btn',{class:[_vm.estTailleDesktop ? 'ml-4' : 'mt-6'],on:{"click":_vm.onClickAnnuler}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.annuler'))+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }