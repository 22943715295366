<template>
  <v-alert border="left" colored-border type="info" elevation="2">
    {{ $t('demande.creation.information.approbations.message') }}
    <ul>
      <li>
        {{ $t('demande.creation.information.approbations.role-1') }}
      </li>
      <li>
        {{ $t('demande.creation.information.approbations.role-2') }}
      </li>
      <li>
        {{ $t('demande.creation.information.approbations.role-3') }}
      </li>
    </ul>
  </v-alert>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'CreationDemandeFaculteInformationApprobation',
  });
</script>
