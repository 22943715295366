<template>
  <validation-provider
    v-slot="{ errors }"
    :custom-messages="messageErreurChargeTravail"
    :rules="`oneOf:0,${totalHeuresValides}`"
    :nom="$t('demande.formulaires.section.charge-de-travail')"
    mode="eager"
  >
    <div class="d-flex flex-row align-center mt-4">
      <h4 class="text-h6 mr-1">
        {{ $t('demande.formulaires.section.charge-de-travail') }}
      </h4>
      <tooltip
        :messages="[
          $t('demande.formulaires.champs.charge-de-travail.infobulle'),
        ]"
      />
    </div>
    <v-input :value="totalHeures" class="d-flex" :errors="errors">
      <div>
        <champ-formulaire>
          <v-row>
            <v-col xl="10" lg="10" md="8" sm="7">
              <v-label class="theme--light text--lighten-2">{{
                $t(
                  'demande.formulaires.champs.charge-de-travail.cours-magistraux'
                )
              }}</v-label>
            </v-col>
            <v-col xl="2" lg="2" md="4" sm="5">
              <stepper-nombre
                v-model="demandeCourante.heuresMagistrales"
                :precision="3"
                :min="0"
                :max="999.999"
                :nom="
                  $t(
                    'demande.formulaires.champs.charge-de-travail.cours-magistraux'
                  )
                "
              />
            </v-col>
          </v-row>
        </champ-formulaire>
        <champ-formulaire>
          <v-row>
            <v-col xl="10" lg="10" md="8" sm="7">
              <v-label class="theme--light text--lighten-2">{{
                $t(
                  'demande.formulaires.champs.charge-de-travail.travaux-pratiques'
                )
              }}</v-label>
            </v-col>
            <v-col xl="2" lg="2" md="4" sm="5">
              <stepper-nombre
                v-model="demandeCourante.heuresTravaux"
                :precision="3"
                :min="0"
                :max="999.999"
                :nom="
                  $t(
                    'demande.formulaires.champs.charge-de-travail.travaux-pratiques'
                  )
                "
              />
            </v-col>
          </v-row>
        </champ-formulaire>
        <champ-formulaire>
          <v-row>
            <v-col xl="10" lg="10" md="8" sm="7">
              <v-label class="theme--light text--lighten-2">{{
                $t(
                  'demande.formulaires.champs.charge-de-travail.travaux-personnels'
                )
              }}</v-label>
            </v-col>
            <v-col xl="2" lg="2" md="4" sm="5">
              <stepper-nombre
                v-model="demandeCourante.heuresPersonnelles"
                :precision="3"
                :min="0"
                :max="999.999"
                :nom="
                  $t(
                    'demande.formulaires.champs.charge-de-travail.travaux-personnels'
                  )
                "
              />
            </v-col>
          </v-row>
        </champ-formulaire>
        <hr />
        <br />
        <v-row>
          <v-col
            xl="10"
            lg="10"
            md="8"
            sm="7 "
            :class="`v-messages-total theme--light ${
              errors[0] ? 'error--text' : ''
            } `"
            role="alert"
          >
            {{ errors[0] }}
          </v-col>
          <v-col xl="2" lg="2" md="4" sm="5">
            {{
              $t('demande.formulaires.total', {
                totalHeures: totalHeures,
                totalHeuresValides: totalHeuresValides,
              })
            }}
          </v-col>
        </v-row>
      </div>
    </v-input>
  </validation-provider>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { StepperNombre } from '@/commun/components/champ-formulaire/stepper-nombre';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { ValidationProvider } from 'vee-validate';

  import Vue from 'vue';

  export default Vue.extend({
    name: 'ChargeTravail',
    components: {
      ChampFormulaire,
      StepperNombre,
      Tooltip,
      ValidationProvider,
    },
    mixins: [SectionFormulaireCours],
    computed: {
      totalHeuresValides(): number {
        return (this.$data.demandeCourante.nombreCredits || 0) * 3;
      },
      totalHeures(): number {
        const heures =
          (this.$data.demandeCourante.heuresMagistrales || 0) +
          (this.$data.demandeCourante.heuresTravaux || 0) +
          (this.$data.demandeCourante.heuresPersonnelles || 0);
        return heures;
      },
      messageErreurChargeTravail(): { [key: string]: string } {
        return {
          oneOf: `${this.$t(
            'demande.formulaires.champs.charge-de-travail.erreur-nombre-heure'
          )}`,
        };
      },
    },
  });
</script>
<style>
  .v-message-total {
    font-size: 12px;
    min-height: 14px;
    min-width: 1px;
    position: relative;
  }
</style>
