<template>
  <div v-if="afficherPage">
    <v-breadcrumbs class="pl-0" :items="breadcrumbsItems" />
    <v-card class="rounded">
      <entete-gestion-traitement
        class="mb-3 lightgray"
        :demande="demandeCourante"
      />
      <v-tabs v-model="onglet" class="pl-4 pr-4">
        <v-tab>{{ $t('demande.gestion.traitement.sommaire') }}</v-tab>
        <v-tab>{{ $t('demande.gestion.traitement.suivi.titre') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="onglet">
        <v-tab-item>
          <div class="d-flex flex-column">
            <bouton-copier-demande-traitement
              class="mr-8 align-self-end"
              :consultation-demande="consultationDemande"
            />
            <consultation-demande-liste
              :demande="demandeCourante"
              :facultes="facultes"
              :unites="unites"
              :individu-creation="individuCreation"
              :individu-modification="individuModification"
            />
            <v-btn
              ref="demande-traitement-gestion--actions--fermer"
              class="ma-8 primary align-self-start"
              @click="onClickBoutonFermer"
            >
              {{ $t('commun.actions.fermer') }}
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item>
          <suivi-demande :demande="demandeCourante" :matieres="matieres" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script lang="ts">
  import Matiere from '@/commun/services/set/model/matiere';
  import Unite from '@/commun/services/set/model/unites';
  import {
    ConsultationDemande,
    ConsultationDemandeListe,
  } from '@/components/faculte/demandes/consultation';
  import { BoutonCopierDemandeTraitement } from '@/components/gestion/traitement/copier-demande';
  import { EnteteGestionTraitement } from '@/components/gestion/traitement/entete';
  import { SuiviDemande } from '@/components/gestion/traitement/formulaires/suivi';
  import Demande from '@/domain/models/demande';
  import Individu from '@/domain/models/individu';
  import Routes from '@/router/routes';
  import Vue from 'vue';
  import Faculte from '@/commun/services/set/model/faculte';
  import demandeAConsultation from '@/components/faculte/demandes/consultation/demande-a-consultation';
  import DemandeCours from '@/domain/models/demande-cours';

  export default Vue.extend({
    name: 'DemandeTraitement',
    components: {
      BoutonCopierDemandeTraitement,
      ConsultationDemandeListe,
      EnteteGestionTraitement,
      SuiviDemande,
    },
    props: {
      idDemande: {
        type: String,
        required: true,
      },
    },
    data: () => {
      return {
        onglet: null,
        afficherPage: false,
      };
    },
    computed: {
      consultationDemande(): ConsultationDemande {
        return demandeAConsultation(
          this.$i18n,
          this.demandeCourante,
          [],
          this.individuModification,
          this.individuCreation,
          this.unites.find(
            (unite: Unite) =>
              unite.codeUnitePedagogique ===
              (this.demandeCourante as DemandeCours)?.uniteResponsable
          ),
          this.facultes.find(
            (faculte: Unite) =>
              faculte.codeUnitePedagogique ===
                (
                  this.demandeCourante as DemandeCours
                )?.codeFaculte?.toString() ?? ''
          )
        );
      },
      demandeCourante(): Demande {
        return this.$store.state.GestionOffreFormation.demandeCourante;
      },
      facultes(): Faculte[] {
        return this.$store.getters.facultes;
      },
      matieres(): Matiere[] {
        return this.$store.state.Etudes.matieres;
      },
      unites(): Unite[] {
        return this.$store.state.Etudes.unites;
      },
      breadcrumbsItems(): Array<unknown> {
        return [
          {
            disabled: false,
            text: this.$i18n.t(
              'demande.formulaires.navigation-liste-de-demandes'
            ),
            exact: true,
            to: { name: Routes.GestionDemandes },
          },
          {
            text: `${this.$i18n.t('demande.gestion.traitement.titre')}`,
          },
        ];
      },
      individuCreation(): Individu {
        return this.$store.state.GestionOffreFormation.individus.find(
          (individu: Individu) =>
            individu.idIndividu ===
            this.demandeCourante.audit?.idIndividuCreation
        );
      },
      individuModification(): Individu {
        return this.$store.state.GestionOffreFormation.individus.find(
          (individu: Individu) =>
            individu.idIndividu ===
            this.demandeCourante.audit?.idIndividuModification
        );
      },
    },
    async created(): Promise<void> {
      await Promise.all([
        this.$store.dispatch('obtenirDemandes'),
        this.$store.dispatch('obtenirDemande', this.idDemande),
        this.$store.dispatch('obtenirUnites'),
        this.$store.dispatch('obtenirMatieres'),
      ]);

      const idIndividuCreation =
        this.$store.state.GestionOffreFormation.demandeCourante?.audit
          ?.idIndividuCreation;
      const idIndividuModification =
        this.$store.state.GestionOffreFormation.demandeCourante?.audit
          ?.idIndividuModification;
      await Promise.all([
        this.$store.dispatch('obtenirIndividu', idIndividuCreation),
        this.$store.dispatch('obtenirIndividu', idIndividuModification),
      ]);
      this.afficherPage = true;
    },
    methods: {
      onClickBoutonFermer(): void {
        this.$router.push({ name: Routes.GestionDemandes });
      },
    },
  });
</script>
