<template>
  <div>
    <v-breadcrumbs class="pl-0" :items="breadcrumbsItems" />
    <h3 class="text-h5 mt-10 mb-10">{{ $t('demande.type.titre') }}</h3>
    <div>
      <ul>
        <li>
          <router-link :to="{ name: routeCreerDemande }">{{
            $t('demande.type.creer-cours')
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: routeCreerDemandeModification }">{{
            $t('demande.type.modifier-cours')
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: routeCreerDemandeReactivation }">{{
            $t('demande.type.reactiver-cours')
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: routeCreerDemandeDesactivation }">{{
            $t('demande.type.desactiver-cours')
          }}</router-link>
        </li>
        <li>
          <router-link :to="{ name: routeCreerDemandeModificationProgramme }">{{
            $t('demande.type.modification-programme')
          }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import Routes from '@/router/routes';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DemandeType',
    computed: {
      routeCreerDemande() {
        return Routes.CreerDemandeCreationCours;
      },
      routeCreerDemandeModification() {
        return Routes.CreerDemandeModificationCours;
      },
      routeCreerDemandeReactivation() {
        return Routes.CreerDemandeReactivationCours;
      },
      routeCreerDemandeDesactivation() {
        return Routes.CreerDemandeDesactivationCours;
      },
      routeCreerDemandeModificationProgramme() {
        return Routes.CreerDemandeModificationProgramme;
      },
      breadcrumbsItems(): Array<unknown> {
        return [
          {
            disabled: false,
            text: this.$i18n.t(
              'demande.formulaires.navigation-liste-de-demandes'
            ),
            exact: true,
            to: { name: Routes.Demandes },
          },
          {
            text: this.$i18n.t('demande.type.titre'),
          },
        ];
      },
    },
  });
</script>
