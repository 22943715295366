<template>
  <radio-group v-model="demandeCourante.typeModification" class="pa-0">
    <template #label>
      <v-label
        >{{ $t('demande.programme.formulaire.champs.majeure').toString() }}
      </v-label>
    </template>
    <v-row no-gutters style="flex-wrap: nowrap">
      <v-col cols="6">
        <v-radio
          :label="
            $t(`demande.programme.formulaire.type-majeure.MAJEURE_EXISTANTE`)
          "
          :value="majeureExistante"
          :disabled="estRadioDesactive"
        />
      </v-col>
      <v-col cols="6">
        <v-radio
          :label="
            $t(`demande.programme.formulaire.type-majeure.NOUVELLE_MAJEURE`)
          "
          :value="nouvelleMajeure"
          :disabled="estRadioDesactive"
        />
      </v-col>
    </v-row>
  </radio-group>
</template>

<script lang="ts">
  import RadioGroup from '@/commun/components/radio-group/radio-group.vue';
  import Vue from 'vue';
  import DemandeProgramme, {
    DemandeProgrammeTypeModification,
  } from '@/domain/models/demande-programme';
  import { SectionFormulaireProgramme } from '@/components/faculte/demandes/formulaires/programme/section-formulaire-programme';

  export default Vue.extend({
    name: 'RadioMajeure',
    components: { RadioGroup },
    mixins: [SectionFormulaireProgramme],
    props: {
      estRadioDesactive: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      demandeCourante: {} as DemandeProgramme,
      nouvelleMajeure: DemandeProgrammeTypeModification.MAJEURE_NOUVELLE,
      majeureExistante: DemandeProgrammeTypeModification.MAJEURE_EXISTANTE,
    }),
  });
</script>
