export const SerieCours: string[] = [
  '0000',
  '1000',
  '2000',
  '3000',
  '4000',
  '5000',
  '6000',
  '7000',
  '8000',
  'U000',
  'V000',
];
