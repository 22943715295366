<template>
  <div />
</template>

<script lang="ts">
  import Vue from 'vue';
  import { AuthModule } from '@/commun/services/auth/AuthModule';

  export default Vue.extend({
    name: 'Callback',
    props: {
      authModule: {
        type: Object as () => AuthModule,
        required: true,
      },
    },
    async created() {
      await this.authModule.gererPromesseRedirection();
    },
  });
</script>
