<template>
  <div v-if="afficherFormulaire">
    <creer-demande-creation-cours
      v-if="afficherFormulaireCreation"
      :demande="demandeCourante"
      @enregistrer="modifierDemande"
      @annuler="onAnnulerFormulaire"
    />
    <creer-demande-modification
      v-if="afficherFormulaireModification"
      :demande="demandeCourante"
      @enregistrer="modifierDemande"
      @annuler="onAnnulerFormulaire"
    />
    <creer-demande-desactivation
      v-if="afficherFormulaireDesactivation"
      :demande="demandeCourante"
      @enregistrer="modifierDemande"
      @annuler="onAnnulerFormulaire"
    />
    <creer-demande-reactivation
      v-if="afficherFormulaireReactivation"
      :demande="demandeCourante"
      @enregistrer="modifierDemande"
      @annuler="onAnnulerFormulaire"
    />
    <Creer-demande-modification-programme
      v-if="afficherFormulaireModificationProgramme"
      :demande="demandeCouranteProgramme"
      @enregistrer="modifierDemande"
      @annuler="onAnnulerFormulaire"
    />
    <Spinner v-if="modificationEnCours"></Spinner>
  </div>
</template>

<script lang="ts">
  import { TypesMessagesSnackbar } from '@/commun/components/snackbars/requete-snackbar';
  import Demande, { TypesDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import CreerDemandeCreationCours from '@/views/faculte/demande/cours/creation/creer/creer-demande-creation-cours.vue';
  import CreerDemandeModification from '@/views/faculte/demande/cours/modification/creer/creer-demande-modification-cours.vue';
  import CreerDemandeDesactivation from '@/views/faculte/demande/cours/desactivation/creer/creer-demande-desactivation-cours.vue';
  import CreerDemandeReactivation from '@/views/faculte/demande/cours/reactivation/creer/creer-demande-reactivation-cours.vue';
  import Operation from '@/views/faculte/demande/cours/operations/operation';
  import { CreerDemandeModificationProgramme } from '@/views/faculte/demande/programme';
  import DemandeProgramme from '@/domain/models/demande-programme';
  import { DemandeDocument } from '@/domain/models/demande-document';
  import { Spinner } from '@/commun/components/spinner';

  export default Operation.extend({
    name: 'ModifierDemandeCours',
    components: {
      CreerDemandeCreationCours,
      CreerDemandeModification,
      CreerDemandeDesactivation,
      CreerDemandeReactivation,
      CreerDemandeModificationProgramme,
      Spinner,
    },
    props: {
      idDemande: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      afficherFormulaire: false,
      modificationEnCours: false,
    }),
    computed: {
      demandeCourante(): DemandeCours {
        return this.$store.state.GestionOffreFormation.demandeCourante;
      },
      demandeCouranteProgramme(): DemandeProgramme {
        return this.$store.state.GestionOffreFormation.demandeCourante;
      },
      afficherFormulaireModification(): boolean {
        return (
          this.demandeCourante.typeDemande === TypesDemande.MODIFICATION_COURS
        );
      },
      afficherFormulaireCreation(): boolean {
        return this.demandeCourante.typeDemande === TypesDemande.CREATION_COURS;
      },
      afficherFormulaireDesactivation(): boolean {
        return (
          this.demandeCourante.typeDemande === TypesDemande.DESACTIVATION_COURS
        );
      },
      afficherFormulaireReactivation(): boolean {
        return (
          this.demandeCourante.typeDemande === TypesDemande.REACTIVATION_COURS
        );
      },
      afficherFormulaireModificationProgramme(): boolean {
        return (
          this.demandeCourante.typeDemande ===
          TypesDemande.MODIFICATION_PROGRAMME
        );
      },
    },
    async created() {
      await Promise.all([
        this.$store.dispatch('obtenirDemandes'),
        this.$store.dispatch('obtenirDemande', this.idDemande),
      ]).then(() => {
        this.afficherFormulaire = true;
      });
    },
    methods: {
      async modifierDemande({
        demande,
        nouveauxFichiers,
        documentExistantsASupprimer,
      }: {
        demande: Demande;
        nouveauxFichiers: File[];
        documentExistantsASupprimer: DemandeDocument[];
      }) {
        this.modificationEnCours = true;
        const demandeEnvoyee = this.retirerProprietesInchangees(demande);

        if (
          Object.keys(demandeEnvoyee).length === 0 &&
          nouveauxFichiers.length === 0 &&
          documentExistantsASupprimer.length === 0
        ) {
          await this.notifierUtilisateur(
            this.$t('demande.edition.aucun-changement').toString(),
            TypesMessagesSnackbar.Information
          );

          this.naviguerSommaire(this.demandeCourante.id);

          return;
        }

        await this.$store.dispatch('modifierDemande', {
          idDemande: this.demandeCourante.id,
          demande: demandeEnvoyee,
        });

        await this.$store.dispatch(
          'supprimerDemandeDocuments',
          documentExistantsASupprimer
        );

        await this.$store.dispatch('creerDemandeDocuments', {
          idDemande: this.demandeCourante.id,
          fichiers: nouveauxFichiers,
        });

        await this.notifierUtilisateur(
          this.$t('demande.edition.succes').toString(),
          TypesMessagesSnackbar.Succes
        );
        this.modificationEnCours = false;
        this.naviguerSommaire(this.demandeCourante.id);
      },
      retirerProprietesInchangees(demande: Demande): Demande {
        const demandeAvecProprietesModifiees = { ...demande };
        Object.keys(demande).forEach((cle: string) => {
          const cleProprieteDemande = cle as keyof Demande;
          if (
            demande[cleProprieteDemande] ===
            this.demandeCourante[cleProprieteDemande]
          ) {
            delete demandeAvecProprietesModifiees[cleProprieteDemande];
          }
        });
        if (
          demande.typeDemande == TypesDemande.MODIFICATION_COURS ||
          demande.typeDemande == TypesDemande.REACTIVATION_COURS
        ) {
          delete (demandeAvecProprietesModifiees as DemandeCours)[
            'modeEnseignement'
          ];
        }
        if (
          this.demandeCourante.typeDemande ===
          TypesDemande.MODIFICATION_PROGRAMME
        ) {
          if (
            JSON.stringify(
              (demande as DemandeProgramme).indicateursModificationProgramme
            ) ===
            JSON.stringify(
              this.demandeCouranteProgramme.indicateursModificationProgramme
            )
          ) {
            delete (demandeAvecProprietesModifiees as DemandeProgramme)
              .indicateursModificationProgramme;
          }
        }
        return demandeAvecProprietesModifiees;
      },
    },
  });
</script>
