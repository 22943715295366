import { RegleValidation } from '@/commun/validations/regles/regle-validation';
import { Validations } from '@/commun/validations/validations';
import validator from 'validator';

export class RegleCourriel implements RegleValidation {
  nom: string = Validations.Courriel;

  valider(valeur: string | undefined | null): boolean {
    if (!valeur) {
      return false;
    }
    return validator.isEmail(valeur);
  }

  obtenirRegleValidation = (): Record<string, unknown> => ({
    validate: (valeur: string | undefined | null) => ({
      valid: this.valider(valeur),
    }),
  });
}
