<template>
  <div>
    <v-breadcrumbs class="pl-0" :items="breadcrumbsItems" />
    <v-container v-if="!afficherFormulaire" fill-height>
      <v-row align="center" justify="center">
        <v-col align="center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
    </v-container>
    <Formulaire-modification-programme
      v-if="afficherFormulaire"
      :demande-en-modification="demandeCourante"
      :facultes="facultes"
      :facultes-rattachement="facultesRattachement"
      :roles="roles"
      :sessions="sessions"
      @annulerFormulaire="$emit('annuler')"
      @enregistrer="$emit('enregistrer', $event)"
    />
  </div>
</template>

<script lang="ts">
  import Session from '@/commun/services/set/model/session';
  import Routes from '@/router/routes';
  import Vue, { PropType } from 'vue';
  import { FormulaireModificationProgramme } from '@/components/faculte/demandes/formulaires/programme';
  import DemandeProgramme from '@/domain/models/demande-programme';
  import Faculte from '@/commun/services/set/model/faculte';
  import { IdentiteRoles } from '@/domain/models/identite';

  export default Vue.extend({
    name: 'CreerDemandeModificationProgramme',
    components: {
      FormulaireModificationProgramme,
    },
    props: {
      demande: {
        type: Object as PropType<DemandeProgramme>,
        default: undefined,
      },
    },
    data: () => ({
      afficherFormulaire: false,
    }),
    computed: {
      facultesRattachement(): string[] {
        return this.$store.state.GestionOffreFormation.identite
          .facultesRattachement;
      },
      roles(): IdentiteRoles[] {
        return this.$store.getters.roles;
      },
      facultes(): Faculte[] {
        return this.$store.getters.facultes;
      },
      demandeCourante(): DemandeProgramme {
        return this.demande;
      },
      sessions(): Session[] {
        return this.$store.getters.sessionsFuturesHoraireNonPublie;
      },
      breadcrumbsItems(): Array<unknown> {
        return [
          {
            disabled: false,
            text: this.$i18n.t(
              'demande.formulaires.navigation-liste-de-demandes'
            ),
            exact: true,
            to: { name: Routes.Demandes },
          },
          {
            text: this.$i18n.t('demande.types.MODIFICATION_PROGRAMME'),
          },
        ];
      },
    },
    async created() {
      await Promise.all([
        this.$store.dispatch('obtenirSessionCourante'),
        this.$store.dispatch('obtenirSessions'),
        this.$store.dispatch('obtenirUnites'),
      ]);
      this.afficherFormulaire = true;
    },
  });
</script>
