import {
  RequeteSnackbar,
  TypesMessagesSnackbar,
} from '@/commun/components/snackbars/requete-snackbar';
import Routes from '@/router/routes';
import Vue from 'vue';

export default Vue.extend({
  name: 'Operation',
  methods: {
    async onAnnulerFormulaire(): Promise<void> {
      await this.retournerAccueil();
    },
    async retournerAccueil(): Promise<void> {
      await this.$router.push({ name: Routes.Demandes });
    },
    async notifierUtilisateur(
      message: string,
      typeMessage: TypesMessagesSnackbar
    ): Promise<void> {
      await this.$store.dispatch(
        'notifierUtilisateur',
        new RequeteSnackbar(message, typeMessage)
      );
    },
    naviguerSommaire(idDemande: string) {
      this.$router.push({
        name: Routes.ConsulterDemande,
        params: { idDemande: idDemande },
      });
    },
  },
});
