<template>
  <champ-formulaire
    validation="requis"
    :nom="$t('demande.formulaires.champs.credits.nom')"
  >
    <stepper-nombre
      slot-scope="{ errors }"
      :value="value"
      :errors="errors"
      :label="$t('demande.formulaires.champs.credits.nom')"
      :min="minCredits"
      :max="maxCredits"
      :afficher-placeholder="false"
      v-bind="$attrs"
      @input="onInput"
    />
  </champ-formulaire>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { StepperNombre } from '@/commun/components/champ-formulaire/stepper-nombre';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'StepperCredits',
    components: {
      ChampFormulaire,
      StepperNombre,
    },
    inheritAttrs: false,
    props: {
      value: {
        type: Number,
        default: null,
      },
      minCredits: {
        type: Number,
        default: 0,
      },
      maxCredits: {
        type: Number,
        default: 999,
      },
    },
    data: () => ({
      valeurInterne: 0,
    }),
    methods: {
      onInput(valeur: number): void {
        this.$emit('input', valeur);
      },
    },
  });
</script>
