import ErreurReseau from '@/commun/http/erreur-reseau';
import { BeforeRetryHook, HTTPError, Options } from 'ky';
import { BeforeRetryState } from 'ky/distribution/types/hooks';
import { AuthError } from '@azure/msal-browser';

export const creerHookErreurReseau =
  (): BeforeRetryHook => async (etat: BeforeRetryState) => {
    if (!(etat.error instanceof HTTPError)) {
      if (etat.error instanceof AuthError) {
        return;
      }
      throw new ErreurReseau();
    }
  };

export const enregistrerHookGestionErreurs = (): Options => ({
  hooks: {
    beforeRetry: [creerHookErreurReseau()],
  },
});
