import DetailsCours from '@/commun/services/set/model/details-cours';
import { RegleValidation } from '@/commun/validations/regles/regle-validation';
import { ajouterValidations } from '@/commun/validations/validations-setup';
import { RegleCoursModifiable } from '@/plugins/validations/regle-cours-modifiable';

export const ajouterValidationsGOF = (): void => {
  const reglesGOF: Array<RegleValidation<DetailsCours>> = [
    new RegleCoursModifiable(),
  ];
  ajouterValidations(reglesGOF);
};
