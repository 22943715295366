<template>
  <v-container class="pl-0 pr-0">
    <v-row class="align-top no-gutters">
      <v-col :cols="colonnesChamp">
        <validation-provider
          v-slot="{ errors }"
          :mode="modeValidation"
          :rules="validation"
          :name="nom"
          :vid="vid"
          :immediate="immediate"
        >
          <slot :errors="errors" />
        </validation-provider>
      </v-col>
      <v-col
        :class="`champ-formulaire__colonne-doite d-flex pl-1 flex-column align-self-${alignementColonneDroite}`"
        cols="1"
      >
        <slot name="contenu-droite" />
      </v-col>
    </v-row>
    <v-row class="align-top no-gutters">
      <v-col :cols="10">
        <slot name="contenu-hint" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { v4 } from 'uuid';
  import { ValidationProvider } from 'vee-validate';
  import Vue from 'vue';

  const VALIDATION_MODE_EAGER = 'eager';
  const COLONNES_CHAMP_VUETIFY = '11';
  export default Vue.extend({
    name: 'ChampFormulaire',
    components: {
      ValidationProvider,
    },
    props: {
      alignementColonneDroite: {
        type: String,
        default: 'center',
      },
      colonnesChamp: {
        type: String,
        default: COLONNES_CHAMP_VUETIFY,
      },
      immediate: {
        type: Boolean,
        default: false,
      },
      nom: {
        type: String,
        default: '',
      },
      modeValidation: {
        type: String,
        default: VALIDATION_MODE_EAGER,
      },
      validation: {
        type: [String, Object],
        default: '',
      },
    },
    data: () => ({
      vid: v4(),
    }),
  });
</script>

<style lang="scss">
  .champ-formulaire {
    &__colonne-doite {
      align-items: start;
    }
  }
</style>
