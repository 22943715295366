import HttpWrapper from '@/commun/http/http-wrapper';
import ServiceDemandesHTTP, {
  ServiceDemandes,
} from '@/services/service-demandes';
import Services from '@/services/services';
import { Container } from 'inversify';

export default class ServicesGestionOffreFormationContainer {
  public static enregistrerServices(
    clientHttp: HttpWrapper,
    container: Container
  ): void {
    container
      .bind<ServiceDemandes>(Services.ServiceDemandes)
      .toConstantValue(new ServiceDemandesHTTP(clientHttp));
  }
}
