<template>
  <div>
    <entete-page :titre="$t('demande.titre')" />
    <v-card class="mt-6">
      <demandes-tableau
        :demandes="demandes"
        :colonnes-a-afficher="colonnesAffichees"
        @naviguerCreerDemande="onNaviguerCreerDemande"
      >
        <template #item.numeroDemande.contenu="{ item }">
          <router-link
            class="demandes-tableau__lien-numero-demande ml-2"
            :to="{
              name: routeConsulterDemande,
              params: { idDemande: item.id },
            }"
          >
            {{ item.numeroDemande }}
          </router-link>
        </template>
      </demandes-tableau>
    </v-card>
  </div>
</template>

<script lang="ts">
  import EntetePage from '@/commun/components/entete-page/entete-page.vue';
  import { DemandesTableau } from '@/components/demandes/tableau';
  import { DemandesTableauColonnesNom } from '@/components/demandes/tableau/demandes-tableau-colonnes';
  import Demande from '@/domain/models/demande';
  import Routes from '@/router/routes';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DemandesFaculte',
    components: {
      EntetePage,
      DemandesTableau,
    },
    data: () => ({
      colonnesAffichees: [
        DemandesTableauColonnesNom.Numero,
        DemandesTableauColonnesNom.Session,
        DemandesTableauColonnesNom.Type,
        DemandesTableauColonnesNom.Faculte,
        DemandesTableauColonnesNom.CoursProgramme,
        DemandesTableauColonnesNom.Etat,
        DemandesTableauColonnesNom.Demandeur,
        DemandesTableauColonnesNom.Actions,
      ],
      routeConsulterDemande: Routes.ConsulterDemande,
    }),
    computed: {
      demandes(): Demande[] {
        return this.$store.getters.demandes;
      },
    },
    async created() {
      await this.$store.dispatch('obtenirDemandes');
    },
    methods: {
      onNaviguerCreerDemande(): void {
        this.$router.push({ name: Routes.DemandeType });
      },
    },
  });
</script>
