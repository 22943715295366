<template>
  <v-card class="pa-8" :outlined="encadre" :elevation="elevation">
    <h3 v-if="titre" class="text-h5 mb-4">
      {{ titre }}
    </h3>
    <slot />
  </v-card>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'SectionFormulaire',
    props: {
      encadre: {
        type: Boolean,
        default: true,
      },
      elevation: {
        type: Number,
        default: 1,
      },
      titre: {
        type: String,
        required: false,
        default: undefined,
      },
    },
  });
</script>
