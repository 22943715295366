import Demande from '@/domain/models/demande';
import { DemandeDocument } from '@/domain/models/demande-document';
import { IdentiteRoles } from '@/domain/models/identite';
import GestionOffreFormationState from '@/store/gestion-offre-formation-state';

const GestionOffreFormationGetters = {
  erreur(state: GestionOffreFormationState): Error | undefined {
    return state.erreur;
  },
  estChargementDemandesEnCours(state: GestionOffreFormationState): boolean {
    return state.estChargementDemandesEnCours;
  },
  demandes(state: GestionOffreFormationState): Demande[] {
    return state.demandes;
  },
  demandeCouranteDocumentsTrieesParDate(
    state: GestionOffreFormationState
  ): DemandeDocument[] {
    return state.demandeCouranteDocuments.sort(
      (a, b) =>
        new Date(b.horodateDepot ?? '').getTime() -
        new Date(a.horodateDepot ?? '').getTime()
    );
  },
  trierTableauDescendant(state: GestionOffreFormationState): boolean {
    return state.trierTableauDescendant;
  },
  colonneATrier(state: GestionOffreFormationState): string {
    return state.colonneATrier;
  },
  filtreRecherche(state: GestionOffreFormationState): string {
    return state.filtreRecherche;
  },
  demandesParPage(state: GestionOffreFormationState): number {
    return state.demandesParPage;
  },
  roles(state: GestionOffreFormationState): IdentiteRoles[] | undefined {
    if (!state.identite) {
      return undefined;
    }
    const roles: IdentiteRoles[] = [];
    state.identite.roles.forEach((role: string) => {
      const roleValide = Object.values(IdentiteRoles).some(
        (roleIdentite: string) => {
          return roleIdentite === role;
        }
      );
      if (roleValide) {
        roles.push(<IdentiteRoles>role);
      }
    });
    return roles;
  },
};

export default GestionOffreFormationGetters;
