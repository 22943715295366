import {
  PublicClientApplication,
  Configuration,
  AccountInfo,
  SilentRequest,
  RedirectRequest,
  AuthenticationResult,
  AuthError,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';
import { VariablesEnvironnementGOF } from '@/setup/variables-environnement-gof';

const IE = 'MSIE ';
const IE11 = 'Trident/';
const EDGE = 'Edge/';

function estIEouEdge(): boolean | undefined {
  const ua = window.navigator.userAgent;
  return ua.indexOf(IE) > 0 || ua.indexOf(IE11) > 0 || ua.indexOf(EDGE) > 0;
}
const msalConfig: Configuration = {
  auth: {
    clientId: '',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: estIEouEdge(),
  },
};
export class AuthModule {
  private msal: PublicClientApplication;
  private readonly loginRequest: RedirectRequest;
  private accessToken: string | undefined = undefined;
  private variablesEnvironnementGOF: VariablesEnvironnementGOF;

  constructor(variables: VariablesEnvironnementGOF) {
    this.variablesEnvironnementGOF = variables;
    msalConfig.auth.clientId =
      this.variablesEnvironnementGOF.parametresAuthorizationCode.client_id;
    msalConfig.auth.authority =
      this.variablesEnvironnementGOF.parametresAuthorizationCode.authority;
    msalConfig.auth.redirectUri =
      this.variablesEnvironnementGOF.parametresAuthorizationCode.redirect_uri;
    msalConfig.auth.postLogoutRedirectUri =
      this.variablesEnvironnementGOF.base_uri;
    const scope =
      this.variablesEnvironnementGOF.parametresAuthorizationCode.scope;

    this.msal = new PublicClientApplication(msalConfig);
    this.loginRequest = {
      scopes: [scope],
    };
  }
  private obtenirCompte(): AccountInfo | null {
    const currentAccounts = this.msal.getAllAccounts();
    if (currentAccounts.length > 1) {
      this.logout();
    }
    if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
    return null;
  }

  async estAuthentifie(): Promise<boolean> {
    this.accessToken = undefined;
    const compteInfo = this.obtenirCompte();
    if (compteInfo !== null) {
      try {
        const tokenRequest = {
          account: compteInfo,
          ...this.loginRequest,
        } as SilentRequest;
        const tokenResponse = await this.msal.acquireTokenSilent(tokenRequest);
        this.accessToken = tokenResponse?.accessToken;
        return true;
      } catch (erreur) {
        if (erreur instanceof InteractionRequiredAuthError) {
          return false;
        }
        throw new AuthError(
          (erreur as AuthError).errorCode,
          (erreur as AuthError).message
        );
      }
    }
    return false;
  }
  getToken(): string | undefined {
    return this.accessToken;
  }
  async login(): Promise<void> {
    this.accessToken = undefined;
    const loginRedirectRequest = {
      ...this.loginRequest,
      loginHint: this.obtenirCompte()?.username,
    };
    await this.msal.loginRedirect(loginRedirectRequest);
  }

  async gererPromesseRedirection(): Promise<AuthenticationResult | null> {
    return await this.msal.handleRedirectPromise();
  }

  async logout(): Promise<void> {
    try {
      await this.msal.logoutRedirect();
    } catch (_e) {
      window.location.href = this.variablesEnvironnementGOF.base_uri;
    }
  }
}
