import HttpClient from '@/commun/http/http-wrapper';
import Session from '@/commun/services/set/model/session';
import { sessionsMock } from '../mocks/sessions.mock';

export interface ServiceSessions {
  obtenirSessions(): Promise<Session[]>;
  obtenirSessionCourante(): Promise<Session>;
}

export default class ServiceSessionsImpl implements ServiceSessions {
  public static PATH_SESSION = 'sessions';

  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  obtenirSessions(): Promise<Session[]> {
    return this.httpClient.get<Session[]>(ServiceSessionsImpl.PATH_SESSION);
  }
  obtenirSessionCourante(): Promise<Session> {
    return this.httpClient.get<Session>(
      `${ServiceSessionsImpl.PATH_SESSION}/courante`
    );
  }
}

export class ServiceSessionMock implements ServiceSessions {
  public sessionsMocks = sessionsMock();

  public obtenirSessions(): Promise<Session[]> {
    return Promise.resolve(this.sessionsMocks);
  }

  public obtenirSessionCourante(): Promise<Session> {
    return Promise.resolve(this.sessionsMocks[0]);
  }
}
