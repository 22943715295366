import HttpClient from '@/commun/http/http-wrapper';
import Unite from '@/commun/services/set/model/unites';
import { facultesMock } from '../mocks/unite.mock';

export interface ServiceUnites {
  obtenirUnites(): Promise<Unite[]>;
}

export default class ServiceUnitesHttp implements ServiceUnites {
  public static PATH_UNITES = 'unites';

  constructor(private readonly httpClient: HttpClient) {}

  obtenirUnites(): Promise<Unite[]> {
    return this.httpClient.get<Unite[]>(ServiceUnitesHttp.PATH_UNITES);
  }
}

export class ServiceUnitesMock implements ServiceUnites {
  private unitesMocks = facultesMock();

  public obtenirUnites(): Promise<Unite[]> {
    return Promise.resolve(this.unitesMocks);
  }
}
