<template>
  <section-formulaire
    class="mt-8"
    :titre="$t('demande.formulaires.section.information-complementaire')"
  >
    <champ-formulaire v-if="afficherAlert">
      <alerte
        type="info"
        no-margin
        :message="
          $t(
            'demande.modification.formulaire.avertissement-champs-optionnels'
          ).toString()
        "
      />
    </champ-formulaire>
    <description-cours-textarea
      v-if="afficherChamp(champsInformationComplementaire.DescriptionSommaire)"
      v-model="demandeCourante.descriptionCours"
      :validation="`${regleValidationRequis}|max:${maximumCaracteresDescriptionCours}`"
      :counter="maximumCaracteresDescriptionCours"
      :label="$t('demande.formulaires.champs.description-du-cours.nom')"
      :maximum-caracteres="maximumCaracteresDescriptionCours"
    >
      <tooltip
        slot="contenu-droite"
        :messages="[
          $t('demande.formulaires.champs.description-du-cours.infobulle'),
        ]"
      />
    </description-cours-textarea>

    <text-area
      v-if="afficherChamp(champsInformationComplementaire.Prealables)"
      v-model="demandeCourante.textePrealable"
      :validation="`max:${maximumCaracteresPrealables}`"
      :placeholder="$t('demande.formulaires.champs.prealables.placeholder')"
      :hint="$t('demande.formulaires.champs.prealables.hint')"
      :counter="maximumCaracteresPrealables"
      :label="$t('demande.formulaires.champs.prealables.nom')"
      :maximum-caracteres="maximumCaracteresPrealables"
    >
      <tooltip
        slot="contenu-droite"
        :messages="[$t('demande.formulaires.champs.prealables.infobulle')]"
      />
    </text-area>

    <text-field
      v-if="afficherChamp(champsInformationComplementaire.Concomitants)"
      v-model="demandeCourante.texteConcomitant"
      :validation="`max:${maximumCaracteresConcomitants}`"
      :placeholder="$t('demande.formulaires.champs.concomitants.placeholder')"
      :counter="maximumCaracteresConcomitants"
      :label="$t('demande.formulaires.champs.concomitants.nom')"
    >
      <tooltip
        slot="contenu-droite"
        :messages="[$t('demande.formulaires.champs.concomitants.infobulle')]"
      />
    </text-field>

    <champ-formulaire
      v-if="afficherChamp(champsInformationComplementaire.Substitution)"
      :validation="`max:${maximumCaracteresSubstitution}`"
      alignement-colonne-droite="start"
    >
      <v-text-field
        v-model="demandeCourante.texteSubstitution"
        slot-scope="{ errors }"
        :placeholder="$t('demande.formulaires.champs.substitution.placeholder')"
        outlined
        :error-messages="errors"
        :counter="maximumCaracteresSubstitution"
        :label="$t('demande.formulaires.champs.substitution.nom')"
      />
      <tooltip
        slot="contenu-droite"
        :messages="[$t('demande.formulaires.champs.substitution.infobulle')]"
      />
    </champ-formulaire>

    <text-area
      v-if="
        afficherChamp(champsInformationComplementaire.RestrictionsInscription)
      "
      v-model="demandeCourante.texteRestriction"
      :validation="`max:${maximumCaracteresRestrictionsInscription}`"
      :hint="$t('demande.formulaires.champs.restrictions-inscription.hint')"
      :maximum-caracteres="maximumCaracteresRestrictionsInscription"
      :label="$t('demande.formulaires.champs.restrictions-inscription.nom')"
    >
      <tooltip
        slot="contenu-droite"
        :messages="[
          $t('demande.formulaires.champs.restrictions-inscription.infobulle'),
        ]"
      />
    </text-area>

    <text-area
      v-if="afficherChamp(champsInformationComplementaire.Positionnement)"
      v-model="demandeCourante.texteProgrammeCible"
      :validation="`${regleValidationRequisPositionnement}|max:${maximumCaracteresPositionnement}`"
      :hint="$t('demande.formulaires.champs.positionnement-cours.hint')"
      :maximum-caracteres="maximumCaracteresPositionnement"
      :label="$t('demande.formulaires.champs.positionnement-cours.nom')"
    >
      <tooltip
        slot="contenu-droite"
        :messages="[
          $t('demande.formulaires.champs.positionnement-cours.infobulle'),
        ]"
      />
    </text-area>

    <text-area
      v-if="
        afficherChamp(
          champsInformationComplementaire.RenseignementsSupplementaires
        )
      "
      v-model="demandeCourante.informationSupplementaire"
      :validation="`max:${maximumCaracteresInformationsSupplementaires}`"
      :maximum-caracteres="maximumCaracteresInformationsSupplementaires"
      :placeholder="
        $t(
          'demande.formulaires.champs.informations-supplementaires.placeholder'
        )
      "
      :label="$t('demande.formulaires.champs.informations-supplementaires.nom')"
    />
  </section-formulaire>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import SectionFormulaire from '@/commun/components/champ-formulaire/section-formulaire/section-formulaire.vue';
  import { TextArea } from '@/commun/components/champ-formulaire/text-area';
  import { TextField } from '@/commun/components/champ-formulaire/text-field';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import { DescriptionCoursTextarea } from '@/components/faculte/demandes/formulaires/champs/information-base-cours/description-cours';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { ChampsInformationComplementaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-complementaire/champs-information-complementaire';
  import Vue, { PropType } from 'vue';
  import { Alerte } from '@/commun/components/champ-formulaire/alerte';

  export default Vue.extend({
    name: 'SectionInformationComplementaire',
    components: {
      SectionFormulaire,
      DescriptionCoursTextarea,
      ChampFormulaire,
      TextArea,
      TextField,
      Tooltip,
      Alerte,
    },
    mixins: [SectionFormulaireCours],
    props: {
      afficherAlert: {
        type: Boolean,
        default: false,
      },
      champsAffiches: {
        type: Array as PropType<Array<ChampsInformationComplementaire>>,
        default: () => Object.values(ChampsInformationComplementaire),
      },
    },
    data: () => ({
      maximumCaracteresDescriptionCours: 800,
      maximumCaracteresPrealables: 500,
      maximumCaracteresConcomitants: 500,
      maximumCaracteresSubstitution: 500,
      maximumCaracteresRestrictionsInscription: 500,
      maximumCaracteresPositionnement: 500,
      maximumCaracteresInformationsSupplementaires: 1000,
      champsInformationComplementaire: ChampsInformationComplementaire,
    }),
    methods: {
      afficherChamp(champ: ChampsInformationComplementaire): boolean {
        return this.champsAffiches?.some(
          (champAffiche) => champ === champAffiche
        );
      },
    },
  });
</script>
