export enum IdentiteRoles {
  DEMANDEUR = 'GOF_DEMANDEUR',
  GESTIONNAIRE = 'GOF_GESTIONNAIRE',
}

interface Identite {
  idIdentite: string;
  identifiant: string;
  roles: string[];
  facultesRattachement: string[];
}

export default Identite;
