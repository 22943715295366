import Vue from 'vue';
import Vuetify, {
  VAlert,
  VApp,
  VAutocomplete,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VBtn,
  VCard,
  VCheckbox,
  VChip,
  VCol,
  VContainer,
  VDataTable,
  VDialog,
  VDivider,
  VExpandTransition,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFadeTransition,
  VFileInput,
  VFlex,
  VForm,
  VIcon,
  VImg,
  VInput,
  VLabel,
  VLayout,
  VList,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemGroup,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VProgressCircular,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSimpleTable,
  VSlideYTransition,
  VSpacer,
  VStepper,
  VStepperHeader,
  VStepperItems,
  VStepperStep,
  VSubheader,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextarea,
  VTextField,
  VToolbar,
  VToolbarTitle,
  VTooltip,
} from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import fr from 'vuetify/src/locale/fr';

Vue.use(Vuetify, {
  components: {
    VApp,
    VAlert,
    VAutocomplete,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VBtn,
    VCard,
    VChip,
    VCheckbox,
    VCol,
    VContainer,
    VDataTable,
    VDialog,
    VDivider,
    VExpandTransition,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VFadeTransition,
    VFileInput,
    VFlex,
    VForm,
    VIcon,
    VImg,
    VInput,
    VLabel,
    VLayout,
    VList,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemGroup,
    VListItemSubtitle,
    VListItemTitle,
    VMain,
    VMenu,
    VProgressCircular,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSimpleTable,
    VSlideYTransition,
    VSpacer,
    VStepper,
    VStepperHeader,
    VStepperItems,
    VStepperStep,
    VSubheader,
    VTab,
    VTabs,
    VTabItem,
    VTabsItems,
    VTextarea,
    VTextField,
    VToolbar,
    VToolbarTitle,
    VTooltip,
  },
});

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        danger: colors.red,
        lightgray: '#f4f4f4',
        lightestgray: '#f9f9f9',
        ulblue: '#09f',
        ulyellow: '#ffc103',
        ulred: '#e30513',
        ulinformation: '#eaf5ff',
        ultext: '#3d3d3d',
        error: '#e30513',
        information: '#eaf5ff',
        warning: '#ffc103',
        success: '#00c77f',
      },
    },
  },
});
