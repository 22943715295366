import DetailsCours from '@/commun/services/set/model/details-cours';
import { RegleValidation } from '@/commun/validations/regles/regle-validation';
import { ValidationsGOF } from '@/plugins/validations/validations-gof';

export class RegleCoursModifiable implements RegleValidation<DetailsCours> {
  nom: string = ValidationsGOF.CoursModifiable;

  valider(valeur: DetailsCours | undefined | null): boolean {
    const estModifiable = valeur?.estModifiable();
    return estModifiable ? estModifiable : false;
  }

  obtenirRegleValidation = (): Record<string, unknown> => ({
    validate: (valeur: DetailsCours | undefined | null) => ({
      valid: this.valider(valeur),
    }),
  });
}
