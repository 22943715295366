import Routes from '@/router/routes';
import StoreGestionOffreFormation from '@/store/store-gestion-offre-formation';
import { NavigationGuardNext, Route } from 'vue-router';

const IdentiteRolesGuard = (
  to: Route,
  _from: Route,
  next: NavigationGuardNext,
  store: StoreGestionOffreFormation
): Promise<void> => {
  const guardAction = async () => {
    if (!store.getters.roles) {
      await store.dispatch('obtenirIdentite');
    }

    const rolesParRoute = to.matched.map((record) => record.meta.roles);
    for (const rolesPermis of rolesParRoute) {
      if (!rolesPermis || rolesPermis.length === 0) {
        continue;
      }

      let estAuthorise = false;
      for (const roleUtilisateur of store.getters.roles) {
        if (rolesPermis.includes(roleUtilisateur)) {
          estAuthorise = true;
          break;
        }
      }
      if (!estAuthorise) {
        return next({ name: Routes.ErreurNonAutorise });
      }
    }
    return;
  };
  return guardAction();
};

export default IdentiteRolesGuard;
