export enum TypesCreditsContributoires {
  Fixe = 'fixe',
  Intervale = 'interval',
  Liste = 'liste',
}

export interface CreditsContributoires {
  type: string;
  valeur?: number;
  valeurMax?: number;
  valeurMin?: number;
}
