import VueI18n from 'vue-i18n';
import DateTimeFormats = VueI18n.DateTimeFormats;

const formatsDateTime: DateTimeFormats = {
  fr: {
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
  },
};

export default formatsDateTime;
