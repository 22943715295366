import Faculte from '@/commun/services/set/model/faculte';
import Unite from '@/commun/services/set/model/unites';
import { DateUtils } from '@/commun/utils/date-utils';
import DemandeCours from '@/domain/models/demande-cours';
import { DemandeDocument } from '@/domain/models/demande-document';
import Individu from '@/domain/models/individu';
import { LangueEnseignement } from '@/domain/models/langue-enseignement';
import VueI18n from 'vue-i18n';
import { ConsultationDemande } from '.';
import Demande, { TypesDemande } from '@/domain/models/demande';
const demandeCoursAConsultation = (
  i18n: VueI18n,
  demande: DemandeCours,
  demandeDocuments: (DemandeDocument & { callback: () => void })[],
  individuModification: Individu,
  individuCreation: Individu,
  uniteResponsable?: Unite,
  faculte?: Faculte
): ConsultationDemande => {
  return {
    sections: [
      {
        titre: '',
        ordrePressePaper: 1,
        headers: [],
        lignes: [
          {
            ordrePressePaper: 1,
            nom: i18n.t(`demande.consultation.champ.numero`),
            valeur: demande.numeroDemande,
          },
          {
            ordrePressePaper: 9,
            nom: i18n.t(`demande.consultation.champ.faculte`),
            valeur: faculte?.obtenirNomAffichage() ?? '',
          },
          {
            ordrePressePaper: 2,
            nom: i18n.t(`demande.consultation.champ.type`),
            valeur: i18n.t(`demande.types.${demande.typeDemande}`),
          },
          {
            ordrePressePaper: 3,
            nom: i18n.t('demande.consultation.champ.etat'),
            valeur: i18n.t(`demande.etats.${demande.etatDemande}`),
          },
          {
            ordrePressePaper: 4,
            cachee: true,
            afficherCopieDemande:
              demande.typeDemande !== TypesDemande.CREATION_COURS,
            nom: i18n.t('demande.consultation.champ.faculte-demandeur'),
            valeur: (demande as Demande).cibleDemande?.faculteResponsableCible,
          },
          {
            ordrePressePaper: 7,
            nom: i18n.t('demande.consultation.champ.session'),
            valeur: demande.codeSession,
          },
          {
            ordrePressePaper: 5,
            nom: i18n.t('demande.consultation.champ.sigle'),
            valeur: demande.sigleCours,
          },
          {
            ordrePressePaper: 6,
            nom: i18n.t('demande.consultation.champ.numero-cours'),
            valeur: demande.numeroCours
              ? demande.numeroCours.toString()
              : demande.serieCours
              ? demande.serieCours
              : '',
          },
          {
            ordrePressePaper: 8,
            nom: i18n.t('demande.consultation.champ.titre'),
            valeur: demande.titreCours,
          },
          {
            ordrePressePaper: 10,
            cachee: !demande.uniteResponsable,
            nom: i18n.t('demande.consultation.champ.unite-responsable'),
            valeur: `${uniteResponsable?.codeUnitePedagogique ?? ''} - ${
              uniteResponsable?.nomUnitePedagogique ?? ''
            }`,
          },
          {
            ordrePressePaper: 11,
            nom: i18n.t(
              'demande.consultation.champ.courriel-approbateur-departement'
            ),
            valeur: demande.courrielApprobateurDepartement,
          },
          {
            ordrePressePaper: 12,
            nom: i18n.t(
              'demande.consultation.champ.courriel-approbateur-facultaire'
            ),

            valeur: demande.courrielApprobateurFacultaire,
          },
          {
            ordrePressePaper: 20,
            cachee: !demande.modeEnseignement,
            nom: i18n.t('demande.consultation.champ.mode-enseignement'),
            valeur: i18n.t(
              `demande.modes-enseignement.${demande.modeEnseignement}`
            ),
          },
          {
            ordrePressePaper: 21,
            nom: i18n.t('demande.consultation.champ.section-connexe'),
            valeur: demande.sectionConnexe,
          },
          {
            ordrePressePaper: 22,
            cachee: !demande.attributStage,
            nom: i18n.t('demande.consultation.champ.attribut-stage'),
            valeur: i18n.t(`demande.attribut-stage.${demande.attributStage}`),
          },
          {
            ordrePressePaper: 23,
            nom: i18n.t('demande.consultation.champ.temps-complet'),
            valeur: demande.tempsComplet,
          },
          {
            ordrePressePaper: 18,
            nom:
              demande.modeEnseignement === 'STAGE' ||
              demande.modeEnseignement === 'STAGE_RESIDENCE_MEDECINE'
                ? i18n.t('demande.consultation.champ.stage-reiterable')
                : i18n.t('demande.consultation.champ.cours-reiterable'),
            valeur: demande.repetable,
          },
          {
            ordrePressePaper: 13,

            nom: i18n.t('demande.consultation.champ.credits'),
            valeur: demande.nombreCredits,
          },
          {
            ordrePressePaper: 15,
            nom: i18n.t('demande.consultation.champ.heures-cours'),
            valeur: demande.heuresMagistrales,
          },
          {
            ordrePressePaper: 16,
            nom: i18n.t('demande.consultation.champ.heures-travaux-pratiques'),
            valeur: demande.heuresTravaux,
          },
          {
            ordrePressePaper: 17,
            nom: i18n.t('demande.consultation.champ.heures-travaux-personnels'),
            valeur: demande.heuresPersonnelles,
          },
          {
            ordrePressePaper: 14,
            nom: i18n.t('demande.consultation.champ.unites-education-continue'),
            valeur: demande.nombreUnitesEducationContinue,
          },
          {
            ordrePressePaper: 19,
            cachee: !demande.echelleNotation,
            nom: i18n.t('demande.consultation.champ.echelle-notation'),
            valeur: i18n.t(
              `demande.formulaires.champs.echelle-notation.${demande.echelleNotation}`
            ),
          },
          {
            ordrePressePaper: 26,
            cachee: !demande.langueEnseignement,
            nom: i18n.t('demande.consultation.champ.langue-enseignement'),
            valeur:
              demande.langueEnseignement === LangueEnseignement.AUTRES
                ? i18n.t('commun.oui')
                : i18n.t('commun.non'),
          },
          {
            ordrePressePaper: 27,
            nom: i18n.t('demande.consultation.champ.description'),
            valeur: demande.descriptionCours,
          },
          {
            ordrePressePaper: 29,
            nom: i18n.t('demande.consultation.champ.prealables'),
            valeur: demande.textePrealable,
          },
          {
            ordrePressePaper: 24,
            nom: i18n.t('demande.consultation.champ.concomitants'),
            valeur: demande.texteConcomitant,
          },
          {
            ordrePressePaper: 25,
            nom: i18n.t('demande.consultation.champ.substitutions'),
            valeur: demande.texteSubstitution,
          },
          {
            ordrePressePaper: 28,
            nom: i18n.t('demande.consultation.champ.restrictions'),
            valeur: demande.texteRestriction,
          },
          {
            ordrePressePaper: 30,
            nom: i18n.t('demande.consultation.champ.positionnement'),
            valeur: demande.texteProgrammeCible,
          },
          {
            ordrePressePaper: 31,
            nom: i18n.t(
              'demande.consultation.champ.informations-supplementaires'
            ),
            valeur: demande.informationSupplementaire,
          },
        ],
      },
      {
        titre: i18n.t('demande.programme.demandeur.titre'),
        ordrePressePaper: 2,
        headers: [],
        lignes: [
          {
            ordrePressePaper: 1,
            nom: i18n.t('demande.programme.demandeur.nom'),
            valeur: individuCreation
              ? `${individuCreation.nom}, ${individuCreation.prenom}`
              : '',
          },
          {
            ordrePressePaper: 2,
            nom: i18n.t('demande.consultation.champ.poste-telephonique'),
            valeur: individuCreation ? individuCreation.posteTelephonique : '',
          },
          {
            ordrePressePaper: 3,
            nom: i18n.t('demande.consultation.champ.courriel'),
            valeur: individuCreation ? individuCreation.courriel : '',
          },
          {
            ordrePressePaper: 4,
            nom: i18n.t('demande.consultation.champ.date-creation'),
            valeur: DateUtils.formaterIntlDateTime(
              demande.audit?.horodateCreation,
              i18n.locale
            ),
          },
        ],
      },
      {
        titre: '',
        ordrePressePaper: 3,
        headers: [],
        lignes: [
          {
            ordrePressePaper: 1,
            nom: i18n.t('demande.consultation.champ.date-depot'),
            valeur: DateUtils.formaterIntlDateTime(
              demande.dateDepot,
              i18n.locale
            ),
          },
          {
            ordrePressePaper: 2,
            nom: i18n.t('demande.consultation.champ.commentaires'),
            valeur: demande.commentaireGestionnaire,
          },
        ],
      },
      {
        titre: i18n.t('demande.programme.dernier-intervenant.titre'),
        ordrePressePaper: 4,
        headers: [],
        lignes: [
          {
            ordrePressePaper: 1,
            nom: i18n.t('demande.programme.dernier-intervenant.nom'),
            valeur: individuModification
              ? `${individuModification.nom}, ${individuModification.prenom}`
              : '',
          },
          {
            ordrePressePaper: 2,
            nom: i18n.t(
              'demande.consultation.champ.poste-telephonique-dernier-intervenant'
            ),
            valeur: individuModification
              ? individuModification.posteTelephonique
              : '',
          },
          {
            ordrePressePaper: 3,
            nom: i18n.t(
              'demande.consultation.champ.courriel-dernier-intervenant'
            ),
            valeur: individuModification ? individuModification.courriel : '',
          },
          {
            ordrePressePaper: 4,
            nom: i18n.t(
              'demande.consultation.champ.date-derniere-modification'
            ),
            valeur: DateUtils.formaterIntlDateTime(
              demande.audit?.horodateModification,
              i18n.locale
            ),
          },
        ],
      },
      {
        ordrePressePaper: -1,
        cachee: !demandeDocuments.length,
        estSectionDocument: true,
        titre: i18n.t('demande.programme.documents-joints.titre'),
        headers: [
          i18n
            .t('demande.programme.documents-joints.colonne-fichiers')
            .toString(),
          i18n.t('demande.programme.documents-joints.colonne-date').toString(),
        ],
        lignes: demandeDocuments.map((dd) => ({
          nom: dd.nomFichier,
          valeur: DateUtils.formaterIntlDateTime(dd.horodateDepot, i18n.locale),
          type: 'lien',
          lienCallback: dd.callback,
        })),
      },
    ],
  };
};

export default demandeCoursAConsultation;
