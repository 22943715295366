<template>
  <div>
    <header class="normes-ul-entete-ul">
      <div class="entete-contenu">
        <div class="or">
          <a href="https://www.ulaval.ca" class="logo-ul">
            <img
              src="@/components/cadre/banniere-ulaval/normes-ul/img/logo-ul.svg"
              width="144"
              height="60"
              class="logo-complet"
              :alt="$t('cadre.bqp.entete.logo-ul.alt')"
            />
            <img
              src="@/components/cadre/banniere-ulaval/normes-ul/img/logo-ul-mobile.svg"
              width="144"
              height="60"
              class="logo-mobile"
              :alt="$t('cadre.bqp.entete.logo-ul.alt')"
            />
          </a>
        </div>
        <div class="rouge">
          <div class="titres">
            <router-link
              class="surtitre"
              :to="{
                name: routeAccueil,
              }"
            >
              {{ $t('cadre.bqp.bureau-qualite-programmes') }}
              <div class="titre">
                {{ $t('cadre.bqp.gestion-offre-formation') }}
              </div>
            </router-link>
          </div>
          <div class="navigation">
            <nav class="navigation-generale">
              <ul>
                <li>
                  <router-link
                    :to="{
                      name: routeDeconnexion,
                    }"
                  >
                    {{ $t('commun.actions.deconnexion') }}
                  </router-link>
                </li>
              </ul>
            </nav>
            <div class="utilitaires">
              <a id="nav-toggle" href="#0" class="nav-mobile-trigger">
                <span />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div id="section-recherche" class="normes-ul-recherche" role="search"></div>
    <div class="normes-ul-nav-mobile-wrapper">
      <nav class="nav-mobile charcoal"></nav>
    </div>
    <nav class="normes-ul-navigation-principale pleine-largeur">
      <div>
        <ul>
          <li></li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
  import Routes from '@/router/routes';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'BanniereUlaval',
    data: function () {
      return {
        routeAccueil: Routes.Accueil,
        routeDeconnexion: Routes.Deconnecter,
      };
    },
  });
</script>
