import Vue from 'vue';

export default Vue.extend({
  name: 'EnteteDemandesTableau',
  props: {
    afficherBoutonCreerDemande: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClickCreerDemande(): void {
      this.$emit('naviguerCreerDemande');
    },
  },
});
