export class DateUtils {
  public static dateCourante(): Date {
    return new Date();
  }

  public static ajouterMinutes(date: Date, minutes: number): Date {
    const nouvelleDate = new Date(date);
    nouvelleDate.setMinutes(nouvelleDate.getMinutes() + minutes);
    return nouvelleDate;
  }

  public static ajouterSecondes(date: Date, secondes: number): Date {
    const nouvelleDate = new Date(date);
    nouvelleDate.setSeconds(nouvelleDate.getSeconds() + secondes);
    return nouvelleDate;
  }

  /**
   * Format ISO = YYYY-MM-DD
   * @param date
   */
  public static formaterDateShortISO(date: Date): string {
    const nouvelleDate = new Date(date);
    return nouvelleDate.toISOString().substring(0, 10);
  }

  public static formaterIntlDateTime(
    date?: string,
    locale = 'fr',
    options?: Intl.DateTimeFormatOptions
  ): string {
    const parts = date?.match(/(\d+)/g)?.map((p) => Number(p)) ?? [];

    if (!parts.length) return '';

    options = {
      ...{
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      ...options,
    };

    let dateInstance: Date;

    if (parts.length === 3) {
      dateInstance = new Date(parts[0], parts[1] - 1, parts[2]);
    } else {
      dateInstance = new Date(
        parts[0],
        parts[1] - 1,
        parts[2],
        parts[3],
        parts[4]
      );

      options = { ...options, ...{ hour: 'numeric', minute: 'numeric' } };
    }

    return DateUtils.formaterDebutMois(
      new Intl.DateTimeFormat(locale, options)
        .format(dateInstance)
        .replace(',', ' à ')
        .replace(':', ' h ')
    );
  }
  private static formaterDebutMois(date: string) {
    if (date.startsWith('1 ')) {
      return '1er ' + date.slice(2);
    }
    return date;
  }
}
