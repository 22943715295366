<template>
  <v-tooltip
    v-model="open"
    class="tooltip"
    right
    :open-on-hover="false"
    :open-on-focus="true"
    :max-width="450"
    @click.stop
  >
    <template #activator="{ on }">
      <v-btn
        :class="`ml-${valeurMargeLeft}`"
        icon
        retain-focus-on-click
        small
        @click="on.click"
        @blur="on.blur"
      >
        <v-icon color="primary">
          {{ nomIcone }}
        </v-icon>
      </v-btn>
    </template>
    <div class="d-flex">
      <div class="ma-2 tooltip-messages">
        <p
          v-for="message in messages"
          :key="message"
          class="ultext--text tooltip-messages__message"
          v-html="message"
        />
      </div>
      <v-btn icon @click="close">
        <v-icon color="primary"> mdi-close </v-icon>
      </v-btn>
    </div>
  </v-tooltip>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'Tooltip',
    props: {
      icone: {
        type: String,
        default: 'information',
      },
      valeurMargeLeft: {
        type: String,
        default: '0',
      },
      messages: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        open: false,
      };
    },
    computed: {
      nomIcone(): string {
        return `mdi-${this.icone}`;
      },
    },
    methods: {
      close(): void {
        this.open = false;
      },
    },
  });
</script>

<style lang="scss">
  @import '~@/commun/styles/variables-color.scss';

  .v-tooltip__content {
    background-color: $m-color--information;
    pointer-events: initial;
    border: 1px solid $m-color--interactive;
  }

  .tooltip-messages__message:last-child {
    margin-bottom: 0 !important;
  }
</style>
