import { ParametresAuthorizationCode } from '@/commun/services/auth/service-authorization-code';
import { ParametresServiceEtudes } from '@/commun/services/set/parametres-service-etudes';
import ky from 'ky';
import {
  base_uri,
  parametresAuthorizationCode,
  parametresGestionOffreFormation,
  parametresServiceEtudes,
} from '../../public/env.json';

export interface ParametresGestionOffreFormation {
  url: string;
}

export interface VariablesEnvironnementGOF {
  base_uri: string;
  parametresAuthorizationCode: ParametresAuthorizationCode;
  parametresGestionOffreFormation: ParametresGestionOffreFormation;
  parametresServiceEtudes: ParametresServiceEtudes;
}

export enum ENVIRONNEMENTS_VUE_CLI {
  Production = 'production',
}

async function chargerVariablesEnvironnementProduction(): Promise<VariablesEnvironnementGOF> {
  const configKy: typeof ky = ky.create({
    prefixUrl: `${document.location.origin}`,
  });
  return configKy.get('public/env.json').json<VariablesEnvironnementGOF>();
}

export async function chargerVariablesEnvironnement(
  environnement: string
): Promise<VariablesEnvironnementGOF> {
  switch (environnement) {
    case ENVIRONNEMENTS_VUE_CLI.Production:
      return chargerVariablesEnvironnementProduction();
    default:
      return {
        base_uri,
        parametresAuthorizationCode,
        parametresGestionOffreFormation,
        parametresServiceEtudes,
      } as VariablesEnvironnementGOF;
  }
}
