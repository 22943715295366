import Demande from '@/domain/models/demande';

export function obtenirDifferencesDemande<T extends Demande>(
  original: T,
  nouveau: T
): T {
  const proprietesDifferentes = { ...nouveau };
  Object.keys(original).forEach((cle: string) => {
    const clePropriete = cle as keyof T;
    if (original[clePropriete] === nouveau[clePropriete]) {
      delete proprietesDifferentes[clePropriete];
    }
    delete proprietesDifferentes.audit;
  });
  return proprietesDifferentes;
}
