<template>
  <div v-if="afficherPage">
    <alerte
      type="info"
      :message="
        $t(
          'demande.modification.formulaire.avertissement-desactivation'
        ).toString()
      "
    />
    <formulaire ref="formulaire" class="p-0" @soumettre="onSoumettre">
      <section-identification-cours
        v-model="demandeDesactivationCours"
        :sessions="sessions"
        :type-demande="typeDemande"
        :est-modifiable="coursEstModifiable"
        :detail-cours="detailsCours"
        @annulerRechercheDetailsCours="onAnnulerRechercheDetailsCours"
      />
      <div v-if="afficherFormulaire">
        <section-formulaire
          class="mt-8"
          :titre="$t('demande.formulaires.section.approbation')"
        >
          <champ-formulaire
            :validation="`max:${maximumCaracteresCourrielDirecteur}|email`"
            :nom="
              $t(
                'demande.formulaires.champs.courriel-approbateur-departement.nom'
              )
            "
          >
            <courriel-textfield
              v-model="demandeDesactivationCours.courrielApprobateurDepartement"
              slot-scope="{ errors }"
              :label="
                $t(
                  'demande.formulaires.champs.courriel-approbateur-departement.nom'
                )
              "
              :counter="maximumCaracteresCourrielDirecteur"
              :errors="errors"
            />
            <tooltip
              slot="contenu-droite"
              :messages="[
                $t(
                  'demande.formulaires.champs.courriel-approbateur-facultaire.infobulle'
                ),
              ]"
            />
          </champ-formulaire>

          <champ-formulaire
            :validation="`requis|max:${maximumCaracteresCourrielApprobateurFacultaire}|email`"
            :nom="
              $t(
                'demande.formulaires.champs.courriel-approbateur-facultaire.nom'
              )
            "
          >
            <courriel-textfield
              v-model="demandeDesactivationCours.courrielApprobateurFacultaire"
              slot-scope="{ errors }"
              :label="
                $t(
                  'demande.formulaires.champs.courriel-approbateur-facultaire.nom'
                )
              "
              :counter="maximumCaracteresCourrielApprobateurFacultaire"
              :errors="errors"
            />
            <tooltip
              slot="contenu-droite"
              :messages="[
                $t(
                  'demande.formulaires.champs.courriel-approbateur-facultaire.infobulle'
                ),
              ]"
            />
          </champ-formulaire>
        </section-formulaire>

        <section-information-complementaire
          v-model="demandeDesactivationCours"
          :champs-affiches="champsInformationComplementaire"
        />
        <section-documents
          :demande-document-existants="demandeDocumentExistants"
          @change="nouveauxFichiers = $event"
          @supprimer-demande-document="documentExistantsASupprimer.push($event)"
          @supprimer-all-documents="supprimerAllDocuments"
        />
      </div>
      <div
        :class="[
          estTailleDesktop ? 'flex-row' : 'flex-column',
          'd-flex',
          'mt-8',
        ]"
      >
        <v-btn
          ref="creation-demande-faculte-formulaire__enregistrer-et-continuer"
          class="primary"
          type="submit"
        >
          {{ $t('commun.actions.enregistrer-et-continuer') }}
        </v-btn>
        <v-btn
          :class="[estTailleDesktop ? 'ml-4' : 'mt-6']"
          @click="onClickAnnuler"
        >
          {{ $t('commun.actions.annuler') }}
        </v-btn>
      </div>
    </formulaire>
  </div>
</template>

<script lang="ts">
  import { Alerte } from '@/commun/components/champ-formulaire/alerte';
  import SectionFormulaire from '@/commun/components/champ-formulaire/section-formulaire/section-formulaire.vue';
  import { Formulaire } from '@/commun/components/formulaire';
  import DetailsCours from '@/commun/services/set/model/details-cours';
  import Session from '@/commun/services/set/model/session';
  import { SectionInformationComplementaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-complementaire';
  import { ChampsInformationComplementaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-complementaire/champs-information-complementaire';
  import { TypesDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import Vue, { PropType } from 'vue';
  import { SectionIdentificationCours } from '../modification-cours/section-identification-cours';
  import CourrielTextfield from '@/components/faculte/demandes/formulaires/champs/courriel-textfield/courriel-textfield.vue';
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import tooltip from '@/commun/components/champ-formulaire/tooltip/tooltip.vue';
  import { DemandeDocument } from '@/domain/models/demande-document';
  import { SectionDocuments } from '../creation-cours/section-documents';

  export default Vue.extend({
    name: 'FormulaireDesactivationCours',
    components: {
      Alerte,
      Formulaire,
      SectionFormulaire,
      SectionIdentificationCours,
      SectionInformationComplementaire,
      ChampFormulaire,
      CourrielTextfield,
      tooltip,
      SectionDocuments,
    },
    props: {
      demandeEnModification: {
        type: Object as PropType<DemandeCours>,
        default: undefined,
      },
      sessions: {
        type: Array as PropType<Array<Session>>,
        required: true,
      },
    },
    data: () => ({
      champsInformationComplementaire: [
        ChampsInformationComplementaire.RenseignementsSupplementaires,
      ],
      detailCoursSelectionne: {
        type: Object as PropType<DetailsCours>,
        default: {},
      },
      demandeDesactivationCours: {
        typeDemande: TypesDemande.DESACTIVATION_COURS,
        codeSession: '',
      } as DemandeCours,
      courrielApprobateurDepartement: '',
      courrielApprobateurFacultaire: '',
      maximumCaracteresCourrielDirecteur: 320,
      maximumCaracteresCourrielApprobateurFacultaire: 320,
      nouveauxFichiers: new Array<File>(0),
      documentExistantsASupprimer: new Array<DemandeDocument>(0),
      documentsExistantsTriees: [] as DemandeDocument[],
      afficherPage: false,
    }),
    computed: {
      demandeDocumentExistants(): DemandeDocument[] {
        return this.documentsExistantsTriees.filter(
          (dcd: DemandeDocument) =>
            !this.documentExistantsASupprimer
              .map((das: DemandeDocument) => das.idDocument)
              .includes(dcd.idDocument)
        );
      },
      afficherFormulaire(): boolean {
        if (!this.detailsCours) {
          return false;
        }
        return this.detailsCours.estModifiable();
      },
      coursEstModifiable(): boolean {
        if (!this.detailsCours) {
          return true;
        }
        return this.detailsCours.estModifiable();
      },
      detailsCours(): DetailsCours {
        return this.$store.state.Etudes.detailsCours;
      },
      typeDemande(): string {
        return TypesDemande.DESACTIVATION_COURS;
      },
      estEnModification(): boolean {
        return Boolean(this.demandeEnModification);
      },
      estTailleDesktop(): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
    },
    watch: {
      detailsCours: {
        handler(nouveauDetailsCours: DetailsCours) {
          if (!nouveauDetailsCours) {
            return;
          }
          this.assignerJsonCibleDemande(nouveauDetailsCours);
        },
      },
    },
    async created() {
      this.afficherPage = false;
      await this.$store.commit('demandeCouranteDocuments', []);
      if (this.estEnModification) {
        this.demandeDesactivationCours = { ...this.demandeEnModification };

        await this.$store.dispatch(
          'obtenirDemandeCouranteDocuments',
          this.demandeDesactivationCours.id
        );
        this.documentsExistantsTriees = ([] as DemandeDocument[]).concat(
          this.$store.getters.demandeCouranteDocumentsTrieesParDate
        );
      }
      this.afficherPage = true;
    },
    methods: {
      supprimerAllDocuments() {
        this.documentExistantsASupprimer = this.documentsExistantsTriees;
        this.nouveauxFichiers = new Array<File>(0);
      },

      assignerJsonCibleDemande(detailsCours: DetailsCours) {
        this.demandeDesactivationCours.jsonCibleDemande =
          JSON.stringify(detailsCours);
      },
      onAnnulerRechercheDetailsCours() {
        this.$emit('annulerRechercheDetailsCours');
      },
      onClickAnnuler() {
        this.$emit('annulerFormulaire');
      },
      onSoumettre(): void {
        if (this.afficherFormulaire) {
          this.envoyerFormulaire();
        }
      },
      envoyerFormulaire(): void {
        this.envoyerCreation();
      },
      envoyerCreation(): void {
        this.$emit('creerDemande', {
          demande: this.demandeDesactivationCours,
          nouveauxFichiers: this.nouveauxFichiers,
          documentExistantsASupprimer: this.documentExistantsASupprimer,
        });
      },
    },
  });
</script>
