import { EtatsDemande, TypesDemande } from '@/domain/models/demande';
import DemandeCours from '@/domain/models/demande-cours';
import Vue from 'vue';

interface DemandesTableauLigne {
  demande: DemandeCours;
  id: string;
  numeroDemande: number;
  codeSession: string;
  typeDemande: string;
  codeFaculte: number;
  coursProgramme: string[];
  sigle: string;
  serie: string;
  etatDemande: string;
  titreCours: string;
  demandeur: string;
  dateDepot: string;
}

export const creerLigneDemande = (
  demande: DemandeCours,
  instanceVue: Vue
): DemandesTableauLigne => {
  const cleEtatDemande =
    EtatsDemande[demande.etatDemande as keyof typeof EtatsDemande];
  const etatDemande: string = instanceVue
    .$t(`demande.etats.${cleEtatDemande}`)
    .toString();
  return {
    demande: demande,
    id: demande.id,
    serieCours: demande.serieCours,
    coursProgramme: [
      demande.cibleDemande?.identifiantCible,
      demande.cibleDemande?.titreCible,
    ],
    demandeur: demande.demandeur,
    etatDemande,
    sigle: demande.sigleCours,
    serie: demande.serieCours,
    codeFaculte: demande.cibleDemande?.faculteResponsableCible
      ? Number.parseInt(demande.cibleDemande?.faculteResponsableCible)
      : undefined,
    numeroDemande: demande.numeroDemande,
    codeSession: demande.codeSession,
    typeDemande: TypesDemande[demande.typeDemande as keyof typeof TypesDemande],
    titreCours: demande.titreCours,
    dateDepot: demande.dateDepot ? demande.dateDepot.toString() : '',
  } as DemandesTableauLigne;
};
export default DemandesTableauLigne;
