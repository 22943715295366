import { ServiceRepertoire } from '@/commun/services/set/services/service-repertoire';
import { ServiceSessions } from '@/commun/services/set/services/service-sessions';
import { ServiceUnites } from '@/commun/services/set/services/service-unites';

export interface ServiceEtudes {
  sessions: ServiceSessions;
  repertoire: ServiceRepertoire;
  unites: ServiceUnites;
}

export default class ServiceEtudesHTTP implements ServiceEtudes {
  public constructor(
    public readonly sessions: ServiceSessions,
    public readonly repertoire: ServiceRepertoire,
    public readonly unites: ServiceUnites
  ) {}
}

export class ServiceEtudesMock implements ServiceEtudes {
  public constructor(
    public readonly sessions: ServiceSessions,
    public readonly repertoire: ServiceRepertoire,
    public readonly unites: ServiceUnites
  ) {}
}
