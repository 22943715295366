<template>
  <div />
</template>

<script lang="ts">
  import { IdentiteRoles } from '@/domain/models/identite';
  import Routes from '@/router/routes';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'Accueil',
    async created() {
      if (!this.$store.getters.roles) {
        await this.$store.dispatch('obtenirIdentite');
      }
      if (this.$store.getters.roles.includes(IdentiteRoles.GESTIONNAIRE)) {
        return await this.$router.push({ name: Routes.GestionDemandes });
      } else if (this.$store.getters.roles.includes(IdentiteRoles.DEMANDEUR)) {
        return await this.$router.push({ name: Routes.Demandes });
      }
      return await this.$router.push({ name: Routes.ErreurNonAutorise });
    },
  });
</script>
