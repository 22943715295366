enum Routes {
  Accueil = 'accueil',
  Callback = 'callback',
  Deconnecter = 'deconnecter',
  NotFound = 'notFound',
  ErreurReseau = 'erreurReseau',
  ErreurNonAutorise = 'erreurNonAutorise',
  Demandes = 'demandes',
  DemandeType = 'demande-type',
  CreerDemandeCreationCours = 'creer-demande-creation-cours',
  CreerDemandeModificationCours = 'creer-demande-modification-cours',
  CreerDemandeReactivationCours = 'creer-demande-reactivation-cours',
  CreerDemandeDesactivationCours = 'creer-demande-desactivation-cours',
  CreerDemandeModificationProgramme = 'Creer-demande-modification-programme',
  ConsulterDemande = 'consulter-demande',
  ModifierDemande = 'modifier-demande',
  GestionDemandes = 'gestion-demandes',
  TraitementDemande = 'traitement-demande',
  SectionDemandes = 'section-demandes',
  SectionGestion = 'section-gestion',
}

export const RoutesSansAuthentification: Routes[] = [
  Routes.Callback,
  Routes.NotFound,
  Routes.Deconnecter,
];

export default Routes;
