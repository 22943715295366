export enum TypesMessagesSnackbar {
  Information = 'info',
  Avertissement = 'warning',
  Succes = 'success',
  Erreur = 'error',
}

export class RequeteSnackbar {
  public readonly message: string;

  public readonly typeMessage: TypesMessagesSnackbar;

  public constructor(message: string, typeMessage: TypesMessagesSnackbar) {
    this.message = message;
    this.typeMessage = typeMessage;
  }
}
