<template>
  <div v-if="afficherPage">
    <alerte
      type="info"
      :message="
        $t(
          'demande.modification.formulaire.avertissement-reactivation'
        ).toString()
      "
    />
    <formulaire
      ref="formulaire"
      class="creation-demande-faculte-formulaire"
      @soumettre="onSoumettre"
    >
      <section-identification-cours
        v-model="demandeReactivationCours"
        :cours-actifs="false"
        :type-demande="typeDemande"
        :est-modifiable="coursEstModifiable"
        :sessions="sessions"
        :detail-cours="detailsCours"
        @annulerRechercheDetailsCours="onAnnulerRechercheDetailsCours"
        @rechercherDetailsCours="onRechercherDetailsCours"
        @selectionResumeDetailsCours="onChangeResumeDetailsCours"
      />
      <div v-if="afficherFormulaire">
        <section-formulaire
          class="mt-8"
          :titre="$t('demande.formulaires.section.information-de-base')"
        >
          <champ-formulaire>
            <alerte
              type="info"
              no-margin
              :message="
                $t(
                  'demande.modification.formulaire.avertissement-champs-optionnels'
                ).toString()
              "
            />
          </champ-formulaire>
          <section-information-base
            v-model="demandeReactivationCours"
            :champs-affiches="champsInformationBase"
            :validation-requis="false"
            :session-courante="sessionCourante"
            :matieres="matieres"
            :facultes="facultes"
            :unites-responsables="unitesResponsables"
            :facultes-rattachement="facultesRattachement"
            :roles="roles"
            :set-default-value-unites="false"
          />
        </section-formulaire>

        <section-formulaire class="mt-8">
          <section-information-mode-enseignement
            v-model="demandeReactivationCours"
            afficher-alert
            :champs-affiches="champsInformationModeEnseignement"
            disable-mode-enseignement
            disable-credits
            :afficher-credits="afficherCredits"
            :validation-requis="false"
          >
            <echelle-notation-radio-group
              slot="echelle-notation"
              v-model="demandeReactivationCours.echelleNotation"
              reinitialisable
              validation=""
            />
          </section-information-mode-enseignement>
        </section-formulaire>

        <section-information-complementaire
          v-model="demandeReactivationCours"
          afficher-alert
          :validation-requis="false"
          validation-positionnement-requis
          :champs-affiches="champsInformationComplementaire"
        />
        <section-documents
          :demande-document-existants="demandeDocumentExistants"
          @change="nouveauxFichiers = $event"
          @supprimer-demande-document="documentExistantsASupprimer.push($event)"
          @supprimer-all-documents="supprimerAllDocuments"
        />
      </div>
      <procedures-obligatoires-creation-cours
        class="mt-8"
        :demande-cours="demandeReactivationCours"
      />
      <div
        :class="[
          estTailleDesktop ? 'flex-row' : 'flex-column',
          'd-flex',
          'mt-8',
        ]"
      >
        <v-btn
          ref="creation-demande-faculte-formulaire__enregistrer-et-continuer"
          class="primary"
          type="submit"
        >
          {{ $t('commun.actions.enregistrer-et-continuer') }}
        </v-btn>
        <v-btn
          :class="[estTailleDesktop ? 'ml-4' : 'mt-6']"
          @click="onClickAnnuler"
        >
          {{ $t('commun.actions.annuler') }}
        </v-btn>
      </div>
    </formulaire>
  </div>
</template>

<script lang="ts">
  import { Alerte } from '@/commun/components/champ-formulaire/alerte';
  import SectionFormulaire from '@/commun/components/champ-formulaire/section-formulaire/section-formulaire.vue';
  import { Formulaire } from '@/commun/components/formulaire';
  import DetailsCours from '@/commun/services/set/model/details-cours';
  import Faculte from '@/commun/services/set/model/faculte';
  import Matiere from '@/commun/services/set/model/matiere';
  import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';
  import Session from '@/commun/services/set/model/session';
  import Unite from '@/commun/services/set/model/unites';
  import { EchelleNotationRadioGroup } from '@/components/faculte/demandes/formulaires/champs/echelle-notation';
  import { ProceduresObligatoiresCreationCours } from '@/components/faculte/demandes/formulaires/creation-cours/procedures-obligatoires-dialogue';
  import { ChampsInformationBase } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-base/champs-information-base';
  import SectionInformationBase from '@/components/faculte/demandes/formulaires/creation-cours/section-information-base/section-information-base.vue';
  import { SectionInformationComplementaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-complementaire';
  import { ChampsInformationComplementaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-complementaire/champs-information-complementaire';
  import { ChampsInformationModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/champs-information-mode-enseignement';
  import { TypesDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import { ModesEnseignement } from '@/domain/models/mode-enseignement';
  import Vue, { PropType } from 'vue';
  import { SectionInformationModeEnseignement } from '../creation-cours/section-information-mode-enseignement';
  import SectionIdentificationCours from '../modification-cours/section-identification-cours/section-identification-cours.vue';
  import { IdentiteRoles } from '@/domain/models/identite';
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { SectionDocuments } from '../creation-cours/section-documents';
  import { DemandeDocument } from '@/domain/models/demande-document';

  export default Vue.extend({
    name: 'FormulaireReactivationCours',
    components: {
      EchelleNotationRadioGroup,
      SectionInformationBase,
      Alerte,
      Formulaire,
      ProceduresObligatoiresCreationCours,
      SectionFormulaire,
      SectionIdentificationCours,
      SectionInformationModeEnseignement,
      SectionInformationComplementaire,
      ChampFormulaire,
      SectionDocuments,
    },
    props: {
      demandeEnModification: {
        type: Object as PropType<DemandeCours>,
        default: undefined,
      },
      sessions: {
        type: Array as PropType<Array<Session>>,
        required: true,
      },
      sessionCourante: {
        type: Object as PropType<Session>,
        required: true,
      },
      unitesResponsables: {
        type: Array as PropType<Array<Unite>>,
        required: true,
      },
      facultes: {
        type: Array as PropType<Array<Faculte>>,
        required: true,
      },
      matieres: {
        type: Array as PropType<Array<Matiere>>,
        required: true,
      },
      facultesRattachement: {
        type: Array as PropType<Array<string>>,
        required: true,
      },
      roles: {
        type: Array as PropType<Array<IdentiteRoles>>,
        required: true,
      },
    },
    data: () => ({
      nouveauxFichiers: new Array<File>(0),
      documentExistantsASupprimer: new Array<DemandeDocument>(0),
      enregistrementEnCours: false,
      champsInformationBase: [
        ChampsInformationBase.Faculte,
        ChampsInformationBase.Titre,
        ChampsInformationBase.UniteResponsable,
        ChampsInformationBase.CourrielDirecteur,
        ChampsInformationBase.CourrielResponsableFacultaire,
      ] as ChampsInformationBase[],
      champsInformationModeEnseignement: [
        ChampsInformationModeEnseignement.EchelleNotation,
      ] as ChampsInformationModeEnseignement[],
      champsInformationComplementaire: [
        ChampsInformationComplementaire.DescriptionSommaire,
        ChampsInformationComplementaire.Concomitants,
        ChampsInformationComplementaire.Prealables,
        ChampsInformationComplementaire.RenseignementsSupplementaires,
        ChampsInformationComplementaire.RestrictionsInscription,
        ChampsInformationComplementaire.Substitution,
        ChampsInformationComplementaire.Positionnement,
      ],
      detailCoursSelectionne: {
        type: Object as PropType<DetailsCours>,
        default: {},
      },
      demandeReactivationCours: {
        typeDemande: TypesDemande.REACTIVATION_COURS,
        modeEnseignement: undefined,
        nombreCredits: null,
        nombreUnitesEducationContinue: null,
        codeSession: '',
      } as DemandeCours,
      documentsExistantsTriees: [] as DemandeDocument[],
      afficherPage: false,
    }),
    computed: {
      demandeDocumentExistants(): DemandeDocument[] {
        return this.documentsExistantsTriees.filter(
          (dcd: DemandeDocument) =>
            !this.documentExistantsASupprimer
              .map((das: DemandeDocument) => das.idDocument)
              .includes(dcd.idDocument)
        );
      },
      typeDemande(): string {
        return TypesDemande.REACTIVATION_COURS;
      },
      estEnModification(): boolean {
        return Boolean(this.demandeEnModification);
      },
      afficherFormulaire(): boolean {
        if (!this.detailsCours) {
          return false;
        }
        return this.detailsCours.estModifiable();
      },
      afficherCredits(): boolean {
        return this.detailsCours.estChargeTravailFixe();
      },
      coursEstModifiable(): boolean {
        if (!this.detailsCours) {
          return true;
        }
        return this.detailsCours.estModifiable();
      },
      detailsCours(): DetailsCours {
        return this.$store.state.Etudes.detailsCours;
      },
      estTailleDesktop(): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
    },
    watch: {
      detailsCours: {
        handler(nouveauDetailsCours: DetailsCours) {
          if (!nouveauDetailsCours) {
            return;
          }
          this.assignerModeEnseignement(nouveauDetailsCours);
          this.assignerValeurCredits(nouveauDetailsCours);
          this.assignerJsonCibleDemande(nouveauDetailsCours);
        },
      },
    },
    async created() {
      this.afficherPage = false;
      await this.$store.commit('demandeCouranteDocuments', []);
      if (this.estEnModification) {
        this.demandeReactivationCours = { ...this.demandeEnModification };

        await this.$store.dispatch(
          'obtenirDemandeCouranteDocuments',
          this.demandeReactivationCours.id
        );
        this.documentsExistantsTriees = ([] as DemandeDocument[]).concat(
          this.$store.getters.demandeCouranteDocumentsTrieesParDate
        );

        if (this.detailsCours) {
          this.assignerModeEnseignement(this.detailsCours);
          this.assignerValeurCredits(this.detailsCours);
        }
      }
      this.afficherPage = true;
    },
    methods: {
      supprimerAllDocuments() {
        this.documentExistantsASupprimer = this.documentsExistantsTriees;
        this.nouveauxFichiers = new Array<File>(0);
      },

      assignerValeurCredits(detailsCours: DetailsCours) {
        if (!detailsCours.estChargeTravailFixe()) {
          return;
        }

        const nombreCredits = this.detailsCours.nbCreditsContributoires.valeur;
        const modeEnseignement = detailsCours.modeEnseignement();
        if (modeEnseignement === ModesEnseignement.EducationContinue) {
          this.demandeReactivationCours.nombreUnitesEducationContinue =
            nombreCredits;
        } else {
          this.demandeReactivationCours.nombreCredits = nombreCredits;
        }
      },
      assignerModeEnseignement(detailsCours: DetailsCours) {
        this.demandeReactivationCours.modeEnseignement =
          detailsCours.modeEnseignement();
      },
      assignerJsonCibleDemande(detailsCours: DetailsCours) {
        this.demandeReactivationCours.jsonCibleDemande =
          JSON.stringify(detailsCours);
      },
      onAnnulerRechercheDetailsCours() {
        this.$emit('annulerRechercheDetailsCours');
      },
      onClickAnnuler() {
        this.$emit('annulerFormulaire');
      },
      onRechercherDetailsCours(payload: string) {
        this.$emit('rechercherDetailsCours', payload);
      },
      onChangeResumeDetailsCours(payload: ResumeDetailsCours) {
        this.$emit('changeResumeDetailsCours', payload);
      },
      onSoumettre(): void {
        this.envoyerFormulaire();
      },
      envoyerFormulaire(): void {
        this.envoyerCreation();
      },
      envoyerCreation(): void {
        if (!this.enregistrementEnCours) {
          this.enregistrementEnCours = true;

          this.$emit('creerDemande', {
            demande: this.demandeReactivationCours,
            nouveauxFichiers: this.nouveauxFichiers,
            documentExistantsASupprimer: this.documentExistantsASupprimer,
          });
        }
      },
    },
  });
</script>
