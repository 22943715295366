<template>
  <radio-group
    ref="echelle-notation-radio-group"
    :nom="nom"
    :validation="validation"
    :libelle="$t('demande.formulaires.champs.echelle-notation.nom').toString()"
    :value="value"
    v-bind="$attrs"
    v-on="{
      ...$listeners,
      input: (event) => $emit('input', event),
    }"
  >
    <v-radio
      v-for="echelleNotation in echellesNotation"
      :key="echelleNotation"
      :label="
        $t(`demande.formulaires.champs.echelle-notation.${echelleNotation}`)
      "
      style="width: fit-content"
      :value="`${echelleNotation}`"
    />
    <tooltip
      slot="label"
      :messages="[
        $t('demande.formulaires.champs.echelle-notation.infobulle-1'),
        $t('demande.formulaires.champs.echelle-notation.infobulle-2'),
      ]"
    />
  </radio-group>
</template>

<script lang="ts">
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import RadioGroup from '@/commun/components/radio-group/radio-group.vue';
  import { EchelleNotation } from '@/components/faculte/demandes/formulaires/champs/echelle-notation/echelle-notation';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'EchelleNotationRadioGroup',
    components: { RadioGroup, Tooltip },
    props: {
      value: {
        type: String,
        default: undefined,
        validator(valeur: string) {
          return (Object.values(EchelleNotation) as string[]).includes(valeur);
        },
      },
      nom: {
        type: String,
        default() {
          return this.$t('demande.formulaires.champs.echelle-notation.nom');
        },
      },
      validation: {
        type: String,
        default: 'requis',
      },
    },
    computed: {
      estEchelleLettree(): boolean {
        return this.value && this.value === (EchelleNotation.LETTRE as string);
      },
      echellesNotation(): EchelleNotation[] {
        return Object.values(EchelleNotation);
      },
    },
  });
</script>
