interface DateSession {
  annee: number;
  mois: number;
  jour: number;
}

export const CODE_DERNIERE_SESSION_INDETERMINEE = '999999';

interface Session {
  codeSession: string;
  dateDebutSession: DateSession;
  dateFinSession: DateSession;
  courante: boolean;
  description: string;
  horairePublie: boolean;
}

export default Session;
