import HttpClient from '@/commun/http/http-wrapper';
import Demande, { EtatsDemande } from '@/domain/models/demande';
import { DemandeDocument } from '@/domain/models/demande-document';
import Identite from '@/domain/models/identite';
import Individu from '@/domain/models/individu';

export interface ServiceDemandes {
  creerDemande(demande: Demande): Promise<Demande>;
  modifierDemande(idDemande: string, demande: Demande): Promise<Demande>;
  obtenirDemande(idDemande: string): Promise<Demande>;
  obtenirDemandes(): Promise<Demande[]>;
  supprimerDemande(idDemande: string): Promise<void>;
  deposerDemande(idDemande: string): Promise<void>;
  obtenirIndividu(idIndividu: string): Promise<Individu>;
  obtenirIdentite(): Promise<Identite>;
  obtenirDemandeDocuments(idDemande: string): Promise<DemandeDocument[]>;
  creerDemandeDocument(
    demandeDocument: DemandeDocument
  ): Promise<DemandeDocument>;
  supprimerDemandeDocument(
    idDemande: string,
    idDocument: string
  ): Promise<void>;
  televerserDemandeDocumentFichier(
    idDemande: string,
    idDocument: string,
    formData: FormData
  ): Promise<DemandeDocument>;
  telechargerDemandeDocumentFichier(
    idDemande: string,
    idDocument: string
  ): Promise<Blob>;
}

export default class ServiceDemandesHTTP implements ServiceDemandes {
  public static PATH_DEMANDES = 'demandes';
  public static PATH_INDIVIDUS = 'individus';
  public static PATH_IDENTITE = 'identites';

  public static PATH_IDENTITE_COURANTE = 'current';

  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  creerDemande(demande: Demande): Promise<Demande> {
    return this.httpClient.post<Demande>(ServiceDemandesHTTP.PATH_DEMANDES, {
      json: demande,
    });
  }

  obtenirDemande(idDemande: string): Promise<Demande> {
    return this.httpClient.get<Demande>(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}`
    );
  }

  obtenirIndividu(idIndividu: string): Promise<Individu> {
    return this.httpClient.get<Individu>(
      `${ServiceDemandesHTTP.PATH_INDIVIDUS}/${idIndividu}`
    );
  }

  obtenirIdentite(): Promise<Identite> {
    return this.httpClient.get<Identite>(
      `${ServiceDemandesHTTP.PATH_IDENTITE}/${ServiceDemandesHTTP.PATH_IDENTITE_COURANTE}`
    );
  }

  obtenirDemandes(): Promise<Demande[]> {
    return this.httpClient.get<Demande[]>(ServiceDemandesHTTP.PATH_DEMANDES);
  }

  async modifierDemande(idDemande: string, demande: Demande): Promise<Demande> {
    return this.httpClient.patch<Demande>(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}`,
      {
        json: demande,
      }
    );
  }

  supprimerDemande(idDemande: string): Promise<void> {
    return this.httpClient.delete<void>(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}`
    );
  }

  deposerDemande(idDemande: string): Promise<void> {
    const demandeDepot = { etatDemande: EtatsDemande.DEPOSEE } as Demande;
    return this.httpClient.patch<void>(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}`,
      {
        json: demandeDepot,
      }
    );
  }

  obtenirDemandeDocuments(idDemande: string): Promise<DemandeDocument[]> {
    return this.httpClient.get(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}/documents`
    );
  }

  creerDemandeDocument(
    demandeDocument: DemandeDocument
  ): Promise<DemandeDocument> {
    return this.httpClient.post<DemandeDocument>(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${demandeDocument.idDemande}/documents`,
      { json: demandeDocument }
    );
  }

  supprimerDemandeDocument(
    idDemande: string,
    idDocument: string
  ): Promise<void> {
    return this.httpClient.delete(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}/documents/${idDocument}`
    );
  }

  televerserDemandeDocumentFichier(
    idDemande: string,
    idDocument: string,
    formData: FormData
  ): Promise<DemandeDocument> {
    return this.httpClient.post(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}/documents/${idDocument}/fichier`,
      {
        body: formData,
        timeout: 60000,
      }
    );
  }

  telechargerDemandeDocumentFichier(
    idDemande: string,
    idDocument: string
  ): Promise<Blob> {
    return this.httpClient.getBlob(
      `${ServiceDemandesHTTP.PATH_DEMANDES}/${idDemande}/documents/${idDocument}/fichier`
    );
  }
}
