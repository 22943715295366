import { ValidationFormulaire } from '@/commun/components/champ-formulaire/validation-formulaire';
import Vue from 'vue';

export default Vue.extend({
  name: 'Validable',
  components: {
    ValidationFormulaire,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: String,
      default: '',
    },
  },
  methods: {
    onValueChange(valeur: unknown): void {
      this.$emit('input', valeur);
    },
  },
});
