import { RegleValidation } from '@/commun/validations/regles/regle-validation';
import { Validations } from '@/commun/validations/validations';

/**
 *  Validation utilitaire pour permettre de simuler un champ en échec.
 */
export class RegleEchec implements RegleValidation {
  nom: string = Validations.Echec;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  valider(_valeur: string | undefined | null): boolean {
    return false;
  }

  obtenirRegleValidation = (): Record<string, unknown> => ({
    validate: (valeur: string | undefined | null) => ({
      valid: this.valider(valeur),
    }),
  });
}
