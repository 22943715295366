<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <entete-page :titre="$t('demande.gestion.titre')" />
      <v-btn :href="baseUri + 'archive/index.html'" target="_blank">
        {{ $t('demande.gestion.archives') }}
      </v-btn>
    </div>
    <v-card class="mt-6">
      <demandes-tableau
        class="mt-1"
        :afficher-bouton-creer-demande="afficherBoutonCreerDemande"
        :demandes="demandes"
        :colonnes-affichees="colonnesAffichees"
        :colonne-tri-par-defaut="colonneTri"
      >
        <template #item.numeroDemande.contenu="{ item }">
          <router-link
            class="ml-2"
            :to="{
              name: routeTraitementDemande,
              params: { idDemande: item.id },
            }"
          >
            {{ item.numeroDemande }}
          </router-link>
        </template>
      </demandes-tableau>
    </v-card>
  </div>
</template>

<script lang="ts">
  import EntetePage from '@/commun/components/entete-page/entete-page.vue';
  import { DemandesTableau } from '@/components/demandes/tableau';
  import { DemandesTableauColonnesNom } from '@/components/demandes/tableau/demandes-tableau-colonnes';
  import Demande from '@/domain/models/demande';
  import Routes from '@/router/routes';
  import Vue from 'vue';
  import {
    chargerVariablesEnvironnement,
    ENVIRONNEMENTS_VUE_CLI,
  } from '@/setup/variables-environnement-gof';

  export default Vue.extend({
    name: 'DemandesGestion',
    components: {
      EntetePage,
      DemandesTableau,
    },
    data: () => ({
      afficherBoutonCreerDemande: false,
      colonnesAffichees: [
        DemandesTableauColonnesNom.Numero,
        DemandesTableauColonnesNom.DateDepot,
        DemandesTableauColonnesNom.Session,
        DemandesTableauColonnesNom.Faculte,
        DemandesTableauColonnesNom.Type,
        DemandesTableauColonnesNom.CoursProgramme,
        DemandesTableauColonnesNom.Etat,
      ],
      colonneTri: DemandesTableauColonnesNom.DateDepot,
      routeTraitementDemande: Routes.TraitementDemande,
      baseUri: '',
    }),
    computed: {
      demandes(): Demande[] {
        return this.$store.getters.demandes;
      },
    },
    async created(): Promise<void> {
      await this.$store.dispatch('obtenirDemandes');
      const environnement = process.env
        .NODE_ENV as keyof typeof ENVIRONNEMENTS_VUE_CLI;
      const variables = await chargerVariablesEnvironnement(environnement);
      this.baseUri = variables.base_uri;
    },
  });
</script>
