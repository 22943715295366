<template>
  <div>
    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.Faculte)"
      class="section-information-base__champ__faculte"
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.faculte.nom')"
    >
      <select-autocomplete
        v-model="demandeCourante.codeFaculte"
        slot-scope="{ errors }"
        :errors="errors"
        :label="$t('demande.formulaires.champs.faculte.nom')"
        :items="facultesItems"
        :set-default-value="setDefaultValueUnites"
        @input="onChangementFaculte"
      />
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.Session)"
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.session.nom')"
    >
      <v-text-field
        class="section-information-base__champ__session"
        :label="$t('demande.formulaires.champs.session.nom')"
        :value="sessionCouranteFormatee"
        :messages="$t('demande.formulaires.champs.session.hint')"
        disabled
      >
      </v-text-field>
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.Sigle)"
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.sigle-du-cours.nom')"
    >
      <select-autocomplete
        v-model="demandeCourante.sigleCours"
        slot-scope="{ errors }"
        alphabetique
        :errors="errors"
        :label="$t('demande.formulaires.champs.sigle-du-cours.nom')"
        :items="matieresItems"
      />
      <tooltip
        slot="contenu-droite"
        :messages="[$t('demande.formulaires.champs.sigle-du-cours.infobulle')]"
      />
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.Numero)"
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.serie-du-cours.nom')"
    >
      <serie-cours-select
        v-model="demandeCourante.serieCours"
        slot-scope="{ errors }"
        :errors="errors"
      />
      <tooltip
        slot="contenu-droite"
        :messages="[$t('demande.formulaires.champs.serie-du-cours.infobulle')]"
      />
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.Titre)"
      :validation="`${regleValidationRequis}|max:${maximumCaracteresTitreCours}`"
      :nom="$t('demande.formulaires.champs.titre-du-cours.nom')"
    >
      <titre-cours-textfield
        v-model="demandeCourante.titreCours"
        slot-scope="{ errors }"
        :maximum-caracteres="maximumCaracteresTitreCours"
        :errors="errors"
      />
      <tooltip
        slot="contenu-droite"
        :messages="[$t('demande.formulaires.champs.titre-du-cours.infobulle')]"
      />
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.UniteResponsable)"
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.unite-responsable.nom')"
    >
      <select-autocomplete
        v-model="demandeCourante.uniteResponsable"
        slot-scope="{ errors }"
        alphabetique
        :label="$t('demande.formulaires.champs.unite-responsable.nom')"
        :items="unitesResponsablesItems"
        :errors="errors"
        :set-default-value="setDefaultValueUnites"
      />
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.CourrielDirecteur)"
      :validation="`max:${maximumCaracteresCourrielDirecteur}|email`"
      :nom="
        $t('demande.formulaires.champs.courriel-approbateur-departement.nom')
      "
    >
      <courriel-textfield
        v-model="demandeCourante.courrielApprobateurDepartement"
        slot-scope="{ errors }"
        :label="
          $t('demande.formulaires.champs.courriel-approbateur-departement.nom')
        "
        :counter="maximumCaracteresCourrielDirecteur"
        :errors="errors"
      />
      <tooltip
        slot="contenu-droite"
        :messages="[
          $t(
            'demande.formulaires.champs.courriel-approbateur-facultaire.infobulle'
          ),
        ]"
      />
    </champ-formulaire>
    <champ-formulaire
      v-if="afficherChamp(champsInformationBase.CourrielResponsableFacultaire)"
      :validation="`requis|max:${maximumCaracteresCourrielApprobateurFacultaire}|email`"
      :nom="
        $t('demande.formulaires.champs.courriel-approbateur-facultaire.nom')
      "
    >
      <courriel-textfield
        v-model="demandeCourante.courrielApprobateurFacultaire"
        slot-scope="{ errors }"
        :label="
          $t('demande.formulaires.champs.courriel-approbateur-facultaire.nom')
        "
        :counter="maximumCaracteresCourrielApprobateurFacultaire"
        :errors="errors"
      />
      <tooltip
        slot="contenu-droite"
        :messages="[
          $t(
            'demande.formulaires.champs.courriel-approbateur-facultaire.infobulle'
          ),
        ]"
      />
    </champ-formulaire>
  </div>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { SelectAutocomplete } from '@/commun/components/champ-formulaire/select-autocomplete';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import Faculte from '@/commun/services/set/model/faculte';
  import Matiere from '@/commun/services/set/model/matiere';
  import Session from '@/commun/services/set/model/session';
  import Unite, {
    CODE_AUCUNE_FACULTE_DESIGNEE,
    CODE_NON_UTILISE,
    TypeUnite,
  } from '@/commun/services/set/model/unites';
  import CourrielTextfield from '@/components/faculte/demandes/formulaires/champs/courriel-textfield/courriel-textfield.vue';
  import { SerieCoursSelect } from '@/components/faculte/demandes/formulaires/champs/information-base-cours/serie-cours';
  import { TitreCoursTextfield } from '@/components/faculte/demandes/formulaires/champs/information-base-cours/titre-cours';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { ChampsInformationBase } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-base/champs-information-base';
  import Vue, { PropType } from 'vue';
  import { IdentiteRoles } from '@/domain/models/identite';
  import DemandeCours from '@/domain/models/demande-cours';

  export default Vue.extend({
    name: 'SectionInformationBase',
    components: {
      ChampFormulaire,
      CourrielTextfield,
      SelectAutocomplete,
      SerieCoursSelect,
      TitreCoursTextfield,
      Tooltip,
    },
    mixins: [SectionFormulaireCours],
    props: {
      champsAffiches: {
        type: Array as PropType<Array<ChampsInformationBase>>,
        default: () => Object.values(ChampsInformationBase),
      },
      unitesResponsables: {
        type: Array as PropType<Array<Unite>>,
        required: true,
      },
      facultes: {
        type: Array as PropType<Array<Faculte>>,
        required: true,
      },
      matieres: {
        type: Array,
        required: true,
      },
      sessionCourante: {
        type: Object as PropType<Session>,
        required: true,
      },
      facultesRattachement: {
        type: Array as PropType<Array<string>>,
        required: true,
      },
      roles: {
        type: Array as PropType<Array<IdentiteRoles>>,
        required: true,
      },
      setDefaultValueUnites: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      maximumCaracteresTitreCours: 60,
      maximumCaracteresDescriptionCours: 800,
      maximumCaracteresCourrielDirecteur: 320,
      maximumCaracteresCourrielApprobateurFacultaire: 320,
      champsInformationBase: ChampsInformationBase,
      demandeCourante: {} as DemandeCours,
    }),
    computed: {
      facultesItems(): SelectAutocompleteItem[] {
        return (this.facultes as Faculte[])
          .filter(
            (faculte: Faculte) =>
              (this.roles.includes(IdentiteRoles.GESTIONNAIRE) ||
                this.facultesRattachement.includes(
                  faculte.codeUnitePedagogique
                )) &&
              faculte.affichableDansListeDeValeurs &&
              faculte.codeUnitePedagogique !== CODE_NON_UTILISE &&
              faculte.codeUnitePedagogique !== CODE_AUCUNE_FACULTE_DESIGNEE
          )
          .map((faculte: Faculte) => ({
            texte: faculte.obtenirNomAffichage(),
            valeur: faculte.codeUnitePedagogique,
          }));
      },
      matieresItems(): SelectAutocompleteItem[] {
        return (this.matieres as Matiere[]).map((matiere: Matiere) => ({
          texte: `${matiere.sigleMatiere} - ${matiere.description}`,
          valeur: matiere.sigleMatiere,
        }));
      },
      sessionCouranteFormatee(): string {
        return `${this.sessionCourante.codeSession} - ${this.sessionCourante.description}`;
      },
      unitesResponsablesValides(): Unite[] {
        return this.unitesResponsables.filter(
          (unite: Unite) =>
            (this.roles.includes(IdentiteRoles.GESTIONNAIRE) ||
              this.facultesRattachement.includes(
                unite.codeUnitePedagogiqueMere
              )) &&
            unite.affichableDansListeDeValeurs &&
            unite.type === TypeUnite.DEPARTEMENT &&
            unite.codeUnitePedagogiqueMere !== CODE_AUCUNE_FACULTE_DESIGNEE &&
            unite.codeUnitePedagogiqueMere !== CODE_NON_UTILISE
        );
      },
      unitesResponsablesItems(): SelectAutocompleteItem[] {
        if (!this.unitesResponsablesValides) {
          return [];
        }
        return this.unitesResponsablesValides.slice().map((unite: Unite) => ({
          texte: `${unite.codeUnitePedagogique} - ${unite.nomUnitePedagogique}`,
          valeur: unite.codeUnitePedagogique,
        }));
      },
    },
    methods: {
      afficherChamp(champ: ChampsInformationBase): boolean {
        return this.champsAffiches?.some(
          (champAffiche) => champ === champAffiche
        );
      },
      onChangementFaculte(faculte: Unite): void {
        this.$emit('changementFaculte', faculte);
      },
    },
  });
</script>

<style lang="scss">
  @import '~@/commun/styles/variables-layout.scss';

  .section-information-base {
    &__champ {
      &__faculte {
        margin-top: $gof-margin;
      }

      &__session {
        padding: 0 0 $gof-min-height-champ 0;
        margin: 0;
      }
    }
  }
</style>
