<template>
  <v-text-field
    class="ma-0 pa-0"
    :value="value"
    :error-messages="errors"
    :counter="maximumCaracteres"
    :label="$t('demande.formulaires.champs.titre-du-cours.nom')"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onValueChange"
  />
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'TitreCoursTextfield',
    mixins: [Validable],
    props: {
      maximumCaracteres: {
        type: Number,
        default: 60,
      },
      nom: {
        type: String,
        default() {
          return this.$t('demande.formulaires.champs.titre-du-cours.nom');
        },
      },
      value: {
        type: String,
        default: '',
      },
    },
  });
</script>
