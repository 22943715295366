<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <h1>{{ $t('erreur.reseau.titre') }}</h1>
        <p v-html="$t('erreur.reseau.message')" />
        <v-btn @click="onRetournerAccueil">
          {{ $t('commun.actions.retourner-accueil') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import Routes from '@/router/routes';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ErreurReseau',
    methods: {
      onRetournerAccueil(): void {
        this.$router.push({ name: Routes.Accueil });
      },
    },
  });
</script>
