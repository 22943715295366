<template>
  <div>
    <section-formulaire
      class="mt-4"
      :titre="$t('demande.programme.approbations.titre').toString()"
    >
      <date-picker
        v-model="demandeCourante.dateSeanceComiteProgramme"
        :label="$t('demande.programme.approbations.date-programme').toString()"
        validation="requis"
      />

      <champ-formulaire
        :validation="`requis|max:${maximumCaracteresCourriel}|email`"
      >
        <v-text-field
          v-model="demandeCourante.courrielApprobateurFacultaire"
          slot-scope="{ errors }"
          :error-messages="errors"
          :label="
            $t('demande.programme.approbations.courriel-facultaire').toString()
          "
          :counter="maximumCaracteresCourriel"
          :placeholder="
            $t('demande.programme.approbations.courriel-placeholder').toString()
          "
        />
        <tooltip
          slot="contenu-droite"
          :messages="[
            $t('demande.programme.approbations.courriel-infobulle').toString(),
          ]"
        />
      </champ-formulaire>
      <date-picker
        v-model="demandeCourante.dateSeanceConseilFacultaire"
        :label="$t('demande.programme.approbations.date-facultaire').toString()"
        :validation="regleValidationDateFacultaire"
      />
    </section-formulaire>
    <section-formulaire
      class="mt-4"
      :titre="$t('demande.programme.approbations.justification').toString()"
    >
      <text-area
        v-model="demandeCourante.justification"
        :validation="`requis|max:${maximumCaracteresJustification}`"
        :label="$t('demande.programme.approbations.justification').toString()"
        :maximum-caracteres="maximumCaracteresJustification"
        :placeholder="
          $t(
            'demande.programme.approbations.justification-placeholder'
          ).toString()
        "
      />
    </section-formulaire>
  </div>
</template>

<script lang="ts">
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import { SectionFormulaire } from '@/commun/components/champ-formulaire/section-formulaire';
  import { TextArea } from '@/commun/components/champ-formulaire/text-area';
  import Vue, { PropType } from 'vue';
  import DemandeProgramme, {
    DemandeProgrammeTypeModification,
    IndicateursModificationProgramme,
  } from '@/domain/models/demande-programme';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import { DatePicker } from '@/commun/components/champ-formulaire/date-picker';
  import { SectionFormulaireProgramme } from '@/components/faculte/demandes/formulaires/programme/section-formulaire-programme';

  export default Vue.extend({
    name: 'SectionApprobation',
    components: {
      TextArea,
      SectionFormulaire,
      ChampFormulaire,
      Tooltip,
      DatePicker,
    },
    mixins: [SectionFormulaireProgramme],
    props: {
      value: {
        type: Object as PropType<DemandeProgramme>,
        required: true,
      },
      indicateursProgramme: {
        type: Object as PropType<IndicateursModificationProgramme>,
        required: true,
      },
    },
    data() {
      return {
        demandeCourante: {} as DemandeProgramme,
        validationDate: false,
        maximumCaracteresCourriel: 320,
        maximumCaracteresJustification: 3500,
        dateFacultaire: null,
        menuProgramme: false,
        menuFacultaire: false,
      };
    },
    computed: {
      champDateFacultaireObligatoire(): boolean | undefined {
        return (
          this.indicateursProgramme.admissionExigences ||
          this.indicateursProgramme.admissionSessions ||
          this.indicateursProgramme.admissionSuspension ||
          this.indicateursProgramme.composantesConcentrations ||
          this.indicateursProgramme.composantesMineures ||
          this.indicateursProgramme.nombreCreditsAugmentation ||
          this.indicateursProgramme.nombreCreditsReduction
        );
      },
      regleValidationDateFacultaire(): string {
        if (
          this.demandeCourante.typeModification ===
            DemandeProgrammeTypeModification.MAJEURE_EXISTANTE &&
          !this.champDateFacultaireObligatoire
        ) {
          return '';
        }
        return 'requis';
      },
    },
  });
</script>
