<template>
  <h3 class="text-h5 pt-4 pb-4">{{ titre }}</h3>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'EntetePage',
    props: {
      titre: {
        type: String,
        default: '',
      },
    },
  });
</script>
