import { RegleValidation } from '@/commun/validations/regles/regle-validation';
import { Validations } from '@/commun/validations/validations';
import { required } from 'vee-validate/dist/rules';

export class RegleRequis implements RegleValidation {
  nom: string = Validations.Requis;

  valider(
    valeur: string | undefined | null | Record<string, unknown>
  ): boolean {
    return required.validate(valeur).valid;
  }

  obtenirRegleValidation = (): Record<string, unknown> => ({
    computesRequired: true,
    validate: (
      valeur: string | undefined | null | Record<string, unknown>
    ) => ({
      required: true,
      valid: this.valider(valeur),
    }),
  });
}
