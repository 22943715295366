import HttpWrapper from '@/commun/http/http-wrapper';
import ServiceEtudesHTTP, {
  ServiceEtudes,
} from '@/commun/services/set/services/service-etudes';
import ServiceRepertoireHTTP from '@/commun/services/set/services/service-repertoire';
import ServiceSessionsImpl from '@/commun/services/set/services/service-sessions';
import ServiceUnitesHttp from '@/commun/services/set/services/service-unites';
import Services from '@/services/services';
import { Container } from 'inversify';

export default class ServiceEtudesContainer {
  public static enregistrerServices(
    clientHttp: HttpWrapper,
    container: Container
  ): void {
    container
      .bind<ServiceEtudes>(Services.ServiceEtudes)
      .toConstantValue(
        new ServiceEtudesHTTP(
          new ServiceSessionsImpl(clientHttp),
          new ServiceRepertoireHTTP(clientHttp),
          new ServiceUnitesHttp(clientHttp)
        )
      );
  }
}
