<template>
  <section-formulaire-vue
    class="mt-4"
    :titre="$t('demande.formulaires.section.documents')"
  >
    <input
      ref="input"
      style="display: none"
      type="file"
      :multiple="nombreFichiersMaximum > 1"
      :accept="accept.map((a) => `.${a}`).join(',')"
      @change="onChange($event.target.files)"
    />
    <div class="d-flex flex-wrap">
      <v-btn class="mr-8 mb-4" color="primary" @click="onAjouter">{{
        $t('demande.formulaires.champs.joindre-fichiers.nom')
      }}</v-btn>
      <v-btn class="mb-4" @click="supprimerTout">{{
        $t('demande.formulaires.champs.retirer-fichiers.nom')
      }}</v-btn>
    </div>
    <v-alert
      :value="!!messagesAvertissement.length"
      class="mt-2"
      outlined
      type="warning"
      dismissible
      @input="onAlertChange"
    >
      <ul>
        <li v-for="(message, i) in messagesAvertissement" :key="i">
          {{ message }}
        </li>
      </ul>
    </v-alert>
    <template v-if="nouveauxFichiers.length">
      <v-list>
        <v-list-item v-for="(fichier, i) in nouveauxFichiers" :key="i">
          <v-list-item-content>
            <v-list-item-title
              style="font-size: 16px"
              v-text="fichier.name"
            ></v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="onSupprimerNouveauFichier(i)">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <template v-if="demandeDocumentExistants.length">
      <span style="font-size: 18px">
        {{ $t('demande.formulaires.section.fichiers-joins') }}
      </span>
      <v-list two-line>
        <v-list-item
          v-for="(demandeDocument, i) in demandeDocumentExistants"
          :key="i"
        >
          <v-list-item-content>
            <a
              style="font-size: 16px"
              href="#"
              @click.prevent="ouvrirFichier(demandeDocument)"
              >{{ demandeDocument.nomFichier }}</a
            >
            <v-list-item-subtitle
              style="font-size: 14px"
              v-text="dateDepotFormatee(demandeDocument.horodateDepot)"
            ></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click="$emit('supprimer-demande-document', demandeDocument)"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
  </section-formulaire-vue>
</template>

<script lang="ts">
  import SectionFormulaireVue from '@/commun/components/champ-formulaire/section-formulaire/section-formulaire.vue';
  import { DateUtils } from '@/commun/utils/date-utils';
  import Vue, { PropType } from 'vue';
  import { DemandeDocument } from '@/domain/models/demande-document';
  import FileSaver from 'file-saver';

  export default Vue.extend({
    name: 'SectionDocuments',
    components: {
      SectionFormulaireVue,
    },
    props: {
      demandeDocumentExistants: {
        type: Array as PropType<Array<DemandeDocument>>,
        default: () => [],
      },
      accept: {
        type: Array as PropType<Array<string>>,
        default: () => [
          'doc',
          'docx',
          'pdf',
          'xls',
          'xlsx',
          'png',
          'jpg',
          'jpeg',
          'DOC',
          'DOCX',
          'PDF',
          'XLS',
          'XLXS',
          'PNG',
          'JPG',
          'JPEG',
        ],
      },
      taillMaxParFichierEnBytes: {
        type: Number,
        default: 10485760,
      },
      nombreFichiersMaximum: {
        type: Number,
        default: 50,
      },
    },
    data: () => ({
      nouveauxFichiers: new Array<File>(0),
      messagesAvertissement: new Array<string>(0),
    }),
    methods: {
      async ouvrirFichier(d: DemandeDocument): Promise<void> {
        const blob = await this.$store.dispatch(
          'telechargerDemandeDocumentFichier',
          {
            idDemande: d.idDemande,
            idDocument: d.idDocument,
          }
        );
        FileSaver.saveAs(blob, d.nomFichier);
      },

      supprimerTout(): void {
        this.nouveauxFichiers = new Array<File>(0);
        this.$emit('supprimer-all-documents');
      },
      emitChange(): void {
        this.$emit('change', this.nouveauxFichiers);
      },
      onAjouter(): void {
        (this.$refs.input as HTMLInputElement).click();
      },
      onChange(fileList: FileList): void {
        const files = [
          this.filterFichiersExtensionsRefusees,
          this.filtrerDocumentsTropVolumineux,
          this.enleverFichiersDeTrop,
        ].reduce((acc, cur) => cur(acc), Array.from(fileList));

        this.nouveauxFichiers = this.nouveauxFichiers.concat(
          files.filter(
            (f) => !this.nouveauxFichiers.map((nf) => nf.name).includes(f.name)
          )
        );
        this.emitChange();
      },
      onSupprimerNouveauFichier(index: number): void {
        this.nouveauxFichiers.splice(index, 1);
        this.emitChange();
      },
      filterFichiersExtensionsRefusees(files: File[]): File[] {
        return files.filter((d) => {
          const accepte = this.accept.some((a) => d.name.endsWith(a));

          if (!accepte) {
            this.messagesAvertissement.push(
              `${d.name}: ${this.$i18n
                .t(
                  'demande.formulaires.champs.documents.avertissement-extension'
                )
                .toString()}`
            );
          }

          return accepte;
        });
      },
      filtrerDocumentsTropVolumineux(files: File[]): File[] {
        return files.filter((d) => {
          const accepte = d.size <= this.taillMaxParFichierEnBytes;

          if (!accepte) {
            this.messagesAvertissement.push(
              `${d.name}: ${this.$i18n
                .t('demande.formulaires.champs.documents.avertissement-taille')
                .toString()}`
            );
          }

          return accepte;
        });
      },
      enleverFichiersDeTrop(files: File[]): File[] {
        const copy = files.slice();
        const nombreDeFichiersExistants =
          this.nouveauxFichiers.length + this.demandeDocumentExistants.length;

        files = files.slice(
          0,
          this.nombreFichiersMaximum - nombreDeFichiersExistants
        );

        copy
          .slice(this.nombreFichiersMaximum - nombreDeFichiersExistants)
          .forEach((d) => {
            this.messagesAvertissement.push(
              `${d.name}: ${this.$i18n
                .t('demande.formulaires.champs.documents.avertissement-nombre')
                .toString()}`
            );
          });

        return files;
      },
      onAlertChange(value: boolean) {
        if (!value) this.messagesAvertissement = [];
      },
      dateDepotFormatee(horodateDepot: string): string {
        return DateUtils.formaterIntlDateTime(horodateDepot);
      },
    },
  });
</script>
