<template>
  <div>
    <radio-group-oui-non
      :value="valeurInterne"
      :validation="validation"
      :libelle="$t('demande.formulaires.champs.cours-en-francais.libelle')"
      :nom="nom"
      :reinitialisable="radioReinitialisable"
      @input="onValueChange"
    />
  </div>
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import { RadioGroupOuiNon } from '@/commun/components/champ-formulaire/radio-group-oui-non';
  import { Validations } from '@/commun/validations/validations';
  import { LangueEnseignement } from '@/domain/models/langue-enseignement';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'CoursEnFrancaisRadioGroup',
    components: {
      RadioGroupOuiNon,
    },
    mixins: [Validable],
    props: {
      value: {
        type: String,
        default: LangueEnseignement.FRANCAIS,
        validator(valeur: string) {
          return (Object.values(LangueEnseignement) as string[]).includes(
            valeur
          );
        },
      },
      nom: {
        type: String,
        default() {
          return this.$t('demande.formulaires.champs.cours-en-francais.nom');
        },
      },
      validation: {
        type: String,
        default: Validations.Requis,
      },
      radioReinitialisable: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      valeurInterne: false,
    }),
    watch: {
      value: {
        immediate: true,
        handler(valeur: string) {
          if (valeur === LangueEnseignement.FRANCAIS) {
            this.valeurInterne = false;
            return;
          }
          this.valeurInterne = true;
        },
      },
    },
    methods: {
      onValueChange(valeur: boolean) {
        const langue = valeur
          ? LangueEnseignement.AUTRES
          : LangueEnseignement.FRANCAIS;
        this.$emit('input', langue);
      },
    },
  });
</script>
