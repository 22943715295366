<template>
  <div>
    <champ-formulaire
      :nom="$t('demande.formulaires.champs.cours-reiterable.nom')"
    >
      <radio-group-oui-non
        v-model="demandeCourante.repetable"
        slot-scope="{ errors }"
        class="mt-0"
        :errors="errors"
        :reinitialisable="radioReinitialisable"
        :libelle="$t('demande.formulaires.champs.cours-reiterable.libelle')"
      >
        <tooltip
          slot="label"
          :messages="[
            $t('demande.formulaires.champs.cours-reiterable.infobulle'),
          ]"
        >
        </tooltip>
      </radio-group-oui-non>
    </champ-formulaire>

    <champ-formulaire
      v-if="afficherCredits"
      :validation="regleValidationRequis"
    >
      <stepper-nombre
        v-model="demandeCourante.nombreUnitesEducationContinue"
        slot-scope="{ errors }"
        :disabled="disableCredits"
        :afficher-placeholder="false"
        :errors="errors"
        :label="$t('demande.formulaires.champs.unites-education-continue.nom')"
        :precision="2"
        :min="0"
        :max="99.99"
        :nom="$t('demande.formulaires.champs.unites-education-continue.nom')"
      />
    </champ-formulaire>
  </div>
</template>

<script lang="ts">
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import { RadioGroupOuiNon } from '@/commun/components/champ-formulaire/radio-group-oui-non';
  import { StepperNombre } from '@/commun/components/champ-formulaire/stepper-nombre';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { SectionModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ModeEnseignementEducationContinueFormulaire',
    components: {
      ChampFormulaire,
      RadioGroupOuiNon,
      Tooltip,
      StepperNombre,
    },
    mixins: [SectionFormulaireCours, SectionModeEnseignement],
    props: {
      radioReinitialisable: {
        type: Boolean,
        default: true,
      },
    },
  });
</script>
