<template>
  <div v-if="demandeCourante && afficherTableau">
    <v-breadcrumbs class="pl-0" :items="breadcrumbsItems" />
    <procedures-obligatoires-creation-cours :demande-cours="demandeCourante" />
    <v-card class="mt-8">
      <consultation-demande-liste
        :demande="demandeCourante"
        :facultes="facultes"
        :unites="unites"
        :individu-modification="individuModification"
        :individu-creation="individuCreation"
      />
    </v-card>
    <div class="mt-6 d-flex flex-column">
      <div>
        <v-dialog v-model="dialogDeposerOuvert" max-width="40%">
          <template #activator="{ on }">
            <v-btn
              v-if="afficherBoutonDeposer"
              key="deposer"
              class="consultation-demande-faculte__actions__bouton"
              color="primary"
              v-on="on"
            >
              {{ $t('demande.depot.deposer') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              {{ $t('demande.depot.confirmation') }}
            </v-card-title>
            <v-card-text>
              <p>
                {{ $t('demande.depot.avertissement') }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                ref="consultation-demande-faculte--actions--deposer"
                color="primary"
                @click="onClickConfirmerDepot"
              >
                {{ $t('demande.depot.deposer') }}
              </v-btn>
              <v-btn @click="onClickAnnulerDepot">
                {{ $t('commun.actions.annuler') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="afficherBoutonModifier"
          ref="consultation-demande-faculte--actions--modifier"
          class="consultation-demande-faculte__actions__bouton"
          @click="onClickBoutonModifier"
        >
          {{ $t('commun.actions.modifier') }}
        </v-btn>
        <v-btn
          ref="consultation-demande-faculte--actions--fermer"
          :color="couleurBoutonFermer"
          class="consultation-demande-faculte__actions__bouton"
          @click="onClickBoutonFermer"
        >
          {{ $t('commun.actions.fermer') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    RequeteSnackbar,
    TypesMessagesSnackbar,
  } from '@/commun/components/snackbars/requete-snackbar';
  import Faculte from '@/commun/services/set/model/faculte';
  import Unite from '@/commun/services/set/model/unites';
  import { ConsultationDemandeListe } from '@/components/faculte/demandes/consultation';
  import { ProceduresObligatoiresCreationCours } from '@/components/faculte/demandes/formulaires/creation-cours/procedures-obligatoires-dialogue';
  import Demande, { EtatsDemande } from '@/domain/models/demande';
  import Individu from '@/domain/models/individu';
  import Routes from '@/router/routes';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ConsulterDemandeFaculte',
    components: {
      ConsultationDemandeListe,
      ProceduresObligatoiresCreationCours,
    },
    props: {
      idDemande: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      afficherTableau: false,
      dialogDeposerOuvert: false,
      etatsAffichageBoutonDeposer: [
        EtatsDemande.EN_REDACTION,
        EtatsDemande.EN_MODIFICATION,
      ],
      etatsAffichageBoutonModifier: [
        EtatsDemande.EN_REDACTION,
        EtatsDemande.EN_MODIFICATION,
      ],
    }),
    computed: {
      afficherBoutonDeposer(): boolean {
        return this.etatsAffichageBoutonDeposer.some(
          (etatAffiche) => this.demandeCourante.etatDemande === etatAffiche
        );
      },
      afficherBoutonModifier(): boolean {
        return this.etatsAffichageBoutonModifier.some(
          (etatAffiche) => this.demandeCourante.etatDemande === etatAffiche
        );
      },
      couleurBoutonFermer(): string {
        if (
          !this.etatsAffichageBoutonDeposer.some(
            (etatAffiche) => this.demandeCourante.etatDemande === etatAffiche
          )
        ) {
          return 'primary';
        }
        return '';
      },
      breadcrumbsItems(): Array<unknown> {
        return [
          {
            disabled: false,
            text: this.$i18n.t(
              'demande.formulaires.navigation-liste-de-demandes'
            ),
            exact: true,
            to: { name: Routes.Demandes },
          },
          {
            text: `${this.$i18n.t('demande.consultation.titre')}`,
          },
        ];
      },
      demandeCourante(): Demande {
        return this.$store.state.GestionOffreFormation.demandeCourante;
      },
      facultes(): Faculte[] {
        return this.$store.getters.facultes;
      },
      unites(): Unite[] {
        return this.$store.state.Etudes.unites;
      },
      individuCreation(): Individu {
        return this.$store.state.GestionOffreFormation.individus.find(
          (individu: Individu) =>
            individu.idIndividu ===
            this.demandeCourante.audit?.idIndividuCreation
        );
      },
      individuModification(): Individu {
        return this.$store.state.GestionOffreFormation.individus.find(
          (individu: Individu) =>
            individu.idIndividu ===
            this.demandeCourante.audit?.idIndividuModification
        );
      },
    },
    watch: {
      dialogDeposerOuvert(valeur: boolean) {
        if (valeur) {
          this.$nextTick(() => {
            this.focusBoutonDeposer();
          });
        }
      },
    },
    async created() {
      await Promise.all([
        this.$store.dispatch('obtenirDemande', this.idDemande),
        this.$store.dispatch('obtenirUnites'),
      ]);

      const idIndividuCreation =
        this.$store.state.GestionOffreFormation.demandeCourante?.audit
          ?.idIndividuCreation;
      const idIndividuModification =
        this.$store.state.GestionOffreFormation.demandeCourante?.audit
          ?.idIndividuModification;
      await Promise.all([
        this.$store.dispatch('obtenirIndividu', idIndividuCreation),
        this.$store.dispatch('obtenirIndividu', idIndividuModification),
      ]);
      this.afficherTableau = true;
    },

    methods: {
      focusBoutonDeposer(): void {
        const boutonDeposer = this.$refs[
          'consultation-demande-faculte--actions--deposer'
        ] as Vue;
        setTimeout(() => {
          (boutonDeposer.$el as HTMLElement).focus();
        });
      },
      onClickAnnulerDepot(): void {
        this.dialogDeposerOuvert = false;
      },
      onClickConfirmerDepot(): void {
        this.$store.dispatch('deposerDemande', this.idDemande).then(() => {
          this.dialogDeposerOuvert = false;
          const requeteSnackbar = new RequeteSnackbar(
            this.$t('demande.depot.succes').toString(),
            TypesMessagesSnackbar.Succes
          );
          this.$store.dispatch('notifierUtilisateur', requeteSnackbar);
          this.$router.push({ name: Routes.Demandes });
        });
      },
      onClickBoutonModifier(): void {
        this.$router.push({
          name: Routes.ModifierDemande,
          params: {
            idDemande: this.idDemande,
          },
        });
      },
      onClickBoutonFermer(): void {
        this.$router.push({ name: Routes.Demandes });
      },
    },
  });
</script>

<style lang="scss">
  @import '~@/commun/styles/variables-layout.scss';

  .consultation-demande-faculte {
    &__actions {
      &__bouton {
        margin-right: $m-margin;
      }
    }
  }
</style>
