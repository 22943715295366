var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('formulaire',{ref:"formulaire",on:{"soumettre":_vm.onSoumettre}},[_c('section-formulaire',{attrs:{"encadre":false,"elevation":0}},[_c('champ-formulaire',[_c('etat-demande-select',{model:{value:(_vm.demandeModifiee.etatDemande),callback:function ($$v) {_vm.$set(_vm.demandeModifiee, "etatDemande", $$v)},expression:"demandeModifiee.etatDemande"}})],1),_c('champ-formulaire',{attrs:{"validation":{ requis: _vm.estTraitee, max: _vm.maximumCaracteresIdBilletJira }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return _c('v-text-field',{attrs:{"counter":_vm.maximumCaracteresIdBilletJira,"label":_vm.$t('demande.gestion.traitement.suivi.champs.billet-jira'),"error-messages":errors},model:{value:(_vm.demandeModifiee.idBilletJira),callback:function ($$v) {_vm.$set(_vm.demandeModifiee, "idBilletJira", $$v)},expression:"demandeModifiee.idBilletJira"}})}}])}),(_vm.estTypeCours)?_c('champ-formulaire',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return _c('select-autocomplete',{attrs:{"alphabetique":"","errors":errors,"label":_vm.$t('demande.formulaires.champs.sigle-du-cours.nom'),"items":_vm.matieresItems},model:{value:(_vm.demandeModifiee.sigleCours),callback:function ($$v) {_vm.$set(_vm.demandeModifiee, "sigleCours", $$v)},expression:"demandeModifiee.sigleCours"}})}}],null,false,2626941977)}):_vm._e(),(_vm.estTypeCours)?_c('champ-formulaire',{attrs:{"nom":_vm.$t('demande.gestion.traitement.suivi.champs.numero-cours'),"validation":{
        requis: _vm.estTraitee,
        max: _vm.maximumCaracteresNumeroCours,
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return _c('v-text-field',{attrs:{"counter":_vm.maximumCaracteresNumeroCours,"error-messages":errors,"label":_vm.$t('demande.gestion.traitement.suivi.champs.numero-cours')},model:{value:(_vm.demandeModifiee.numeroCours),callback:function ($$v) {_vm.$set(_vm.demandeModifiee, "numeroCours", $$v)},expression:"demandeModifiee.numeroCours"}})}}],null,false,3585597596)}):_vm._e(),_c('text-area',{attrs:{"validation":`max:${_vm.maximumCaracteresCommentairesGestionnaire}`,"maximum-caracteres":_vm.maximumCaracteresCommentairesGestionnaire,"label":_vm.$t(
          'demande.gestion.traitement.suivi.champs.commentaires-gestionnaire'
        )},model:{value:(_vm.demandeModifiee.commentaireGestionnaire),callback:function ($$v) {_vm.$set(_vm.demandeModifiee, "commentaireGestionnaire", $$v)},expression:"demandeModifiee.commentaireGestionnaire"}})],1),_c('div',{class:[
      _vm.estTailleDesktop ? 'flex-row' : 'flex-column',
      'd-flex',
      'ml-8',
      'mb-8',
    ]},[_c('v-btn',{ref:"boutonEnregistrer",staticClass:"primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.enregistrer'))+" ")]),_c('v-btn',{ref:"boutonAnnuler",class:[_vm.estTailleDesktop ? 'ml-4' : 'mt-6'],on:{"click":_vm.onClickAnnuler}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.annuler'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }