import { ServiceEtudes } from '@/commun/services/set/services/service-etudes';
import EtudesActions from '@/commun/store/modules/etudes/etudes-actions';
import EtudesGetters from '@/commun/store/modules/etudes/etudes-getters';
import EtudesMutations from '@/commun/store/modules/etudes/etudes-mutations';
import EtudesState from '@/commun/store/modules/etudes/etudes-state';
import RootState from '@/commun/store/root-state';
import Vue from 'vue';
import Vuex, { Module } from 'vuex';

Vue.use(Vuex);

const ModuleEtudes = (
  serviceEtudes: ServiceEtudes
): Module<EtudesState, RootState> => ({
  state: {
    detailsCours: undefined,
    resumeDetailsCours: [],
    resumeDetailsProgrammes: [],
    resumeDetailsCoursEnChargement: false,
    matieres: [],
    sessionCourante: undefined,
    sessions: [],
    unites: [],
  },
  getters: EtudesGetters,
  mutations: EtudesMutations,
  actions: EtudesActions(serviceEtudes),
});

export default ModuleEtudes;
