<template>
  <div>
    <champ-formulaire
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.attribut-de-stage.nom')"
    >
      <select-autocomplete
        v-model="demandeCourante.attributStage"
        slot-scope="{ errors }"
        class="champFormulaire"
        :errors="errors"
        :label="$t('demande.formulaires.champs.attribut-de-stage.nom')"
        :items="attributsStageItems"
      />
    </champ-formulaire>
    <champ-formulaire
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.temps-complet.nom')"
    >
      <radio-group-oui-non
        v-model="demandeCourante.tempsComplet"
        slot-scope="{ errors }"
        :errors="errors"
        :reinitialisable="radioReinitialisable"
        class="mt-0"
        :libelle="$t('demande.formulaires.champs.temps-complet.libelle')"
      >
        <tooltip
          slot="label"
          :messages="[$t('demande.formulaires.champs.temps-complet.infobulle')]"
        />
      </radio-group-oui-non>
    </champ-formulaire>
    <champ-formulaire
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.stage-reiterable.nom')"
    >
      <radio-group-oui-non
        v-model="demandeCourante.repetable"
        slot-scope="{ errors }"
        class="mt-0"
        :errors="errors"
        :reinitialisable="radioReinitialisable"
        :libelle="$t('demande.formulaires.champs.stage-reiterable.libelle')"
      >
        <tooltip
          slot="label"
          :messages="[
            $t('demande.formulaires.champs.stage-reiterable.infobulle'),
          ]"
        >
        </tooltip>
      </radio-group-oui-non>
    </champ-formulaire>

    <div v-if="afficherCredits">
      <stepper-credits
        v-model="demandeCourante.nombreCredits"
        :disabled="disableCredits"
      />
      <v-divider class="mb-6" />
      <charge-travail v-model="demandeCourante" />
    </div>
  </div>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { RadioGroupOuiNon } from '@/commun/components/champ-formulaire/radio-group-oui-non';
  import { SelectAutocomplete } from '@/commun/components/champ-formulaire/select-autocomplete';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import { StepperCredits } from '@/components/faculte/demandes/formulaires/champs/stepper-credits';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { SectionModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement';
  import ChargeTravail from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/charge-travail/charge-travail.vue';
  import { AttributStage } from '@/domain/models/attribut-stage';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ModeEnseignementStageFormulaire',
    components: {
      ChargeTravail,
      ChampFormulaire,
      RadioGroupOuiNon,
      SelectAutocomplete,
      StepperCredits,
      Tooltip,
    },
    mixins: [SectionFormulaireCours, SectionModeEnseignement],
    props: {
      radioReinitialisable: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      attributsStage: Object.values(AttributStage),
    }),
    computed: {
      attributsStageItems(): SelectAutocompleteItem[] {
        return (this.attributsStage as string[]).map(
          (attributStage: string) => ({
            texte: this.$i18n
              .t(
                `demande.formulaires.champs.attribut-de-stage.${attributStage}`
              )
              .toString(),
            valeur: attributStage,
          })
        );
      },
    },
  });
</script>
