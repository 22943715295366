export enum TypeUnite {
  FACULTE = 'faculte',
  DEPARTEMENT = 'departement',
}

export const CODE_AUCUNE_FACULTE_DESIGNEE = '00';
export const CODE_NON_UTILISE = '99';

interface Unite {
  codeUnitePedagogique: string;
  nomUnitePedagogique: string;
  codeUnitePedagogiqueMere: string;
  type: string;
  affichableDansListeDeValeurs: boolean;
}

export default Unite;
