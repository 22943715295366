import Faculte from '@/commun/services/set/model/faculte';
import Unite from '@/commun/services/set/model/unites';
import Demande, { TypesDemande } from '@/domain/models/demande';
import DemandeCours from '@/domain/models/demande-cours';
import { DemandeDocument } from '@/domain/models/demande-document';
import DemandeProgramme from '@/domain/models/demande-programme';
import Individu from '@/domain/models/individu';
import VueI18n from 'vue-i18n';
import { ConsultationDemande } from '.';
import demandeCoursAConsultation from './demande-cours-a-consultation';
import demandeProgrammeAConsultation from './demande-programme-a-consultation';

const estDemandeCours = (demande: Demande): boolean => {
  return [
    TypesDemande.CREATION_COURS,
    TypesDemande.DESACTIVATION_COURS,
    TypesDemande.REACTIVATION_COURS,
    TypesDemande.MODIFICATION_COURS,
  ].includes(demande.typeDemande as TypesDemande);
};

const demandeAConsultation = (
  i18n: VueI18n,
  demande: Demande,
  demandeDocuments: (DemandeDocument & { callback: () => void })[],
  individuModification: Individu,
  individuCreation: Individu,
  uniteResponsable?: Unite,
  faculte?: Faculte
): ConsultationDemande => {
  if (estDemandeCours(demande)) {
    return demandeCoursAConsultation(
      i18n,
      demande as DemandeCours,
      demandeDocuments,
      individuModification,
      individuCreation,
      uniteResponsable,
      faculte
    );
  } else {
    return demandeProgrammeAConsultation(
      i18n,
      demande as DemandeProgramme,
      demandeDocuments,
      individuModification,
      individuCreation
    );
  }
};

export default demandeAConsultation;
