import {
  CreditsContributoires,
  TypesCreditsContributoires,
} from '@/commun/services/set/model/credits-contributoires';
import { ModesEnseignement } from '@/domain/models/mode-enseignement';

class DetailsCours {
  sigleMatiere = '';
  codeSession = '';
  idCours = '';
  idDetailsCours = '';
  numeroCours = '';
  titreCours = '';
  modesEnseignement: string[] = [];
  nbCreditsContributoires: CreditsContributoires = {} as CreditsContributoires;
  nbCreditsFacturables: CreditsContributoires = {} as CreditsContributoires;

  constructor(
    sigleMatiere: string,
    codeSession: string,
    idCours: string,
    idDetailsCours: string,
    numeroCours: string,
    titreCours: string,
    modesEnseignement: ModesEnseignementDetailsCours[],
    creditsContributoires: CreditsContributoires
  ) {
    this.sigleMatiere = sigleMatiere;
    this.codeSession = codeSession;
    this.idCours = idCours;
    this.idDetailsCours = idDetailsCours;
    this.numeroCours = numeroCours;
    this.titreCours = titreCours;
    this.modesEnseignement = modesEnseignement;
    this.nbCreditsContributoires = creditsContributoires;
  }

  public estModifiable(): boolean {
    return this.modeEnseignement() != undefined;
  }

  public estChargeTravailFixe(): boolean {
    return this.nbCreditsFacturables.type === TypesCreditsContributoires.Fixe;
  }

  public modeEnseignement(): ModesEnseignement | undefined {
    return obtenirModeEnseignementDetailsCours(this);
  }
}

export enum ModesEnseignementDetailsCours {
  Regulier = 'R',
  Stage = 'S',
  StageResidenceMedecine = 'M',
  EducationContinue = 'U',
}

export const mapConversionModesEnseignement: {
  [key in ModesEnseignementDetailsCours]?: ModesEnseignement;
} = {
  [ModesEnseignementDetailsCours.Regulier]: ModesEnseignement.Regulier,
  [ModesEnseignementDetailsCours.Stage]: ModesEnseignement.Stage,
  [ModesEnseignementDetailsCours.StageResidenceMedecine]:
    ModesEnseignement.StageResidenceMedecine,
  [ModesEnseignementDetailsCours.EducationContinue]:
    ModesEnseignement.EducationContinue,
};

export function obtenirModeEnseignementDetailsCours(
  detailsCours: DetailsCours
): ModesEnseignement | undefined {
  let modeEnseignement;
  for (const mode of detailsCours.modesEnseignement) {
    modeEnseignement = mode;
    if (
      (Object.values(ModesEnseignementDetailsCours) as string[]).includes(mode)
    ) {
      break;
    }
  }
  return mapConversionModesEnseignement[
    modeEnseignement as ModesEnseignementDetailsCours
  ];
}

export default DetailsCours;
