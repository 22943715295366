import HttpWrapper from '@/commun/http/http-wrapper';
import { KyHttpWrapper } from '@/commun/http/ky-http-wrapper';
import ky from 'ky';

const clientHttp = (url: string): HttpWrapper => {
  const instanceKy: typeof ky = ky.create({
    prefixUrl: url,
    timeout: 30000,
  });
  return new KyHttpWrapper(instanceKy);
};

export default clientHttp;
