<template>
  <v-dialog
    v-model="openDialog"
    :transition="false"
    persistent
    hide-overlay
    fullscreen
  >
    <v-progress-circular
      style="
        left: 50%;
        margin-left: -16px;
        display: block;
        position: fixed;
        top: 50%;
        margin-top: -16px;
        z-index: 9999999999;
      "
      indeterminate
      color="primary"
    ></v-progress-circular>
  </v-dialog>
</template>

<script>
  import Vue from 'vue';

  export default Vue.extend({
    name: 'Spinner',
    data: () => ({
      openDialog: true,
    }),
  });
</script>
