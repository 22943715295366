import Vue from 'vue';

export default Vue.extend({
  name: 'SectionModeEnseignement',
  props: {
    afficherCredits: {
      type: Boolean,
      default: true,
    },
    disableCredits: {
      type: Boolean,
      default: false,
    },
  },
});
