<template>
  <div>
    <v-app>
      <v-main>
        <v-container
          class="ma-0 pa-0"
          style="min-height: 100vh"
          fluid
          fill-height
          no-gutters
        >
          <v-layout column>
            <banniere-ulaval />
            <v-container fill-height class="col-8 align-start">
              <v-flex full-width fill-height>
                <v-slide-x-transition leave-absolute>
                  <router-view />
                </v-slide-x-transition>
              </v-flex>
            </v-container>
          </v-layout>
        </v-container>
        <snackbar-notification-globale />
        <dialogue-erreur v-model="dialogueErreurOuvert" :erreur="erreur" />
      </v-main>
      <footer-gof class="mt-8" />
    </v-app>
  </div>
</template>

<script lang="ts">
  import { DialogueErreur } from '@/commun/components/dialogue-erreur';
  import { BanniereUlaval } from '@/components/cadre/banniere-ulaval/';
  import { FooterGof } from '@/components/cadre/footer/';
  import Vue from 'vue';
  import SnackbarNotificationGlobale from './commun/components/snackbars/snackbar-notification-globale.vue';
  export default Vue.extend({
    name: 'App',
    components: {
      FooterGof,
      BanniereUlaval,
      DialogueErreur,
      SnackbarNotificationGlobale,
    },
    data: () => ({
      dialogueErreurOuvert: false,
    }),
    computed: {
      erreur(): Error | undefined {
        return this.$store.getters.erreur;
      },
    },
    watch: {
      erreur(erreur: Error) {
        if (erreur) {
          this.dialogueErreurOuvert = true;
          return;
        }
        this.dialogueErreurOuvert = false;
      },
      dialogueErreurOuvert(valeur: boolean) {
        if (!valeur) {
          this.retirerErreur();
        }
      },
    },
    methods: {
      retirerErreur(): void {
        this.$store.dispatch('retirerErreur');
      },
    },
  });
</script>
