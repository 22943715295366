<template>
  <v-radio-group
    ref="radio-group-oui-non"
    :error-messages="errors"
    :value="value"
    @change="onValueChange"
  >
    <v-radio
      ref="radio-group-oui-non__oui"
      :label="$t('commun.components.radio-group-oui-non.oui')"
      style="width: fit-content"
      :value="true"
      type="radio"
    />
    <v-radio
      ref="radio-group-oui-non__non"
      :label="$t('commun.components.radio-group-oui-non.non')"
      style="width: fit-content"
      :value="false"
      type="radio"
    />
    <a v-if="reinitialisable" @click="onClickRetirerSelection">
      {{ $t('commun.components.radio-group.retirer-selection') }}
    </a>
    <div slot="label">
      <v-label>{{ libelle }}</v-label>
      <slot name="label" />
    </div>
  </v-radio-group>
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import { Validations } from '@/commun/validations/validations';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'RadioGroupOuiNon',
    mixins: [Validable],
    props: {
      libelle: {
        type: String,
        required: true,
      },
      value: {
        type: Boolean,
        default: undefined,
      },
      validation: {
        type: String,
        default: Validations.Requis,
      },
      reinitialisable: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      onClickRetirerSelection(): void {
        this.$emit('input', null);
      },
    },
  });
</script>
