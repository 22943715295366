<template>
  <div>
    <champ-formulaire validation="requis">
      <select-autocomplete
        v-model="resumeDetailProgrammeSelectionne"
        slot-scope="{ errors }"
        alphabetique
        :loading="rechercheEnCours"
        :items="itemsDetailsProgramme"
        :label="$t('demande.programme.formulaire.champs.programme').toString()"
        :errors="errors"
        @update:search-input="onUpdateRechercheProgrammes"
      />
    </champ-formulaire>
    <radio-majeure
      v-model="demandeCourante"
      :est-radio-desactive="estEnModification"
    />

    <champ-formulaire
      v-if="estSectionMajeureExistanteAffichee"
      validation="requis"
    >
      <select-autocomplete
        v-model="resumeDetailMajeurSelectionne"
        slot-scope="{ errors }"
        alphabetique
        :items="itemsDetailsMajeurProgramme"
        :label="$t('demande.programme.formulaire.champs.majeure').toString()"
        :errors="errors"
      />
    </champ-formulaire>
    <champ-formulaire
      v-else
      :validation="`requis|max:${maximumCaracteresMajeur}`"
    >
      <v-text-field
        v-model="demandeCourante.titreMajeure"
        slot-scope="{ errors }"
        :error-messages="errors"
        :label="
          $t(
            'demande.programme.formulaire.champs.titre-nouvelle-majeure'
          ).toString()
        "
        :counter="maximumCaracteresMajeur"
      />
    </champ-formulaire>
    <champ-formulaire>
      <v-text-field
        v-model="faculte"
        slot-scope="{ errors }"
        :error-messages="errors"
        readonly
        :label="$t('demande.programme.formulaire.champs.faculte').toString()"
      />
    </champ-formulaire>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import SelectAutocomplete from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete.vue';
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import { IdentiteRoles } from '@/domain/models/identite';
  import Faculte from '@/commun/services/set/model/faculte';
  import ResumeDetailsProgrammes from '@/commun/services/set/model/resume-details-programmes';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import { SectionFormulaireProgramme } from '@/components/faculte/demandes/formulaires/programme/section-formulaire-programme';
  import DemandeProgramme, {
    DemandeProgrammeTypeModification,
  } from '@/domain/models/demande-programme';
  import RadioMajeure from '@/components/faculte/demandes/formulaires/programme/section-identification-programme-majeure/radio-majeure.vue';
  const NombreCaracteresCodeProgramme = [5, 6];
  export default Vue.extend({
    name: 'SectionIdentificationProgrammeMajeure',
    components: {
      SelectAutocomplete,
      ChampFormulaire,
      RadioMajeure,
    },
    mixins: [SectionFormulaireProgramme],
    props: {
      estEnModification: {
        type: Boolean,
        default: false,
      },
      roles: {
        type: Array as PropType<Array<IdentiteRoles>>,
        required: true,
      },
      facultes: {
        type: Array as PropType<Array<Faculte>>,
        required: true,
      },
      facultesRattachement: {
        type: Array as PropType<Array<string>>,
        required: true,
      },
    },
    data: () => ({
      maximumCaracteresMajeur: 120,
      demandeCourante: {} as DemandeProgramme,
      rechercheEnCours: false,
      faculte: '',
      demandeInitiale: '',
      resumeDetailMajeurSelectionne: undefined as
        | ResumeDetailsProgrammes
        | undefined,
      resumeDetailProgrammeSelectionne: undefined as
        | ResumeDetailsProgrammes
        | undefined,
    }),
    computed: {
      itemsDetailsProgramme(): SelectAutocompleteItem[] {
        return this.rechercheEnCours
          ? []
          : this.resumesDetailsProgrammes
              .map(
                (resumeDetailsProgrammes: ResumeDetailsProgrammes) =>
                  ({
                    texte: `${resumeDetailsProgrammes.codeProgramme} ${resumeDetailsProgrammes.descriptionProgrammeGenerique}`,
                    valeur: resumeDetailsProgrammes,
                  } as SelectAutocompleteItem)
              )
              .filter(
                (valeur, index, tab) =>
                  tab.findIndex((t) => t.texte === valeur.texte) === index
              );
      },
      itemsDetailsMajeurProgramme(): SelectAutocompleteItem[] {
        return this.resumesDetailsProgrammes
          .map(
            (resumeDetailsProgrammes: ResumeDetailsProgrammes) =>
              ({
                texte: `${resumeDetailsProgrammes.codeMajeure} - ${resumeDetailsProgrammes.descriptionMajeure}`,
                valeur: resumeDetailsProgrammes,
              } as SelectAutocompleteItem)
          )
          .filter(
            (item) =>
              (item.valeur as ResumeDetailsProgrammes).codeProgramme ===
              this.resumeDetailProgrammeSelectionne?.codeProgramme
          );
      },
      estSectionMajeureExistanteAffichee(): boolean {
        return (
          this.demandeCourante.typeModification ===
          DemandeProgrammeTypeModification.MAJEURE_EXISTANTE
        );
      },
      resumesDetailsProgrammes(): ResumeDetailsProgrammes[] {
        return this.$store.getters.resumeDetailsProgrammes;
      },
    },
    watch: {
      resumeDetailProgrammeSelectionne: {
        immediate: true,
        handler(valeur: ResumeDetailsProgrammes) {
          if (!valeur) {
            this.faculte = '';
            return;
          }
          this.demandeCourante.codeProgramme = valeur.codeProgramme;
          this.demandeCourante.codeFaculte = valeur.codeFaculteResponsable;
          this.faculte = this.faculteProgramme(
            valeur.codeFaculteResponsable?.toString()
          );
          this.ajouterjsonCibleDemande(valeur);
        },
      },
      resumeDetailMajeurSelectionne: {
        immediate: true,
        handler(valeur: ResumeDetailsProgrammes) {
          if (!valeur) {
            return;
          }
          this.demandeCourante.codeMajeure = valeur.codeMajeure;
          this.ajouterjsonCibleDemande(valeur);
        },
      },
    },
    async created() {
      if (!this.estEnModification) {
        await this.$store.commit('effacerResumeDetailsProgrammes');
      } else {
        this.demandeInitiale = JSON.stringify(this.demandeCourante);
        await this.onUpdateRechercheProgrammes(
          this.demandeCourante.codeProgramme
        );
        this.resumeDetailMajeurSelectionne = this.resumesDetailsProgrammes.find(
          (item) => item.codeMajeure === this.demandeCourante.codeMajeure
        );
      }
    },
    methods: {
      ajouterjsonCibleDemande(valeur: ResumeDetailsProgrammes): void {
        delete this.demandeCourante.jsonCibleDemande;
        if (this.demandeInitiale !== JSON.stringify(this.demandeCourante)) {
          this.demandeCourante.jsonCibleDemande = JSON.stringify(valeur);
        }
      },

      faculteProgramme(codeFaculte: string): string {
        const faculte = (this.facultes as Faculte[]).find(
          (faculte: Faculte) =>
            faculte.affichableDansListeDeValeurs &&
            faculte.codeUnitePedagogique === codeFaculte
        );
        return faculte ? faculte.obtenirNomAffichage() : codeFaculte;
      },

      async onUpdateRechercheProgrammes(payload: string) {
        if (payload && NombreCaracteresCodeProgramme.includes(payload.length)) {
          await this.rechercherDetailsProgrammes(payload);
        }
      },
      async rechercherDetailsProgrammes(codeProgramme: string): Promise<any> {
        if (this.rechercheEnCours) {
          return;
        }
        this.rechercheEnCours = true;
        await this.$store.commit('effacerResumeDetailsProgrammes');
        await this.$store.dispatch('rechercherDetailsProgrammes', {
          codesProgramme: codeProgramme.toUpperCase(),
          programmesAvecStructure: true,
          offertPourAdmission: true,
          derniereVersion: true,
        });
        if (!this.roles.includes(IdentiteRoles.GESTIONNAIRE)) {
          let resumeDetailsProgrammes: ResumeDetailsProgrammes[] =
            this.$store.getters.resumeDetailsProgrammes;
          resumeDetailsProgrammes = resumeDetailsProgrammes.filter(
            (resume: ResumeDetailsProgrammes) =>
              this.facultesRattachement.includes(
                resume.codeFaculteResponsable.toString()
              )
          );
          await this.$store.commit(
            'setResumeDetailsProgrammes',
            resumeDetailsProgrammes
          );
        }
        this.rechercheEnCours = false;
      },
    },
  });
</script>

<style scoped></style>
