<template>
  <v-snackbar v-model="afficher" top :color="typeMessage">
    {{ message }}
  </v-snackbar>
</template>

<script lang="ts">
  import {
    RequeteSnackbar,
    TypesMessagesSnackbar,
  } from '@/commun/components/snackbars/requete-snackbar';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'SnackbarNotificationGlobale',
    data() {
      return {
        afficher: false,
        message: '',
        typeMessage: TypesMessagesSnackbar.Information,
      };
    },
    computed: {
      messageNotification() {
        return this.$store.state.GestionOffreFormation
          .requeteNotificationGlobale;
      },
    },
    watch: {
      messageNotification(nouvelleRequete?: RequeteSnackbar) {
        if (nouvelleRequete) {
          this.message = nouvelleRequete.message;
          this.typeMessage = nouvelleRequete.typeMessage;
          this.afficher = true;
        }
      },
    },
  });
</script>
