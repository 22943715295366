import App from '@/App.vue';
import ErreurReseau from '@/commun/http/erreur-reseau';
import HttpWrapper from '@/commun/http/http-wrapper';
import { enregistrerHookAuthentification } from '@/commun/http/ky-auth-hook';
import { enregistrerHookGestionErreurs } from '@/commun/http/ky-gestion-erreurs';
import RootState from '@/commun/store/root-state';
import { ajouterValidationsCommunes } from '@/commun/validations/validations-setup';
import i18n from '@/plugins/i18n';
import { ajouterValidationsGOF } from '@/plugins/validations/gof-validations-setup';
import vuetify from '@/plugins/vuetify';
import { routerFactory } from '@/router';
import Routes from '@/router/routes';
import { creerContainerGestionOffreFormation } from '@/setup/gestion-offre-formation-container';
import {
  chargerVariablesEnvironnement,
  ENVIRONNEMENTS_VUE_CLI,
  VariablesEnvironnementGOF,
} from '@/setup/variables-environnement-gof';
import StoreGestionOffreFormation from '@/store/store-gestion-offre-formation';
import { decorate, injectable } from 'inversify';
import 'reflect-metadata';
import Vue from 'vue';
import { Store } from 'vuex';
import creerClientHttp from './services/client-http';
import { AuthModule } from './commun/services/auth/AuthModule';

Vue.config.productionTip = false;

const environnement = process.env
  .NODE_ENV as keyof typeof ENVIRONNEMENTS_VUE_CLI;

chargerVariablesEnvironnement(environnement).then(
  (variables: VariablesEnvironnementGOF) => {
    decorate(injectable(), Store);

    const clientHttpGOF: HttpWrapper = creerClientHttp(
      variables.parametresGestionOffreFormation.url
    );
    const clientServiceEtudes: HttpWrapper = creerClientHttp(
      variables.parametresServiceEtudes.url
    );

    const container = creerContainerGestionOffreFormation(
      clientHttpGOF,
      clientServiceEtudes
    );

    const store: Store<RootState> =
      container.resolve<StoreGestionOffreFormation>(StoreGestionOffreFormation);

    const authModule: AuthModule = new AuthModule(variables);
    const router = routerFactory(store, authModule);

    const errorHandler = async (erreur: Error) => {
      if (erreur instanceof ErreurReseau) {
        await router.push({ name: Routes.ErreurReseau });
        return;
      }
      await store.dispatch('notifierErreur', erreur);
      // eslint-disable-next-line no-console
      console.error(erreur);
    };

    const hookAuthentification = enregistrerHookAuthentification(authModule);
    const hookGestionErreurs = enregistrerHookGestionErreurs();
    clientHttpGOF.extend(hookAuthentification);
    clientHttpGOF.extend(hookGestionErreurs);
    clientServiceEtudes.extend(hookAuthentification);
    clientServiceEtudes.extend(hookGestionErreurs);

    ajouterValidationsCommunes();
    ajouterValidationsGOF();

    Vue.config.errorHandler = errorHandler;
    return new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: (h) => h(App),
    }).$mount('#app');
  }
);
