<template>
  <champ-formulaire
    :validation="validation"
    :nom="$t('demande.formulaires.champs.mode-enseignement.nom')"
  >
    <select-autocomplete
      slot-scope="{ errors }"
      v-bind="$attrs"
      :value="value"
      :label="$t('demande.formulaires.champs.mode-enseignement.nom')"
      :nom="nom"
      :items="modeEnseignementItems"
      :errors="errors"
      @input="onValueChange"
    />
  </champ-formulaire>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import SelectAutocomplete from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete.vue';
  import { ModesEnseignement } from '@/domain/models/mode-enseignement';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ModeEnseignementSelect',
    components: { ChampFormulaire, SelectAutocomplete },
    mixins: [Validable],
    inheritAttrs: false,
    props: {
      value: {
        type: String,
        default: '',
      },
      nom: {
        type: String,
        default() {
          return this.$t('demande.formulaires.champs.mode-enseignement.nom');
        },
      },
    },
    data: () => ({
      modesEnseignement: Object.values(ModesEnseignement),
    }),
    computed: {
      modeEnseignementItems(): SelectAutocompleteItem[] {
        return (this.modesEnseignement as string[]).map(
          (modeEnseignement: string) => ({
            texte: this.obtenirTexteSelection(modeEnseignement),
            valeur: modeEnseignement,
          })
        );
      },
    },
    methods: {
      obtenirTexteSelection(modeEnseignement: string): string {
        return this.$t(
          `demande.formulaires.champs.mode-enseignement.valeurs.${modeEnseignement}`
        ).toString();
      },
    },
  });
</script>
