<template>
  <select-autocomplete
    ref="serie-cours-select__autoselect"
    :value="value"
    :label="$t('demande.formulaires.champs.serie-du-cours.nom')"
    :items="serieCours"
    :errors="errors"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onValueChange"
  />
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import { SelectAutocomplete } from '@/commun/components/champ-formulaire/select-autocomplete';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import { SerieCours } from '@/domain/models/serie-cours';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'SerieCoursSelect',
    components: {
      SelectAutocomplete,
    },
    mixins: [Validable],
    props: {
      nom: {
        type: String,
        default() {
          return this.$t('demande.formulaires.champs.serie-du-cours.nom');
        },
      },
      value: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      serieCours: SerieCours.map(
        (serie: string) =>
          ({
            texte: serie,
            valeur: serie,
          } as SelectAutocompleteItem)
      ),
    }),
  });
</script>
