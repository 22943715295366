<template>
  <tr
    v-if="valeurFormatee && ligne.valeur !== undefined"
    class="consultation-demande-tableau-ligne"
  >
    <td
      class="consultation-demande-tableau-ligne__cellule__nom font-weight-medium"
    >
      <div v-if="!ligne.type || ligne.type === 'text'">
        {{ ligne.nom }}
      </div>
      <a
        v-else
        class="consultation-demande-tableau-ligne__lien"
        href="#"
        @click.prevent="ligne.lienCallback"
        >{{ ligne.nom }}</a
      >
    </td>
    <td :style="[sectionExpension ? {} : { 'padding-left': '26px' }]">
      {{ valeurFormatee }}
    </td>
  </tr>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { ConsultationDemandeLigne } from '..';

  export default Vue.extend({
    name: 'ConsultationDemandeTableauLigne',
    props: {
      ligne: {
        type: Object as PropType<ConsultationDemandeLigne>,
        required: true,
      },
      sectionExpension: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      valeurFormatee(): string {
        if (typeof this.ligne.valeur === 'boolean') {
          return this.ligne.valeur
            ? this.$t('commun.oui').toString()
            : this.$t('commun.non').toString();
        }

        return (this.ligne.valeur as string)?.toString();
      },
    },
  });
</script>

<style lang="scss">
  @import '~@/commun/styles/variables-layout.scss';

  .consultation-demande-tableau-ligne {
    &__cellule__nom {
      width: 30%;
    }

    &__nom-valeur {
      padding: $m-padding;
    }
  }

  .consultation-demande-tableau-ligne:hover {
    background-color: transparent !important;
  }
</style>
