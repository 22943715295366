<template>
  <div>
    <div class="mb-4" style="font-size: 18px">{{ label }}</div>
    <div v-for="item in items" :key="item.texte" class="d-flex flex-row">
      <v-checkbox
        v-model="item.valeur"
        class="mt-0 pt-0"
        :label="item.texte"
        @change="onChangeValeurCheckbox(item)"
      />
      <tooltip
        v-if="!!item.infobulle"
        valeur-marge-left="2"
        :messages="[item.infobulle]"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import CheckboxGroupItem from '@/commun/components/champ-formulaire/checkbox-group/checkbox-group-item';
  import Vue, { PropType } from 'vue';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';

  export default Vue.extend({
    name: 'CheckboxGroup',
    components: { Tooltip },
    props: {
      label: {
        type: String,
        default: '',
      },
      items: {
        type: Array as PropType<Array<CheckboxGroupItem>>,
        default: new Array(0),
      },
    },
    methods: {
      onChangeValeurCheckbox(payload: CheckboxGroupItem): void {
        this.$emit('changeValeurCheckbox', payload);
      },
    },
  });
</script>
