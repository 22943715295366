<template>
  <formulaire ref="formulaire" @soumettre="onSoumettre">
    <section-formulaire :encadre="false" :elevation="0">
      <champ-formulaire>
        <etat-demande-select v-model="demandeModifiee.etatDemande" />
      </champ-formulaire>
      <champ-formulaire
        :validation="{ requis: estTraitee, max: maximumCaracteresIdBilletJira }"
      >
        <v-text-field
          v-model="demandeModifiee.idBilletJira"
          slot-scope="{ errors }"
          :counter="maximumCaracteresIdBilletJira"
          :label="$t('demande.gestion.traitement.suivi.champs.billet-jira')"
          :error-messages="errors"
        />
      </champ-formulaire>
      <champ-formulaire v-if="estTypeCours">
        <select-autocomplete
          v-model="demandeModifiee.sigleCours"
          slot-scope="{ errors }"
          alphabetique
          :errors="errors"
          :label="$t('demande.formulaires.champs.sigle-du-cours.nom')"
          :items="matieresItems"
        />
      </champ-formulaire>
      <champ-formulaire
        v-if="estTypeCours"
        :nom="$t('demande.gestion.traitement.suivi.champs.numero-cours')"
        :validation="{
          requis: estTraitee,
          max: maximumCaracteresNumeroCours,
        }"
      >
        <v-text-field
          v-model="demandeModifiee.numeroCours"
          slot-scope="{ errors }"
          :counter="maximumCaracteresNumeroCours"
          :error-messages="errors"
          :label="$t('demande.gestion.traitement.suivi.champs.numero-cours')"
        />
      </champ-formulaire>
      <text-area
        v-model="demandeModifiee.commentaireGestionnaire"
        :validation="`max:${maximumCaracteresCommentairesGestionnaire}`"
        :maximum-caracteres="maximumCaracteresCommentairesGestionnaire"
        :label="
          $t(
            'demande.gestion.traitement.suivi.champs.commentaires-gestionnaire'
          )
        "
      />
    </section-formulaire>
    <div
      :class="[
        estTailleDesktop ? 'flex-row' : 'flex-column',
        'd-flex',
        'ml-8',
        'mb-8',
      ]"
    >
      <v-btn ref="boutonEnregistrer" class="primary" type="submit">
        {{ $t('commun.actions.enregistrer') }}
      </v-btn>
      <v-btn
        ref="boutonAnnuler"
        :class="[estTailleDesktop ? 'ml-4' : 'mt-6']"
        @click="onClickAnnuler"
      >
        {{ $t('commun.actions.annuler') }}
      </v-btn>
    </div>
  </formulaire>
</template>

<script lang="ts">
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import SectionFormulaire from '@/commun/components/champ-formulaire/section-formulaire/section-formulaire.vue';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import SelectAutocomplete from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete.vue';
  import TextArea from '@/commun/components/champ-formulaire/text-area/text-area.vue';
  import { Formulaire } from '@/commun/components/formulaire';
  import { TypesMessagesSnackbar } from '@/commun/components/snackbars/requete-snackbar';
  import Matiere from '@/commun/services/set/model/matiere';
  import { EtatDemandeSelect } from '@/components/gestion/traitement/formulaires/champs/etat-demande-select';
  import { EtatsDemande, TypesDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import Routes from '@/router/routes';
  import { obtenirDifferencesDemande } from '@/utils/differences-demande';
  import Operation from '@/views/faculte/demande/cours/operations/operation';
  import { PropType } from 'vue';

  export default Operation.extend({
    name: 'FormulaireSuiviDemande',
    components: {
      EtatDemandeSelect,
      Formulaire,
      ChampFormulaire,
      TextArea,
      SelectAutocomplete,
      SectionFormulaire,
    },
    props: {
      demande: {
        type: Object as PropType<DemandeCours>,
        required: true,
      },
      matieres: {
        type: Array as PropType<Array<Matiere>>,
        required: true,
      },
    },
    data: () => ({
      maximumCaracteresIdBilletJira: 20,
      maximumCaracteresNumeroCours: 4,
      maximumCaracteresCommentairesGestionnaire: 3500,
      demandeModifiee: {} as DemandeCours,
    }),
    computed: {
      matieresItems(): SelectAutocompleteItem[] {
        return (this.matieres as Matiere[]).map((matiere: Matiere) => ({
          texte: `${matiere.sigleMatiere} - ${matiere.description}`,
          valeur: matiere.sigleMatiere,
        }));
      },
      estTailleDesktop(): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
      estTraitee(): boolean {
        return this.demandeModifiee.etatDemande === EtatsDemande.COMPLETEE;
      },
      estTypeCours(): boolean {
        return (
          this.demandeModifiee.typeDemande !==
          TypesDemande.MODIFICATION_PROGRAMME
        );
      },
    },
    created() {
      this.demandeModifiee = { ...this.demande };
    },
    methods: {
      onClickAnnuler(): void {
        this.$router.push({ name: Routes.GestionDemandes });
      },
      async naviguerListeDemandes(): Promise<void> {
        await this.$router.push({ name: Routes.GestionDemandes });
      },
      async onSoumettre(): Promise<void> {
        const proprietesModifiees = obtenirDifferencesDemande<DemandeCours>(
          this.demande,
          this.demandeModifiee
        );
        if (Object.keys(proprietesModifiees).length === 0) {
          await this.notifierUtilisateur(
            this.$t('demande.edition.aucun-changement').toString(),
            TypesMessagesSnackbar.Information
          );
          await this.naviguerListeDemandes();
          return;
        }
        await this.soumettre(proprietesModifiees);
      },
      async soumettre(demande: DemandeCours): Promise<void> {
        await this.$store.dispatch('modifierDemande', {
          idDemande: this.demande.id,
          demande,
        });
        await this.notifierUtilisateur(
          this.$t('demande.edition.succes').toString(),
          TypesMessagesSnackbar.Succes
        );
        await this.naviguerListeDemandes();
      },
    },
  });
</script>
