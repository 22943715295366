import Unite from '@/commun/services/set/model/unites';

class Faculte implements Unite {
  codeUnitePedagogique: string;
  nomUnitePedagogique: string;
  codeUnitePedagogiqueMere: string;
  affichableDansListeDeValeurs: boolean;
  type: string;
  nomLongFaculte?: string;

  constructor(
    codeUnitePedagogique: string,
    nomUnitePedagogique: string,
    codeUnitePedagogiqueMere: string,
    affichableDansListeDeValeurs: boolean,
    type: string,
    nomLongFaculte?: string
  ) {
    this.codeUnitePedagogique = codeUnitePedagogique;
    this.nomUnitePedagogique = nomUnitePedagogique;
    this.codeUnitePedagogiqueMere = codeUnitePedagogiqueMere;
    this.affichableDansListeDeValeurs = affichableDansListeDeValeurs;
    this.type = type;
    this.nomLongFaculte = nomLongFaculte;
  }

  obtenirNomAffichage(): string {
    return `${this.codeUnitePedagogique} - ${
      this.nomLongFaculte ? this.nomLongFaculte : this.nomUnitePedagogique
    }`;
  }
}

export default Faculte;
