<template>
  <champ-formulaire :validation="validation" :nom="nom">
    <v-radio-group
      ref="radio-group"
      slot-scope="{ errors }"
      :error-messages="errors"
      :value="value"
      @change="onValueChange"
    >
      <slot />
      <div slot="label">
        <v-label>{{ libelle }}</v-label>
        <slot name="label" />
      </div>
      <a
        v-if="reinitialisable"
        ref="lienRetirerSelection"
        @click="onClickRetirerSelection"
      >
        {{ $t('commun.components.radio-group.retirer-selection') }}
      </a>
    </v-radio-group>
  </champ-formulaire>
</template>

<script lang="ts">
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'RadioGroup',
    components: { ChampFormulaire },
    mixins: [Validable],
    props: {
      value: {
        type: [String, Number, Object],
        default: undefined,
      },
      libelle: {
        type: String,
        default: '',
      },
      nom: {
        type: String,
        default: '',
      },
      reinitialisable: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onClickRetirerSelection(): void {
        this.$emit('input', null);
      },
    },
  });
</script>
