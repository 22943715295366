import HttpClient from '@/commun/http/http-wrapper';
import Matiere from '@/commun/services/set/model/matiere';
import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';
import ResumeDetailsProgrammes from '@/commun/services/set/model/resume-details-programmes';
import { matieresMock } from '../mocks/matiere.mock';
import { resumeDetailsCoursMock } from '../mocks/resume-details-cours.mock';
import { resumeDetailsProgrammeMock } from '../mocks/resume-details-programme.mock';

export interface ServiceRepertoire {
  obtenirMatieres(): Promise<Matiere[]>;
  obtenirDetailsCours(idDetailsCours: string): Promise<any>;
  rechercherDetailsCours(
    parametresRechercheDetailsCours: Record<string, string>
  ): Promise<ResumeDetailsCours[]>;
  rechercherDetailsProgrammes(
    parametresRechercheDetailsProgrammes: Record<string, string>
  ): Promise<ResumeDetailsProgrammes[]>;
}

export default class ServiceRepertoireHTTP implements ServiceRepertoire {
  public static PATH_REPERTOIRE_COURS = 'repertoire/cours';
  public static PATH_REPERTOIRE_PROGRAMME = 'repertoire/programmes/versions';

  constructor(private readonly httpClient: HttpClient) {}

  obtenirMatieres(): Promise<Matiere[]> {
    return this.httpClient.get<Matiere[]>(
      `${ServiceRepertoireHTTP.PATH_REPERTOIRE_COURS}/matieres/`
    );
  }

  rechercherDetailsCours(
    parametresRechercheDetailsCours: Record<string, string>
  ): Promise<ResumeDetailsCours[]> {
    return this.httpClient.get(
      `${ServiceRepertoireHTTP.PATH_REPERTOIRE_COURS}/details/`,
      {
        searchParams: parametresRechercheDetailsCours,
      }
    );
  }

  rechercherDetailsProgrammes(
    parametresRechercheDetailsProgrammes: Record<string, string>
  ): Promise<ResumeDetailsProgrammes[]> {
    return this.httpClient.get(
      ServiceRepertoireHTTP.PATH_REPERTOIRE_PROGRAMME,
      {
        searchParams: parametresRechercheDetailsProgrammes,
      }
    );
  }

  obtenirDetailsCours(idDetailsCours: string): Promise<any> {
    return this.httpClient.get(
      `${ServiceRepertoireHTTP.PATH_REPERTOIRE_COURS}/details/${idDetailsCours}`
    );
  }
}

export class ServiceRepertoireMock implements ServiceRepertoire {
  private matieresMocks = matieresMock().slice();
  private resumeDetailsCoursMocks = resumeDetailsCoursMock().slice();
  private resumeDetailsProgrammesMocks = resumeDetailsProgrammeMock().slice();

  public obtenirMatieres(): Promise<Matiere[]> {
    return Promise.resolve(this.matieresMocks);
  }

  public rechercherDetailsCours(): Promise<ResumeDetailsCours[]> {
    return Promise.resolve(this.resumeDetailsCoursMocks);
  }

  public obtenirDetailsCours(idDetailsCours: string): Promise<any> {
    return Promise.resolve(
      this.resumeDetailsCoursMocks.find(
        (rdc) => rdc.idDetailsCours === idDetailsCours
      )
    );
  }

  public rechercherDetailsProgrammes(): Promise<ResumeDetailsProgrammes[]> {
    return Promise.resolve(this.resumeDetailsProgrammesMocks);
  }
}
