import { RequeteSnackbar } from '@/commun/components/snackbars/requete-snackbar';
import { ExecutionMutationPayload } from '@/commun/store/execution-mutation';
import RootState from '@/commun/store/root-state';
import Demande, { EtatsDemande } from '@/domain/models/demande';
import { DemandeDocument } from '@/domain/models/demande-document';
import Identite from '@/domain/models/identite';
import Individu from '@/domain/models/individu';
import { ServiceDemandes } from '@/services/service-demandes';
import { ActionContext } from 'vuex';
import GestionOffreFormationState from '../gestion-offre-formation-state';

const GestionOffreFormationActions = (serviceDemandes: ServiceDemandes) => ({
  async creerDemande(
    { commit }: { commit: ExecutionMutationPayload },
    demande: Demande
  ): Promise<Demande> {
    const reponseDemande = await serviceDemandes.creerDemande(demande);
    commit('ajouterDemande', reponseDemande);
    demande.id = reponseDemande.id;
    return reponseDemande;
  },
  modifierDemande(
    { commit }: { commit: ExecutionMutationPayload },
    payload: { idDemande: string; demande: Demande }
  ): Promise<void> {
    return serviceDemandes
      .modifierDemande(payload.idDemande, payload.demande)
      .then((reponse: Demande) => {
        commit('modifierDemande', reponse);
      });
  },
  obtenirDemande(
    { commit }: { commit: ExecutionMutationPayload },
    idDemande: string
  ) {
    return serviceDemandes
      .obtenirDemande(idDemande)
      .then((reponse: Demande) => {
        commit('setDemandeCourante', reponse);
      });
  },
  obtenirIndividu(
    { commit }: { commit: ExecutionMutationPayload },
    idIndividu: string
  ) {
    return serviceDemandes
      .obtenirIndividu(idIndividu)
      .then((reponse: Individu) => {
        commit('ajouterIndividu', reponse);
      });
  },
  obtenirIdentite({ commit }: { commit: ExecutionMutationPayload }) {
    return serviceDemandes.obtenirIdentite().then((reponse: Identite) => {
      commit('setIdentite', reponse);
    });
  },
  obtenirDemandes({ commit }: { commit: ExecutionMutationPayload }) {
    commit('setEstChargementDemandesEnCours', true);
    return serviceDemandes
      .obtenirDemandes()
      .then((reponse: Demande[]) => {
        commit('setDemandes', reponse);
      })
      .finally(() => {
        commit('setEstChargementDemandesEnCours', false);
      });
  },
  supprimerDemande(
    { commit }: { commit: ExecutionMutationPayload },
    idDemande: string
  ) {
    return serviceDemandes.supprimerDemande(idDemande).then(() => {
      commit('retirerDemande', idDemande);
    });
  },
  deposerDemande(
    { commit }: { commit: ExecutionMutationPayload },
    idDemande: string
  ) {
    return serviceDemandes.deposerDemande(idDemande).then(() => {
      commit('changerEtatDemande', {
        etat: EtatsDemande.DEPOSEE,
        id: idDemande,
      });
    });
  },
  notifierErreur(
    { commit }: { commit: ExecutionMutationPayload },
    erreur: Error
  ) {
    commit('modifierErreur', erreur);
  },
  retirerErreur({ commit }: { commit: (nom: string) => void }) {
    commit('retirerErreur');
  },
  notifierUtilisateur(
    { commit }: { commit: ExecutionMutationPayload },
    requeteNotificationGlobale: RequeteSnackbar
  ) {
    commit('modifierMessageNotification', requeteNotificationGlobale);
  },
  modifierOrdreTriTableau(
    { commit }: { commit: ExecutionMutationPayload },
    trierTableauDescendant: boolean
  ) {
    commit('setTrierTableauDescendant', trierTableauDescendant);
  },
  modifierColonneTriTableau(
    { commit }: { commit: ExecutionMutationPayload },
    colonneATrier: string
  ) {
    commit('setColonneATrier', colonneATrier);
  },
  modifierFiltreRechercheTableau(
    { commit }: { commit: ExecutionMutationPayload },
    filtreRecherche: string
  ) {
    commit('setFiltreRecherche', filtreRecherche);
  },
  modifierNombreDemandesParPageTableau(
    { commit }: { commit: ExecutionMutationPayload },
    demandesParPage: number
  ) {
    commit('setNombreDemandesParPage', demandesParPage);
  },
  async obtenirDemandeCouranteDocuments(
    { commit }: { commit: ExecutionMutationPayload },
    idDemande: string
  ): Promise<void> {
    const demandeDocuments =
      await serviceDemandes.obtenirDemandeDocuments(idDemande);

    commit('demandeCouranteDocuments', demandeDocuments);
  },
  async creerDemandeDocuments(
    context: ActionContext<GestionOffreFormationState, RootState>,
    { idDemande, fichiers }: { idDemande: string; fichiers: File[] }
  ): Promise<void> {
    const demandeDocuments: DemandeDocument[] = fichiers.map((f: File) => ({
      idDemande,
      nomFichier: f.name,
    }));

    await Promise.all(
      demandeDocuments.map(async (dd) => {
        const fichier = fichiers.find((f) => f.name === dd.nomFichier);

        if (!fichier) return;

        const demandeDocument = await serviceDemandes.creerDemandeDocument(dd);

        const formData = new FormData();

        formData.append('file', fichier);

        try {
          await context.dispatch('televersementDemandeDocumentFichier', {
            idDemande: demandeDocument.idDemande,
            idDocument: demandeDocument.idDocument,
            formData,
          });
        } catch (exception) {
          if (demandeDocument.idDocument) {
            await serviceDemandes.supprimerDemandeDocument(
              demandeDocument.idDemande,
              demandeDocument.idDocument
            );
          }
          throw exception;
        }
      })
    );
  },
  async supprimerDemandeDocuments(
    { commit }: { commit: ExecutionMutationPayload },
    demandeDocuments: DemandeDocument[]
  ): Promise<void> {
    await Promise.all(
      demandeDocuments.map(async (dd) => {
        await serviceDemandes.supprimerDemandeDocument(
          dd.idDemande ?? '',
          dd.idDocument ?? ''
        );
        commit('supprimerDemandeCouranteDocument', dd.idDocument);
      })
    );
  },
  async televersementDemandeDocumentFichier(
    { commit }: { commit: ExecutionMutationPayload },
    {
      idDemande,
      idDocument,
      formData,
    }: { idDemande: string; idDocument: string; formData: FormData }
  ): Promise<void> {
    const demandeDocument =
      await serviceDemandes.televerserDemandeDocumentFichier(
        idDemande,
        idDocument,
        formData
      );

    commit('ajouterDemandeCouranteDocument', demandeDocument);
  },
  async telechargerDemandeDocumentFichier(
    _: ActionContext<GestionOffreFormationState, RootState>,
    { idDemande, idDocument }: { idDemande: string; idDocument: string }
  ): Promise<Blob> {
    const fichier = await serviceDemandes.telechargerDemandeDocumentFichier(
      idDemande,
      idDocument
    );

    return fichier;
  },
});

export default GestionOffreFormationActions;
