export interface Navigateur {
  nom?: string;
  version?: string;
}

export interface SystemeExploitation {
  nom?: string;
  version?: string;
}

export interface Engin {
  nom?: string;
}

export interface Plateforme {
  type?: string;
}

export class InformationsNavigateur {
  private navigateur: Navigateur;
  private systemeExploitation: SystemeExploitation;
  private engin: Engin;
  private plateforme: Plateforme;

  constructor(
    navigateur: Navigateur,
    systemeExploitation: SystemeExploitation,
    engin: Engin,
    plateforme: Plateforme
  ) {
    this.navigateur = navigateur;
    this.systemeExploitation = systemeExploitation;
    this.engin = engin;
    this.plateforme = plateforme;
  }

  public toString(): string {
    return (
      `${this.navigateur.nom} ${this.navigateur.version} ` +
      `(${this.systemeExploitation.nom} ${this.systemeExploitation.version}) ` +
      `${this.engin.nom} (${this.plateforme.type})`
    );
  }
}
