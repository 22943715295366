<template>
  <div>
    <p
      class="mb-6"
      style="font-size: 16px"
      v-html="$t('demande.programme.modification-majeure.information')"
    />
    <checkbox-group
      v-for="groupeModification in groupesModification"
      :key="groupeModification.titre"
      class="mt-2"
      :label="groupeModification.titre"
      :items="groupeModification.questions"
      @changeValeurCheckbox="onChangeIndicateursProgramme"
    />
  </div>
</template>

<script lang="ts">
  import CheckboxGroupItem from '@/commun/components/champ-formulaire/checkbox-group/checkbox-group-item';
  import GroupeModificationsApportees from '@/components/faculte/demandes/formulaires/programme/section-modifications-apportees/groupe-modifications-apportees';
  import Vue, { PropType } from 'vue';
  import { IndicateursModificationProgramme } from '@/domain/models/demande-programme';
  import { CheckboxGroup } from '@/commun/components/champ-formulaire/checkbox-group';

  export default Vue.extend({
    name: 'SectionModificationsApportees',
    components: {
      CheckboxGroup,
    },
    props: {
      indicateursProgramme: {
        type: Object as PropType<IndicateursModificationProgramme>,
        required: true,
      },
    },
    data: () => ({
      sessionsAdmission: true,
      indicateursProgrammeCourant: {} as IndicateursModificationProgramme,
    }),
    computed: {
      estEnModification(): boolean {
        return Boolean(this.indicateursProgramme);
      },
      groupesModification(): GroupeModificationsApportees[] {
        return [
          {
            titre: this.$t(
              'demande.programme.modification-majeure.admission.titre'
            ).toString(),
            questions: [
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.admission.admission-sessions'
                ).toString(),
                keyObjet: 'admissionSessions',
                valeur: this.indicateursProgramme.admissionSessions,
              } as CheckboxGroupItem,
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.admission.admission-exigences'
                ).toString(),
                keyObjet: 'admissionExigences',
                valeur: this.indicateursProgramme.admissionExigences,
              } as CheckboxGroupItem,
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.admission.admission-suspension'
                ).toString(),
                keyObjet: 'admissionSuspension',
                valeur: this.indicateursProgramme.admissionSuspension,
              } as CheckboxGroupItem,
            ],
          },
          {
            titre: this.$t(
              'demande.programme.modification-majeure.description.titre'
            ).toString(),
            questions: [
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.description.description-officielle-orientations'
                ).toString(),
                keyObjet: 'descriptionOfficielleOrientations',
                valeur:
                  this.indicateursProgramme.descriptionOfficielleOrientations,
              } as CheckboxGroupItem,
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.description.description-officielle-autres'
                ).toString(),
                keyObjet: 'descriptionOfficielleAutres',
                valeur: this.indicateursProgramme.descriptionOfficielleAutres,
              } as CheckboxGroupItem,
            ],
          },
          {
            titre: this.$t(
              'demande.programme.modification-majeure.composantes.titre'
            ).toString(),
            questions: [
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.composantes.composantes-concentrations'
                ).toString(),
                keyObjet: 'composantesConcentrations',
                valeur: this.indicateursProgramme.composantesConcentrations,
              } as CheckboxGroupItem,
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.composantes.composantes-mineures'
                ).toString(),
                keyObjet: 'composantesMineures',
                valeur: this.indicateursProgramme.composantesMineures,
              } as CheckboxGroupItem,
            ],
          },
          {
            titre: this.$t(
              'demande.programme.modification-majeure.credits.titre'
            ).toString(),
            questions: [
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.credits.nombre-credits-augmentation'
                ).toString(),
                keyObjet: 'nombreCreditsAugmentation',
                valeur: this.indicateursProgramme.nombreCreditsAugmentation,
              } as CheckboxGroupItem,
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.credits.nombre-credits-reduction'
                ).toString(),
                keyObjet: 'nombreCreditsReduction',
                valeur: this.indicateursProgramme.nombreCreditsReduction,
              } as CheckboxGroupItem,
            ],
          },
          {
            titre: this.$t(
              'demande.programme.modification-majeure.structure.titre'
            ).toString(),
            questions: [
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.structure.structure-repartition-credits'
                ).toString(),
                keyObjet: 'structureRepartitionCredits',
                valeur: this.indicateursProgramme.structureRepartitionCredits,
              } as CheckboxGroupItem,
              {
                texte: this.$t(
                  'demande.programme.modification-majeure.structure.structure-cours'
                ).toString(),
                keyObjet: 'structureCours',
                valeur: this.indicateursProgramme.structureCours,
                infobulle: this.$t(
                  'demande.programme.modification-majeure.structure.infobulle'
                ).toString(),
              } as CheckboxGroupItem,
            ],
          },
        ];
      },
    },
    created() {
      if (this.estEnModification) {
        this.indicateursProgrammeCourant = { ...this.indicateursProgramme };
      }
    },
    methods: {
      onChangeIndicateursProgramme(payload: CheckboxGroupItem): void {
        this.indicateursProgrammeCourant[
          payload.keyObjet as keyof IndicateursModificationProgramme
        ] = payload.valeur;
        this.$emit(
          'changeIndicateursProgramme',
          this.indicateursProgrammeCourant
        );
      },
    },
  });
</script>
