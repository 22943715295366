import { RequeteSnackbar } from '@/commun/components/snackbars/requete-snackbar';
import Demande, { EtatsDemande } from '@/domain/models/demande';
import { DemandeDocument } from '@/domain/models/demande-document';
import Identite from '@/domain/models/identite';
import Individu from '@/domain/models/individu';
import GestionOffreFormationState from '@/store/gestion-offre-formation-state';

function trouverIndexDemande(
  gestionOffreFormationState: GestionOffreFormationState,
  idDemande: string
): number {
  const indexDemande = gestionOffreFormationState.demandes.findIndex(
    (demande: Demande) => demande.id === idDemande
  );
  if (indexDemande === -1) {
    throw new Error(`La demande ${idDemande} est inexistante.`);
  }
  return indexDemande;
}

const GestionOffreFormationMutations = {
  setDemandes(
    gestionOffreFormationState: GestionOffreFormationState,
    demandes: Demande[]
  ): void {
    gestionOffreFormationState.demandes = demandes;
  },
  modifierErreur(
    gestionOffreFormationState: GestionOffreFormationState,
    erreur: Error
  ): void {
    gestionOffreFormationState.erreur = erreur;
  },
  retirerErreur(gestionOffreFormationState: GestionOffreFormationState): void {
    gestionOffreFormationState.erreur = undefined;
  },
  setEstChargementDemandesEnCours(
    gestionOffreFormationState: GestionOffreFormationState,
    chargement: boolean
  ): void {
    gestionOffreFormationState.estChargementDemandesEnCours = chargement;
  },
  modifierMessageNotification(
    gestionOffreFormationState: GestionOffreFormationState,
    requeteNotificationGlobale: RequeteSnackbar
  ): void {
    gestionOffreFormationState.requeteNotificationGlobale =
      requeteNotificationGlobale;
  },
  changerEtatDemande(
    gestionOffreFormationState: GestionOffreFormationState,
    payload: { etat: EtatsDemande; id: string }
  ): void {
    const demande =
      gestionOffreFormationState.demandes[
        trouverIndexDemande(gestionOffreFormationState, payload.id)
      ];
    demande.etatDemande = payload.etat;
    gestionOffreFormationState.demandes[
      trouverIndexDemande(gestionOffreFormationState, demande.id)
    ] = demande;
  },
  modifierDemande(
    gestionOffreFormationState: GestionOffreFormationState,
    demande: Demande
  ): void {
    const indexDemande = trouverIndexDemande(
      gestionOffreFormationState,
      demande.id
    );
    gestionOffreFormationState.demandes[indexDemande] = demande;
    if (gestionOffreFormationState.demandeCourante?.id === demande.id) {
      gestionOffreFormationState.demandeCourante = demande;
    }
  },
  demandeCouranteDocuments(
    gestionOffreFormationState: GestionOffreFormationState,
    demandeDocuments: DemandeDocument[]
  ): void {
    gestionOffreFormationState.demandeCouranteDocuments = demandeDocuments;
  },
  ajouterDemandeCouranteDocument(
    gestionOffreFormationState: GestionOffreFormationState,
    demandeDocument: DemandeDocument
  ): void {
    gestionOffreFormationState.demandeCouranteDocuments.push(demandeDocument);
  },
  supprimerDemandeCouranteDocument(
    gestionOffreFormationState: GestionOffreFormationState,
    idDocument: string
  ): void {
    const index = gestionOffreFormationState.demandeCouranteDocuments
      .map((dcd) => dcd.idDocument)
      .indexOf(idDocument);

    if (index === -1) return;

    gestionOffreFormationState.demandeCouranteDocuments.splice(index, 1);
  },
  retirerDemande(
    gestionOffreFormationState: GestionOffreFormationState,
    idDemande: string
  ): void {
    const indexDemande = trouverIndexDemande(
      gestionOffreFormationState,
      idDemande
    );

    gestionOffreFormationState.demandes.splice(indexDemande, 1);
  },
  ajouterDemande(
    gestionOffreFormationState: GestionOffreFormationState,
    demande: Demande
  ): void {
    gestionOffreFormationState.demandes.push(demande);
  },
  setDemandeCourante(
    gestionOffreFormationState: GestionOffreFormationState,
    demande: Demande
  ): void {
    gestionOffreFormationState.demandeCourante = demande;
  },
  ajouterIndividu(
    gestionOffreFormationState: GestionOffreFormationState,
    individu: Individu
  ): void {
    const indexIndividu = gestionOffreFormationState.individus.findIndex(
      (individuExistant: Individu) =>
        individuExistant.idIndividu === individu.idIndividu
    );
    if (indexIndividu === -1) {
      gestionOffreFormationState.individus.push(individu);
    }
  },
  setIdentite(
    gestionOffreFormationState: GestionOffreFormationState,
    identite: Identite
  ): void {
    gestionOffreFormationState.identite = identite;
  },
  setTrierTableauDescendant(
    gestionOffreFormationState: GestionOffreFormationState,
    trierTableauDescendant: boolean
  ): void {
    gestionOffreFormationState.trierTableauDescendant = trierTableauDescendant;
  },
  setColonneATrier(
    gestionOffreFormationState: GestionOffreFormationState,
    colonneATrier: string
  ): void {
    gestionOffreFormationState.colonneATrier = colonneATrier;
  },
  setFiltreRecherche(
    gestionOffreFormationState: GestionOffreFormationState,
    filtreRecherche: string
  ): void {
    gestionOffreFormationState.filtreRecherche = filtreRecherche;
  },
  setNombreDemandesParPage(
    gestionOffreFormationState: GestionOffreFormationState,
    demandesParPage: number
  ): void {
    gestionOffreFormationState.demandesParPage = demandesParPage;
  },
};

export default GestionOffreFormationMutations;
