import Matiere from '@/commun/services/set/model/matiere';

export const matieresMock = (): Matiere[] => [
  {
    sigleMatiere: 'GLG',
    description: 'Géologie',
  },
  {
    sigleMatiere: 'SCV',
    description: 'Sciences de la vie',
  },
];
