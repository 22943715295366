<template>
  <v-flex v-if="afficherPage">
    <v-container :class="{ 'col-8': estTailleDesktop }">
      <creation-demande-faculte-information-approbation />
      <formulaire
        ref="formulaire"
        class="creation-demande-faculte-formulaire"
        @soumettre="onSoumettre"
      >
        <v-card class="pa-8" outlined>
          <h3 class="text-h5">
            {{ $t('demande.formulaires.section.information-de-base') }}
          </h3>
          <section-information-base
            v-model="demandeCourante"
            :unites-responsables="unitesResponsables"
            :facultes="facultes"
            :matieres="matieres"
            :session-courante="sessionCourante"
            :facultes-rattachement="facultesRattachement"
            :roles="roles"
          />
        </v-card>
        <v-card class="pa-8 mt-8" outlined>
          <section-information-mode-enseignement
            v-model="demandeCourante"
            :radio-reinitialisable="false"
          >
            <echelle-notation-radio-group
              slot="echelle-notation"
              v-model="demandeCourante.echelleNotation"
            />
          </section-information-mode-enseignement>
        </v-card>

        <section-information-complementaire v-model="demandeCourante" />

        <section-documents
          :demande-document-existants="demandeDocumentExistants"
          @change="nouveauxFichiers = $event"
          @supprimer-demande-document="documentExistantsASupprimer.push($event)"
          @supprimer-all-documents="supprimerAllDocuments"
        />

        <procedures-obligatoires-creation-cours
          class="mt-8"
          :demande-cours="demandeCourante"
        />

        <div
          :class="[
            estTailleDesktop ? 'flex-row' : 'flex-column',
            'd-flex',
            'mt-8',
          ]"
        >
          <v-btn
            ref="creation-demande-faculte-formulaire__enregistrer-et-continuer"
            class="primary"
            type="submit"
            :disabled="enregistrementEnCours"
          >
            {{ $t('commun.actions.enregistrer-et-continuer') }}
          </v-btn>
          <v-btn
            :class="[estTailleDesktop ? 'ml-4' : 'mt-6']"
            @click="$emit('annuler')"
          >
            {{ $t('commun.actions.annuler') }}
          </v-btn>
        </div>
      </formulaire>
    </v-container>
  </v-flex>
</template>

<script lang="ts">
  import { Formulaire } from '@/commun/components/formulaire';
  import Faculte from '@/commun/services/set/model/faculte';
  import Matiere from '@/commun/services/set/model/matiere';
  import Session from '@/commun/services/set/model/session';
  import Unite from '@/commun/services/set/model/unites';
  import { EchelleNotationRadioGroup } from '@/components/faculte/demandes/formulaires/champs/echelle-notation';
  import { EchelleNotation } from '@/components/faculte/demandes/formulaires/champs/echelle-notation/echelle-notation';
  import { ProceduresObligatoiresCreationCours } from '@/components/faculte/demandes/formulaires/creation-cours/procedures-obligatoires-dialogue';
  import SectionInformationBase from '@/components/faculte/demandes/formulaires/creation-cours/section-information-base/section-information-base.vue';
  import { SectionInformationComplementaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-complementaire';
  import { SectionInformationModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement';
  import { CreationDemandeFaculteInformationApprobation } from '@/components/faculte/demandes/formulaires/creation-demande-faculte-information-approbation';
  import { EtatsDemande, TypesDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import { LangueEnseignement } from '@/domain/models/langue-enseignement';
  import { ModesEnseignement } from '@/domain/models/mode-enseignement';
  import Vue, { PropType } from 'vue';
  import { IdentiteRoles } from '@/domain/models/identite';
  import { DemandeDocument } from '@/domain/models/demande-document';
  import { SectionDocuments } from './creation-cours/section-documents';

  export default Vue.extend({
    name: 'CreationDemandeFaculteFormulaire',
    components: {
      EchelleNotationRadioGroup,
      Formulaire,
      ProceduresObligatoiresCreationCours,
      SectionInformationBase,
      SectionInformationComplementaire,
      SectionInformationModeEnseignement,
      CreationDemandeFaculteInformationApprobation,
      SectionDocuments,
    },
    props: {
      demandeEnModification: {
        type: Object as PropType<DemandeCours>,
        default: undefined,
        required: false,
      },
      unitesResponsables: {
        type: Array as PropType<Array<Unite>>,
        required: true,
      },
      facultes: {
        type: Array as PropType<Array<Faculte>>,
        required: true,
      },
      matieres: {
        type: Array as PropType<Array<Matiere>>,
        required: true,
      },
      sessionCourante: {
        type: Object as PropType<Session>,
        required: true,
      },
      facultesRattachement: {
        type: Array as PropType<Array<string>>,
        required: true,
      },
      roles: {
        type: Array as PropType<Array<IdentiteRoles>>,
        required: true,
      },
    },
    data() {
      return {
        nouveauxFichiers: new Array<File>(0),
        documentExistantsASupprimer: new Array<DemandeDocument>(0),
        enregistrementEnCours: false,
        demandeCourante: {} as DemandeCours,
        documentsExistantsTriees: [] as DemandeDocument[],
        afficherPage: false,
      };
    },
    computed: {
      estEnModification(): boolean {
        return Boolean(this.demandeEnModification);
      },
      demandeDocumentExistants(): DemandeDocument[] {
        return this.documentsExistantsTriees.filter(
          (dcd: DemandeDocument) =>
            !this.documentExistantsASupprimer
              .map((das: DemandeDocument) => das.idDocument)
              .includes(dcd.idDocument)
        );
      },
      estRegulier(): boolean {
        return (
          this.demandeCourante.modeEnseignement === ModesEnseignement.Regulier
        );
      },
      estStage(): boolean {
        return (
          this.demandeCourante.modeEnseignement === ModesEnseignement.Stage
        );
      },
      estStageResidenceMedecine(): boolean {
        return (
          this.demandeCourante.modeEnseignement ===
          ModesEnseignement.StageResidenceMedecine
        );
      },
      estEducationContinue(): boolean {
        return (
          this.demandeCourante.modeEnseignement ===
          ModesEnseignement.EducationContinue
        );
      },
      estTailleDesktop(): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
      proceduresObligatoires(): string[] {
        const procedures: string[] = [];
        if (
          this.demandeCourante.echelleNotation ===
          EchelleNotation.REUSSITE_ECHEC
        ) {
          procedures.push();
        }
        if (
          this.demandeCourante.langueEnseignement === LangueEnseignement.AUTRES
        ) {
          procedures.push();
        }
        return procedures;
      },
    },
    async created() {
      this.afficherPage = false;
      await this.$store.commit('demandeCouranteDocuments', []);
      if (this.demandeEnModification) {
        this.demandeCourante = { ...this.demandeEnModification };

        await this.$store.dispatch(
          'obtenirDemandeCouranteDocuments',
          this.demandeCourante.id
        );
        this.documentsExistantsTriees = ([] as DemandeDocument[]).concat(
          this.$store.getters.demandeCouranteDocumentsTrieesParDate
        );
      } else {
        this.demandeCourante.etatDemande = EtatsDemande.EN_REDACTION;
        this.demandeCourante.typeDemande = TypesDemande.CREATION_COURS;
        this.demandeCourante.echelleNotation = EchelleNotation.LETTRE;
        this.demandeCourante.langueEnseignement = LangueEnseignement.FRANCAIS;
        this.demandeCourante.codeSession = this.sessionCourante.codeSession;
      }
      this.afficherPage = true;
    },
    methods: {
      supprimerAllDocuments() {
        this.documentExistantsASupprimer = this.documentsExistantsTriees;
        this.nouveauxFichiers = new Array<File>(0);
      },

      async onSoumettre(): Promise<any> {
        if (!this.enregistrementEnCours) {
          this.enregistrementEnCours = true;

          await this.$emit('enregistrer', {
            demande: this.demandeCourante,
            nouveauxFichiers: this.nouveauxFichiers,
            documentExistantsASupprimer: this.documentExistantsASupprimer,
          });
        }
      },
    },
  });
</script>

<style>
  .creation-demande-faculte-formulaire .v-textarea .v-text-field__details {
    padding: 0;
  }

  .creation-demande-faculte-formulaire .v-text-field .v-text-field__details {
    padding: 0;
  }
</style>
