<template>
  <div />
</template>

<script lang="ts">
  import Vue from 'vue';
  import { AuthModule } from '@/commun/services/auth/AuthModule';

  export default Vue.extend({
    name: 'Deconnecter',
    props: {
      authModule: {
        type: Object as () => AuthModule,
        required: true,
      },
    },
    async created(): Promise<void> {
      await this.authModule.logout();
    },
  });
</script>
