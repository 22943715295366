<template>
  <div>
    <demandes-tableau-entete-mobile
      v-if="estTailleMobile"
      :afficher-bouton-creer-demande="afficherBoutonCreerDemande"
      :filtre-recherche.sync="filtreRecherche"
      @naviguerCreerDemande="onNaviguerCreerDemande"
      @update:items-per-page="onPaginationModifie"
    />

    <v-data-table
      ref="demandes-tableau"
      fixed-header
      class="demandes-tableau"
      :loading="estChargement"
      :headers="colonnes"
      :items="lignes"
      :search="filtreRecherche"
      :footer-props="{
        'show-first-last-page': true,
        'items-per-page-text': $t('demande.tableau.demandes-par-page'),
        'items-per-page-options': [
          5,
          10,
          15,
          {
            text: $t('demande.tableau.tout-afficher'),
            value: -1,
          },
        ],
      }"
      :items-per-page="demandesParPage"
      :header-props="{
        'sort-by-text': $t('demande.tableau.entete.trier-par'),
      }"
      :custom-filter="customFilter"
      :sort-by="colonneATrier"
      :sort-desc="trierDescendant"
      :no-results-text="$t('demande.tableau.aucun-resultat-filtre')"
      @update:sort-desc="onTriDescendentModifie"
      @update:sort-by="onColonneTriModifie"
      @update:items-per-page="onPaginationModifie"
      @update:filtre-recherche="onFiltreRechercheModifie"
    >
      <template #top>
        <demandes-tableau-entete-desktop
          v-if="!estTailleMobile"
          ref="demandes-entete-tableau"
          :afficher-bouton-creer-demande="afficherBoutonCreerDemande"
          :filtre-recherche.sync="filtreRecherche"
          @naviguerCreerDemande="onNaviguerCreerDemande"
          @update:filtre-recherche="onFiltreRechercheModifie"
        />
      </template>
      <template v-if="!estTailleMobile" #header.actions />
      <template #no-data>
        <div class="black--text">
          {{ $t('demande.tableau.aucune-demande') }}
        </div>
      </template>
      <template #loading>
        {{ $t('demande.tableau.chargement') }}
      </template>

      <template #header.numeroDemande>
        <span class="ml-2">{{
          $t('demande.tableau.colonne.numeroDemande')
        }}</span>
      </template>
      <template #item.dateDepot="{ item }">
        {{ formatterDateDepot(item.dateDepot) }}
      </template>
      <template #item.numeroDemande="{ item }">
        <slot name="item.numeroDemande.contenu" :item="item" />
      </template>
      <template #item.coursProgramme="{ item }">
        <div class="d-flex flex-column">
          <span>{{ item.coursProgramme[0] }}</span>
          <span class="font-weight-light">
            {{ item.coursProgramme[1] }}
          </span>
        </div>
      </template>
      <template #item.typeDemande="{ item }">
        {{ $t(`demande.types.${item.typeDemande}`) }}
      </template>
      <template #item.actions="{ item }">
        <demandes-tableau-actions
          :key="item.numeroDemande"
          :demande="item.demande"
        />
      </template>
      <template #footer.page-text="props">
        {{ $t('demande.tableau.pagination.prefixe') }}
        {{ props.pageStart }}-{{ props.pageStop }}
        {{ $t('demande.tableau.pagination.separateur') }}
        {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
  import { DateUtils } from '@/commun/utils/date-utils';
  import { DemandesTableauActions } from '@/components/demandes/tableau/actions';
  import {
    DemandesTableauColonnes,
    DemandesTableauColonnesNom,
  } from '@/components/demandes/tableau/demandes-tableau-colonnes';
  import DemandesTableauLigne, {
    creerLigneDemande,
  } from '@/components/demandes/tableau/demandes-tableau-ligne';
  import { DemandesTableauEnteteDesktop } from '@/components/demandes/tableau/entete/desktop';
  import { DemandesTableauEnteteMobile } from '@/components/demandes/tableau/entete/mobile';
  import { ETAT_INITIAL } from '@/domain/models/colonne-tableau-tri-etat-initial';
  import DemandeCours from '@/domain/models/demande-cours';
  import Vue, { PropType } from 'vue';
  import { DataTableHeader } from 'vuetify';

  export default Vue.extend({
    name: 'DemandesTableau',
    components: {
      DemandesTableauActions,
      DemandesTableauEnteteDesktop,
      DemandesTableauEnteteMobile,
    },
    props: {
      afficherBoutonCreerDemande: {
        type: Boolean,
        default: true,
      },
      colonneTriParDefaut: {
        required: false,
        type: String,
        default() {
          return DemandesTableauColonnesNom.Numero;
        },
      },
      colonnesAffichees: {
        required: false,
        type: Array as PropType<Array<DemandesTableauColonnesNom>>,
        default() {
          return [
            DemandesTableauColonnesNom.Numero,
            DemandesTableauColonnesNom.Session,
            DemandesTableauColonnesNom.Type,
            DemandesTableauColonnesNom.Faculte,
            DemandesTableauColonnesNom.CoursProgramme,
            DemandesTableauColonnesNom.Etat,
            DemandesTableauColonnesNom.Actions,
          ];
        },
      },
      demandes: {
        type: Array as PropType<Array<DemandeCours>>,
        default: () => [],
      },
    },
    data: () => ({
      filtreRecherche: '',
      demandesParPage: 10,
      trierDescendant: false,
      colonneATrier: '',
    }),

    computed: {
      estChargement() {
        return this.$store.getters.estChargementDemandesEnCours;
      },
      colonnes(): DataTableHeader[] {
        return DemandesTableauColonnes(this.$i18n, this.colonnesAffichees);
      },
      lignes(): DemandesTableauLigne[] {
        return this.demandes.map((demande: DemandeCours) =>
          creerLigneDemande(demande, this)
        );
      },
      estTailleMobile(): boolean {
        return !this.$vuetify.breakpoint.smAndUp;
      },
    },
    created() {
      this.trierDescendant = this.$store.getters.trierTableauDescendant;
      if (this.$store.getters.colonneATrier === ETAT_INITIAL) {
        this.colonneATrier = this.colonneTriParDefaut;
      } else {
        this.colonneATrier = this.$store.getters.colonneATrier;
      }
      this.filtreRecherche = this.$store.getters.filtreRecherche;
      this.demandesParPage = this.$store.getters.demandesParPage;
    },
    methods: {
      onNaviguerCreerDemande(): void {
        this.$emit('naviguerCreerDemande');
      },
      onPaginationModifie(event: number): void {
        this.$store.dispatch('modifierNombreDemandesParPageTableau', event);
      },
      onFiltreRechercheModifie(event: string): void {
        this.$store.dispatch('modifierFiltreRechercheTableau', event);
      },
      onTriDescendentModifie(event: any): void {
        this.trierDescendant = event;
        this.$store.dispatch('modifierOrdreTriTableau', this.trierDescendant);
      },
      onColonneTriModifie(event: any): void {
        this.colonneATrier = event;
        this.$store.dispatch('modifierColonneTriTableau', this.colonneATrier);
      },
      customFilter(_value: any, entreeRecherche: string | null, item: any) {
        const valeursItem = {
          numeroDemande: item['numeroDemande'],
          codeSession: item['codeSession'],
          typeDemande: item['typeDemande'],
          codeFaculte: item['codeFaculte'],
          coursProgramme: item['coursProgramme'].join(),
          etatDemande: item['etatDemande'],
          dateDepot: item['dateDepot'].substring(0, 10),
        };
        return Object.values(valeursItem)
          .filter((valeurItem) => valeurItem)
          .some((valeurItem) =>
            valeurItem
              .toString()
              .toUpperCase()
              .includes(entreeRecherche?.toUpperCase() || '')
          );
      },
      formatterDateDepot(dateDepot: string): string {
        if (isNaN(Date.parse(dateDepot))) {
          return '';
        }
        return DateUtils.formaterDateShortISO(new Date(dateDepot));
      },
    },
  });
</script>

<style lang="scss">
  .v-data-table-header-mobile {
    padding-bottom: 64px;
  }

  .demandes-tableau {
    &__lien-numero-demande {
      text-decoration: none;
    }
  }

  .demandes-tableau .v-data-footer {
    margin-right: 0 !important;
  }

  .demandes-tableau td {
    padding-top: 0.75em !important;
    padding-bottom: 0.75em !important;
  }

  .demandes-tableau th {
    white-space: nowrap;
  }
</style>
