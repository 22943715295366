import { IdentiteRoles } from '@/domain/models/identite';
import IdentiteRolesGuard from '@/router/identite-roles-guard';
import Routes, { RoutesSansAuthentification } from '@/router/routes';
import { Accueil } from '@/views/accueil';
import { Callback } from '@/views/callback';
import { Deconnecter } from '@/views/deconnecter';
import { ErreurReseau } from '@/views/erreur';
import { DemandesFaculte } from '@/views/faculte/demande';
import { ConsulterDemandeFaculte } from '@/views/faculte/demande/consulter';
import { CreerDemandeCreationCours } from '@/views/faculte/demande/cours/creation/creer';
import { CreerDemandeDesactivationCours } from '@/views/faculte/demande/cours/desactivation/creer';
import { CreerDemandeModificationCours } from '@/views/faculte/demande/cours/modification/creer';
import { CreerDemandeCours } from '@/views/faculte/demande/cours/operations/creer';
import { ModifierDemandeCours } from '@/views/faculte/demande/cours/operations/modifier';
import { CreerDemandeReactivationCours } from '@/views/faculte/demande/cours/reactivation/creer';
import { DemandeType } from '@/views/faculte/demande/type';
import { DemandeTraitement } from '@/views/gestion/demande-traitement';
import { DemandesGestion } from '@/views/gestion/demandes-gestion';
import { NonAutorise } from '@/views/non-autorise';
import { NotFound } from '@/views/not-found';
import { SectionSite } from '@/views/section-site';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { Store } from 'vuex';
import RootState from '../commun/store/root-state';
import { CreerDemandeModificationProgramme } from '@/views/faculte/demande/programme';
import { AuthModule } from '@/commun/services/auth/AuthModule';

Vue.use(VueRouter);

const createRoutes = (authModule: AuthModule): Array<RouteConfig> => [
  {
    path: '/',
    name: Routes.Accueil,
    component: Accueil,
  },
  {
    path: '/demandes',
    component: SectionSite,
    meta: {
      roles: [IdentiteRoles.DEMANDEUR, IdentiteRoles.GESTIONNAIRE],
    },
    children: [
      {
        path: '',
        name: Routes.Demandes,
        component: DemandesFaculte,
      },
      {
        path: 'creer',
        name: Routes.DemandeType,
        component: DemandeType,
      },
      {
        path: 'creer/demande-programme',
        name: Routes.DemandeType,
        component: CreerDemandeCours,
        children: [
          {
            path: 'modification',
            name: Routes.CreerDemandeModificationProgramme,
            component: CreerDemandeModificationProgramme,
          },
        ],
      },
      {
        path: 'creer/demande-cours',
        name: Routes.DemandeType,
        component: CreerDemandeCours,
        children: [
          {
            path: 'creation',
            name: Routes.CreerDemandeCreationCours,
            component: CreerDemandeCreationCours,
          },
          {
            path: 'modification',
            name: Routes.CreerDemandeModificationCours,
            component: CreerDemandeModificationCours,
          },
          {
            path: 'reactivation',
            name: Routes.CreerDemandeReactivationCours,
            component: CreerDemandeReactivationCours,
          },
          {
            path: 'desactivation',
            name: Routes.CreerDemandeDesactivationCours,
            component: CreerDemandeDesactivationCours,
          },
        ],
      },
      {
        path: ':idDemande',
        name: Routes.ConsulterDemande,
        component: ConsulterDemandeFaculte,
        props: true,
      },
      {
        path: 'modifier/:idDemande',
        name: Routes.ModifierDemande,
        component: ModifierDemandeCours,
        props: true,
      },
    ],
  },
  {
    path: '/gestion',
    component: SectionSite,
    meta: {
      roles: [IdentiteRoles.GESTIONNAIRE],
    },
    children: [
      {
        path: '',
        name: Routes.GestionDemandes,
        component: DemandesGestion,
      },
      {
        path: 'demande/:idDemande',
        name: Routes.TraitementDemande,
        component: DemandeTraitement,
        props: true,
      },
    ],
  },

  {
    path: '/erreur-reseau',
    name: Routes.ErreurReseau,
    component: ErreurReseau,
  },
  {
    path: '/erreur-non-autorise',
    name: Routes.ErreurNonAutorise,
    component: NonAutorise,
  },
  {
    path: '/deconnecter',
    name: Routes.Deconnecter,
    props: { authModule: authModule },
    component: Deconnecter,
  },
  {
    path: '/callback',
    name: Routes.Callback,
    props: { authModule: authModule },
    component: Callback,
  },
  {
    path: '*',
    name: Routes.NotFound,
    component: NotFound,
  },
];

export const routerFactory = (
  store: Store<RootState>,
  authModule: AuthModule
): VueRouter => {
  const router = new VueRouter({
    mode: 'history',
    routes: createRoutes(authModule),
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });
  router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
    if (RoutesSansAuthentification.some((nomRoute) => nomRoute === to.name)) {
      next();
    } else {
      Promise.resolve(IdentiteRolesGuard(to, from, next, store)).finally(() =>
        next()
      );
    }
  });
  return router;
};

export default routerFactory;
