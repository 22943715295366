<template>
  <div v-if="afficherCredits">
    <stepper-credits
      v-model="demandeCourante.nombreCredits"
      :disabled="disableCredits"
    />
    <v-divider class="mb-6" />
    <charge-travail v-model="demandeCourante" />
  </div>
</template>

<script lang="ts">
  import { StepperCredits } from '@/components/faculte/demandes/formulaires/champs/stepper-credits';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { SectionModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement';
  import ChargeTravail from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/charge-travail/charge-travail.vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ModeEnseignementStageResidenceMedecineFormulaire',
    components: {
      ChargeTravail,
      StepperCredits,
    },
    mixins: [SectionFormulaireCours, SectionModeEnseignement],
  });
</script>
