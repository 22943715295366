export const enregistrerOuOuvrirFichier = (
  blob: Blob,
  nomFichier: string
): void => {
  if ((window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, nomFichier);
    return;
  }

  const a: HTMLAnchorElement = document.createElement('a');
  const url = window.URL.createObjectURL(blob);

  a.style.display = 'none';
  a.href = url;
  a.download = nomFichier;

  document.body.appendChild(a);

  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
