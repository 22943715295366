import DetailsCours from '@/commun/services/set/model/details-cours';
import Matiere from '@/commun/services/set/model/matiere';
import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';
import Session from '@/commun/services/set/model/session';
import Unite from '@/commun/services/set/model/unites';
import EtudesState from '@/commun/store/modules/etudes/etudes-state';
import ResumeDetailsProgrammes from '@/commun/services/set/model/resume-details-programmes';

const EtudesMutations = {
  setDetailsCours(etudesState: EtudesState, detailsCours: DetailsCours): void {
    etudesState.detailsCours = detailsCours;
  },
  setResumeDetailsCoursEnChargement(
    etudesState: EtudesState,
    enChargement: boolean
  ): void {
    etudesState.resumeDetailsCoursEnChargement = enChargement;
  },
  setResumeDetailsCours(
    etudesState: EtudesState,
    resumeDetailsCours: ResumeDetailsCours[]
  ): void {
    etudesState.resumeDetailsCours = resumeDetailsCours;
  },
  addResumeDetailsCours(
    etudesState: EtudesState,
    resumeDetailsCours: ResumeDetailsCours[]
  ): void {
    etudesState.resumeDetailsCours =
      etudesState.resumeDetailsCours.concat(resumeDetailsCours);
  },
  setResumeDetailsProgrammes(
    etudesState: EtudesState,
    resumeDetailsProgrammes: ResumeDetailsProgrammes[]
  ): void {
    etudesState.resumeDetailsProgrammes = resumeDetailsProgrammes;
  },
  effacerResumeDetailsProgrammes(etudesState: EtudesState): void {
    etudesState.resumeDetailsProgrammes = [];
  },
  setMatieres(etudesState: EtudesState, matieres: Matiere[]): void {
    etudesState.matieres = matieres;
  },
  setSessionCourante(etudesState: EtudesState, session: Session): void {
    etudesState.sessionCourante = session;
  },
  setSessions(etudesState: EtudesState, sessions: Session[]): void {
    etudesState.sessions = sessions;
  },
  setUnites(etudesState: EtudesState, unites: Unite[]): void {
    etudesState.unites = unites;
  },
};

export default EtudesMutations;
