import ResumeDetailsProgrammes from '@/commun/services/set/model/resume-details-programmes';

export function resumeDetailsProgrammeMock(): ResumeDetailsProgrammes[] {
  return [
    {
      idProgramme: 'B-IFT.IFT',
      codeProgramme: 'B-IFT',
      titreProgramme: 'Bac en info',
      codeMajeure: 'IFT',
      descriptionProgrammeGenerique:
        'descriptionProgrammeGenerique informatique',
      descriptionMajeure: 'descriptionMajeure informatique',
      codeFaculteResponsable: 36,
    } as ResumeDetailsProgrammes,
    {
      idProgramme: 'B-ADM.ADM',
      codeProgramme: 'B-ADM',
      titreProgramme: 'Bac en administration',
      codeMajeure: 'ADM',
      descriptionProgrammeGenerique:
        'descriptionProgrammeGenerique administration',
      descriptionMajeure: 'descriptionMajeure administration',
      codeFaculteResponsable: 24,
    } as ResumeDetailsProgrammes,
  ];
}
