<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="validation"
    :name="nom"
    :immediate="immediate"
  >
    <slot :errors="errors" />
  </validation-provider>
</template>

<script lang="ts">
  import { ValidationProvider } from 'vee-validate';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ValidationFormulaire',
    components: {
      ValidationProvider,
    },
    props: {
      validation: {
        type: String,
        default: '',
      },
      nom: {
        type: String,
        required: true,
      },
      immediate: {
        type: Boolean,
        default: false,
      },
    },
  });
</script>
