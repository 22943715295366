import type { ServiceEtudes } from '@/commun/services/set/services/service-etudes';
import ModuleEtudes from '@/commun/store/modules/etudes/module-etudes';
import type { ServiceDemandes } from '@/services/service-demandes';
import Services from '@/services/services';
import ModuleGestionOffreFormation from '@/store/module/module-gestion-offre-formation';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import RootState from '../commun/store/root-state';

Vue.use(Vuex);

@injectable()
class StoreGestionOffreFormation extends Store<RootState> {
  constructor(
    @inject(Services.ServiceDemandes)
    serviceDemandes: ServiceDemandes,
    @inject(Services.ServiceEtudes)
    serviceEtudes: ServiceEtudes
  ) {
    super({
      modules: {
        GestionOffreFormation: ModuleGestionOffreFormation(serviceDemandes),
        Etudes: ModuleEtudes(serviceEtudes),
      },
    });
  }
}

export default StoreGestionOffreFormation;
