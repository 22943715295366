import {
  Engin,
  InformationsNavigateur,
  Navigateur,
  Plateforme,
  SystemeExploitation,
} from '@/commun/utils/informations-navigateur';
import Bowser from 'bowser';

export function obtenirInformationsNavigateur(): InformationsNavigateur {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const informations = browser.getResult();

  const navigateur: Navigateur = {
    nom: informations.browser.name,
    version: informations.browser.version,
  };
  const systemeExploitation: SystemeExploitation = {
    nom: informations.os.name,
    version: informations.os.version,
  };
  const engin: Engin = {
    nom: informations.engine.name,
  };
  const plateforme: Plateforme = {
    type: informations.platform.type,
  };

  return new InformationsNavigateur(
    navigateur,
    systemeExploitation,
    engin,
    plateforme
  );
}
