<template>
  <validation-observer ref="observateurValidation">
    <v-form ref="formulaire" autocomplete="off" @submit.prevent="onSubmit()">
      <slot />
    </v-form>
  </validation-observer>
</template>

<script lang="ts">
  import { ValidationObserver } from 'vee-validate';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'Formulaire',
    components: {
      ValidationObserver,
    },
    methods: {
      async onSubmit(): Promise<void> {
        const observateurValidation = this.$refs
          .observateurValidation as Vue & { validate: () => boolean };
        const valide = await observateurValidation.validate();
        if (valide) {
          this.$emit('soumettre');
        } else {
          this.defilerPremiereErreur();
        }
      },
      defilerPremiereErreur(): void {
        const el = document.querySelector(
          '.error--text:first-of-type'
        ) as HTMLElement;
        if (el) {
          const offset = 50;
          this.$vuetify.goTo(el, {
            offset,
          });
        }
      },
    },
  });
</script>
