import RootState from '@/commun/store/root-state';
import { ETAT_INITIAL } from '@/domain/models/colonne-tableau-tri-etat-initial';
import { ServiceDemandes } from '@/services/service-demandes';
import GestionOffreFormationState from '@/store/gestion-offre-formation-state';
import GestionOffreFormationActions from '@/store/module/gestion-offre-formation-actions';
import GestionOffreFormationGetters from '@/store/module/gestion-offre-formation-getters';
import GestionOffreFormationMutations from '@/store/module/gestion-offre-formation-mutations';
import { Module } from 'vuex';

const ModuleGestionOffreFormation = (
  serviceDemandes: ServiceDemandes
): Module<GestionOffreFormationState, RootState> => ({
  state: {
    erreur: undefined,
    estChargementDemandesEnCours: false,
    demandeCourante: undefined,
    demandeCouranteDocuments: [],
    demandes: [],
    requeteNotificationGlobale: undefined,
    individus: [],
    identite: undefined,
    trierTableauDescendant: true,
    colonneATrier: ETAT_INITIAL,
    filtreRecherche: '',
    demandesParPage: 10,
  },
  mutations: GestionOffreFormationMutations,
  actions: GestionOffreFormationActions(serviceDemandes),
  getters: GestionOffreFormationGetters,
});

export default ModuleGestionOffreFormation;
