import { DemandeCours } from '@/domain/models/demande-cours';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'SectionFormulaireCours',
  props: {
    validationRequis: {
      type: Boolean,
      default: true,
    },
    validationPositionnementRequis: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object as PropType<DemandeCours>,
      default() {
        return {} as DemandeCours;
      },
    },
  },
  data: () => ({
    demandeCourante: {} as DemandeCours,
  }),
  computed: {
    regleValidationRequis(): string {
      return this.validationRequis ? 'requis' : '';
    },
    regleValidationRequisPositionnement(): string {
      return this.validationPositionnementRequis ? 'requis' : '';
    },
  },
  watch: {
    value: {
      handler(valeur: DemandeCours) {
        this.demandeCourante = valeur;
      },
      deep: true,
    },
    demandeCourante: {
      handler(valeur: DemandeCours) {
        this.$emit('input', valeur);
      },
      deep: true,
    },
  },
  created() {
    this.demandeCourante = { ...this.value };
  },
});
