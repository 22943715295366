<template>
  <champ-formulaire :validation="validation" alignement-colonne-droite="start">
    <v-textarea
      ref="text-area__saisie"
      v-model="innerValue"
      slot-scope="{ errors }"
      class="mt-0"
      :error-messages="errors"
      outlined
      v-bind="$attrs"
      :counter="maximumCaracteres"
      @update:error="estInvalide = $event"
      @change="onValueChange"
    >
      <template #message="{ message, key }">
        <div :key="key" v-html="message"></div>
      </template>
    </v-textarea>
    <slot slot="contenu-droite" name="contenu-droite" />
    <div
      v-if="estInvalide"
      slot="contenu-hint"
      class="v-messages theme--light v-messages__wrapper v-messages__message"
      v-html="$attrs.hint"
    />
  </champ-formulaire>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'TextArea',
    components: {
      ChampFormulaire,
    },
    mixins: [Validable],
    inheritAttrs: false,
    props: {
      maximumCaracteres: {
        type: Number,
        default: Infinity,
      },
      value: {
        type: [String, Object],
        default: '',
      },
    },
    data() {
      return {
        innerValue: '',
        estInvalide: false,
      };
    },
    created() {
      this.innerValue = this.value;
    },
  });
</script>
