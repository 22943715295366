<template>
  <v-input :error-messages="errors" :value="value">
    <div>
      <v-label class="theme--light text--lighten-2">{{ label }}</v-label>
      <div class="d-flex align-center">
        <v-btn
          ref="boutonDecrementer"
          icon
          outlined
          x-small
          :disabled="!peutDecrementer || disabled"
          @click="decrementer"
        >
          <v-icon color="primary"> mdi-minus </v-icon>
        </v-btn>
        <v-text-field
          ref="entreeValeur"
          v-model="valeurInterne"
          :placeholder="afficherPlaceholder ? placeholder : ''"
          :error-messages="errors"
          hide-details
          :disabled="disabled"
          class="stepper-nombre__textfield shrink pa-0 mt-0 ml-2 mr-2"
          type="number"
          @change="onValueChange"
        />
        <v-btn
          ref="boutonIncrementer"
          class="align-center"
          icon
          outlined
          x-small
          :disabled="!peutIncrementer || disabled"
          @click="incrementer"
        >
          <v-icon color="primary"> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
  </v-input>
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import { clamp } from '@/commun/utils/numerique-utils';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'StepperNombre',
    mixins: [Validable],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Number,
        default: null,
      },
      label: {
        type: String,
        default: '',
      },
      min: {
        type: Number,
        default: Number.MIN_SAFE_INTEGER,
      },
      max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER,
      },
      precision: {
        type: Number,
        default: 0,
      },
      afficherPlaceholder: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      iconeMoins: 'mdi-minus',
      iconePlus: 'mdi-plus',
      valeurInterne: '',
      valeurPrecedente: 0,
    }),
    computed: {
      placeholder(): string {
        const valeurPlaceholder = 0;
        return valeurPlaceholder.toFixed(this.precision);
      },
      peutDecrementer(): boolean {
        return this.value > this.min;
      },
      peutIncrementer(): boolean {
        return this.value < this.max;
      },
    },
    watch: {
      value(nouvelleValeur: number) {
        this.mettreAJourValeurInterne(nouvelleValeur);
      },
    },
    created() {
      this.mettreAJourValeurInterne(this.value);
    },
    methods: {
      decrementer(): void {
        this.mettreAJourValeur(this.value - 1);
      },
      incrementer(): void {
        if (this.value === null) {
          this.mettreAJourValeur(0);
        } else {
          this.mettreAJourValeur(this.value + 1);
        }
      },
      onValueChange(nouvelleValeur: string): void {
        if (nouvelleValeur === '') {
          this.mettreAJourValeurNulle();
          return;
        }
        this.mettreAJourValeur(+nouvelleValeur);
      },
      mettreAJourValeurNulle(): void {
        this.$emit('input', null);
        this.mettreAJourValeurInterne(null);
      },
      mettreAJourValeur(valeur: number): void {
        const valeurNumerique: number = clamp(valeur, this.min, this.max);
        this.$emit('input', valeurNumerique);
        this.mettreAJourValeurInterne(valeurNumerique);
      },
      mettreAJourValeurInterne(valeur: number | null): void {
        if (valeur === null) {
          this.valeurInterne = '';
          return;
        }
        this.valeurInterne = valeur.toFixed(this.precision);
      },
    },
  });
</script>

<style lang="scss">
  .stepper-nombre {
    &__textfield {
      width: 80px;
    }

    &__textfield input {
      text-align: center;
    }

    &__textfield input[type='number'] {
      -moz-appearance: textfield;
    }

    &__textfield input::-webkit-outer-spin-button,
    &__textfield input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
</style>
