<template>
  <div class="d-flex flex-column pl-4 pr-4">
    <div class="d-flex mt-4 pb-1 flex-row align-center">
      <h3 class="text-h5">{{ $t('demande.tableau.titre') }}</h3>
      <v-spacer />
      <v-btn
        v-if="afficherBoutonCreerDemande"
        class="ml-4"
        @click="onClickCreerDemande"
      >
        {{ $t('demande.tableau.actions.creer') }}
      </v-btn>
    </div>
    <v-text-field
      :value="filtreRecherche"
      :label="$t('demande.tableau.actions.filtrer')"
      @input="$emit('update:filtre-recherche', $event)"
    />
  </div>
</template>

<script lang="ts">
  import { EnteteDemandesTableau } from '@/components/demandes/tableau/entete';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DemandesTableauEnteteMobile',
    mixins: [EnteteDemandesTableau],
    props: {
      filtreRecherche: {
        type: String,
        default: '',
      },
    },
  });
</script>
