<template>
  <div>
    <p v-if="!afficherFormulaire" ref="messageDemandeEnRedaction" class="ma-8">
      {{ messageModificationImpossible }}
    </p>
    <formulaire-suivi-demande
      v-if="afficherFormulaire"
      ref="formulaireSuivi"
      :demande="demande"
      :matieres="matieres"
    />
  </div>
</template>

<script lang="ts">
  import Matiere from '@/commun/services/set/model/matiere';
  import { FormulaireSuiviDemande } from '@/components/gestion/traitement/formulaires/suivi/formulaire';
  import Demande, { EtatsDemande } from '@/domain/models/demande';
  import Vue, { PropType } from 'vue';

  const etatsDemandeFormulaireCache = [
    EtatsDemande.EN_REDACTION,
    EtatsDemande.EN_MODIFICATION,
  ];

  export default Vue.extend({
    name: 'SuiviDemande',
    components: {
      FormulaireSuiviDemande,
    },
    props: {
      demande: {
        type: Object as PropType<Demande>,
        required: true,
      },
      matieres: {
        type: Array as PropType<Array<Matiere>>,
        required: true,
      },
    },
    computed: {
      afficherFormulaire(): boolean {
        return !etatsDemandeFormulaireCache.some(
          (etat) => etat === this.demande.etatDemande
        );
      },
      messageModificationImpossible(): string {
        return this.$t(
          'demande.gestion.traitement.suivi.modification-bloquee-par-etat-demande',
          { etat: this.$t(`demande.etats.${this.demande.etatDemande}`) }
        ).toString();
      },
    },
  });
</script>
