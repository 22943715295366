<template>
  <v-dialog
    v-if="erreur"
    ref="dialogue-erreur"
    :value="value"
    max-width="600px"
    @input="onInput"
  >
    <v-card class="pa-4">
      <h2>{{ $t('commun.erreur.app.titre') }}</h2>
      <p class="mt-4" v-html="$t('commun.erreur.app.indications')" />
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('commun.erreur.app.precisions-techniques') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p
              ref="dialogue-erreur__informations-navigateur"
              v-html="
                $t('commun.erreur.app.navigateur', {
                  navigateur: informationsNavigateur,
                })
              "
            />
            <v-textarea
              ref="dialogue-erreur__trace"
              outlined
              :readonly="true"
              :value="erreur.stack"
              no-resize
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="dialogue-erreur__actions">
        <v-btn @click="onFermer"> {{ $t('commun.actions.fermer') }} </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { obtenirInformationsNavigateur } from '@/commun/utils/navigateur-utils';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DialogueErreur',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      erreur: {
        type: Error,
        required: false,
        default: undefined,
      },
    },
    computed: {
      informationsNavigateur(): string {
        return obtenirInformationsNavigateur().toString();
      },
    },
    methods: {
      onFermer() {
        this.$emit('input', false);
      },
      onInput(valeur: boolean) {
        this.$emit('input', valeur);
      },
    },
  });
</script>

<style lang="scss">
  @import '~@/commun/styles/variables-layout.scss';

  .dialogue-erreur {
    &__actions {
      margin-top: $m-margin--l;
      align-items: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
</style>
