var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('header',{staticClass:"normes-ul-entete-ul"},[_c('div',{staticClass:"entete-contenu"},[_c('div',{staticClass:"or"},[_c('a',{staticClass:"logo-ul",attrs:{"href":"https://www.ulaval.ca"}},[_c('img',{staticClass:"logo-complet",attrs:{"src":require("@/components/cadre/banniere-ulaval/normes-ul/img/logo-ul.svg"),"width":"144","height":"60","alt":_vm.$t('cadre.bqp.entete.logo-ul.alt')}}),_c('img',{staticClass:"logo-mobile",attrs:{"src":require("@/components/cadre/banniere-ulaval/normes-ul/img/logo-ul-mobile.svg"),"width":"144","height":"60","alt":_vm.$t('cadre.bqp.entete.logo-ul.alt')}})])]),_c('div',{staticClass:"rouge"},[_c('div',{staticClass:"titres"},[_c('router-link',{staticClass:"surtitre",attrs:{"to":{
              name: _vm.routeAccueil,
            }}},[_vm._v(" "+_vm._s(_vm.$t('cadre.bqp.bureau-qualite-programmes'))+" "),_c('div',{staticClass:"titre"},[_vm._v(" "+_vm._s(_vm.$t('cadre.bqp.gestion-offre-formation'))+" ")])])],1),_c('div',{staticClass:"navigation"},[_c('nav',{staticClass:"navigation-generale"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
                    name: _vm.routeDeconnexion,
                  }}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.deconnexion'))+" ")])],1)])]),_vm._m(0)])])])]),_c('div',{staticClass:"normes-ul-recherche",attrs:{"id":"section-recherche","role":"search"}}),_vm._m(1),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"utilitaires"},[_c('a',{staticClass:"nav-mobile-trigger",attrs:{"id":"nav-toggle","href":"#0"}},[_c('span')])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"normes-ul-nav-mobile-wrapper"},[_c('nav',{staticClass:"nav-mobile charcoal"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"normes-ul-navigation-principale pleine-largeur"},[_c('div',[_c('ul',[_c('li')])])])
}]

export { render, staticRenderFns }