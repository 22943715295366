import Vue, { PropType } from 'vue';
import DemandeProgramme from '@/domain/models/demande-programme';

export default Vue.extend({
  name: 'SectionFormulaireProgramme',
  props: {
    value: {
      type: Object as PropType<DemandeProgramme>,
      default() {
        return {} as DemandeProgramme;
      },
    },
  },
  data: () => ({
    demandeCourante: {} as DemandeProgramme,
  }),
  watch: {
    value: {
      handler(valeur: DemandeProgramme) {
        this.demandeCourante = valeur;
      },
      deep: true,
    },
    demandeCourante: {
      handler(valeur: DemandeProgramme) {
        this.$emit('input', valeur);
      },
      deep: true,
    },
  },
  created() {
    this.demandeCourante = { ...this.value };
  },
});
