import VueI18n from 'vue-i18n';
import { DataTableHeader } from 'vuetify';

export enum DemandesTableauColonnesNom {
  Numero = 'numeroDemande',
  DateDepot = 'dateDepot',
  Session = 'codeSession',
  Type = 'typeDemande',
  Faculte = 'codeFaculte',
  CoursProgramme = 'coursProgramme',
  Etat = 'etatDemande',
  Demandeur = 'demandeur',
  Actions = 'actions',
}

export const DemandesTableauColonnes = (
  i18n: VueI18n,
  colonnesAffichees: Array<DemandesTableauColonnesNom>
): DataTableHeader[] =>
  colonnesAffichees.map((colonne) => {
    const tableHeader: DataTableHeader = {
      text: i18n.t(`demande.tableau.colonne.${colonne}`).toString(),
      value: colonne,
    };

    if (colonne === DemandesTableauColonnesNom.Actions) {
      tableHeader.align = 'end';
      tableHeader.sortable = false;
      tableHeader.filterable = false;
    }

    switch (colonne) {
      case DemandesTableauColonnesNom.Type:
        tableHeader.width = '15%';
        break;
      case DemandesTableauColonnesNom.CoursProgramme:
        tableHeader.width = '35%';
        break;
      default:
        tableHeader.width = '10%';
        break;
    }

    return tableHeader;
  });
