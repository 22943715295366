import ky, { Options } from 'ky';
import HttpWrapper from './http-wrapper';

export class KyHttpWrapper implements HttpWrapper {
  private kyInstance: typeof ky;

  constructor(kyInstance: typeof ky) {
    this.kyInstance = kyInstance;
  }

  getBlob(url: string, options?: Options): Promise<Blob> {
    return this.kyInstance.get(url, options).blob();
  }

  get<T>(url: string, options?: Options): Promise<T> {
    return this.kyInstance.get(url, options).json<T>();
  }

  post<T>(url: string, options: Options): Promise<T> {
    return this.kyInstance.post(url, options).json<T>();
  }

  patch<T>(url: string, options: Options): Promise<T> {
    return this.kyInstance.patch(url, options).json<T>();
  }

  delete<T>(url: string): Promise<T> {
    return this.kyInstance.delete(url).json<T>();
  }

  deleteString(url: string): Promise<string> {
    return this.kyInstance.delete(url).text();
  }

  extend(options: Options): void {
    this.kyInstance = this.kyInstance.extend(options);
  }
}

export default KyHttpWrapper;
