import { default as DetailsCours } from '@/commun/services/set/model/details-cours';
import Matiere from '@/commun/services/set/model/matiere';
import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';
import Session from '@/commun/services/set/model/session';
import Unite from '@/commun/services/set/model/unites';
import { ServiceEtudes } from '@/commun/services/set/services/service-etudes';
import { ExecutionMutationPayload } from '@/commun/store/execution-mutation';
import { plainToInstance } from 'class-transformer';
import ResumeDetailsProgrammes from '@/commun/services/set/model/resume-details-programmes';

const EtudesActions = (serviceEtudes: ServiceEtudes) => ({
  obtenirMatieres({ commit }: { commit: ExecutionMutationPayload }) {
    return serviceEtudes.repertoire
      .obtenirMatieres()
      .then((reponse: Matiere[]) => {
        commit('setMatieres', reponse);
      });
  },
  obtenirSessionCourante({
    commit,
  }: {
    commit: ExecutionMutationPayload;
  }): Promise<void> {
    return serviceEtudes.sessions
      .obtenirSessionCourante()
      .then((reponse: Session) => {
        commit('setSessionCourante', reponse);
      });
  },
  obtenirSessions({
    commit,
  }: {
    commit: ExecutionMutationPayload;
  }): Promise<void> {
    return serviceEtudes.sessions
      .obtenirSessions()
      .then((reponse: Session[]) => {
        commit('setSessions', reponse);
      });
  },
  obtenirUnites({
    commit,
  }: {
    commit: ExecutionMutationPayload;
  }): Promise<void> {
    return serviceEtudes.unites.obtenirUnites().then((reponse: Unite[]) => {
      commit('setUnites', reponse);
    });
  },
  obtenirDetailsCours(
    { commit }: { commit: ExecutionMutationPayload },
    idDetailsCours: string
  ): Promise<void> {
    return serviceEtudes.repertoire
      .obtenirDetailsCours(idDetailsCours)
      .then((reponse: Record<string, unknown>) => {
        const detailsCours = plainToInstance(DetailsCours, reponse);
        commit('setDetailsCours', detailsCours);
      });
  },
  supprimerDetailsCours({ commit }: { commit: ExecutionMutationPayload }) {
    return commit('setDetailsCours', undefined);
  },
  supprimerResumesDetailsCours({
    commit,
  }: {
    commit: ExecutionMutationPayload;
  }) {
    return commit('setResumeDetailsCours', []);
  },
  rechercherDetailsCours(
    { commit }: { commit: ExecutionMutationPayload },
    parametresRechercheDetailsCours: Record<string, string>
  ): Promise<void> {
    commit('setResumeDetailsCoursEnChargement', true);
    return serviceEtudes.repertoire
      .rechercherDetailsCours(parametresRechercheDetailsCours)
      .then((reponse: ResumeDetailsCours[]) => {
        commit('addResumeDetailsCours', reponse);
      })
      .finally(() => {
        commit('setResumeDetailsCoursEnChargement', false);
      });
  },
  rechercherDetailsProgrammes(
    { commit }: { commit: ExecutionMutationPayload },
    parametresRechercheDetailsProgrammes: Record<string, string>
  ): Promise<void> {
    return serviceEtudes.repertoire
      .rechercherDetailsProgrammes(parametresRechercheDetailsProgrammes)
      .then((reponse: ResumeDetailsProgrammes[]) => {
        commit('setResumeDetailsProgrammes', reponse);
      });
  },
});

export default EtudesActions;
