<template>
  <div>
    <router-view @enregistrer="creerDemande" @annuler="onAnnulerFormulaire" />
    <Spinner v-if="creationEnCours"></Spinner>
  </div>
</template>

<script lang="ts">
  import { TypesMessagesSnackbar } from '@/commun/components/snackbars/requete-snackbar';
  import Demande from '@/domain/models/demande';
  import { DemandeDocument } from '@/domain/models/demande-document';
  import Operation from '@/views/faculte/demande/cours/operations/operation';
  import { Spinner } from '@/commun/components/spinner';

  export default Operation.extend({
    name: 'CreerDemandeCours',
    components: {
      Spinner,
    },
    data: () => ({
      creationEnCours: false,
    }),
    methods: {
      async creerDemande({
        demande,
        nouveauxFichiers,
        documentExistantsASupprimer,
      }: {
        demande: Demande;
        nouveauxFichiers: File[];
        documentExistantsASupprimer: DemandeDocument[];
      }) {
        this.creationEnCours = true;
        const demandeEnregistree = await this.$store.dispatch(
          'creerDemande',
          demande
        );

        await this.$store.dispatch(
          'supprimerDemandeDocuments',
          documentExistantsASupprimer
        );

        await this.$store.dispatch('creerDemandeDocuments', {
          idDemande: demandeEnregistree.id,
          fichiers: nouveauxFichiers,
        });

        await this.notifierUtilisateur(
          this.$t('demande.creation.succes').toString(),
          TypesMessagesSnackbar.Succes
        );
        this.creationEnCours = false;
        await this.naviguerSommaire(demande.id);
      },
    },
  });
</script>
