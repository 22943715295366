<template>
  <v-expand-transition>
    <div v-if="afficher">
      <v-alert
        :class="[noMargin ? `mb-0` : '']"
        border="left"
        :type="type"
        colored-border
        elevation="2"
      >
        <p class="mb-0" v-html="message" />
      </v-alert>
    </div>
  </v-expand-transition>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'Alerte',
    props: {
      noMargin: {
        type: Boolean,
        default: false,
      },
      afficher: {
        type: Boolean,
        default: true,
      },
      message: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        default: 'warning',
      },
    },
  });
</script>
