<template>
  <select-autocomplete
    v-model="valeurInterne"
    :items="itemsSelectDemande"
    :label="$t('demande.gestion.traitement.suivi.champs.etat')"
    @input="onValueChange"
  />
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import SelectAutocomplete from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete.vue';
  import { EtatsDemande } from '@/domain/models/demande';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'EtatDemandeSelect',
    components: { SelectAutocomplete },
    mixins: [Validable],
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      valeurInterne: '',
      etatsDemandeActifs: [
        EtatsDemande.EN_TRAITEMENT,
        EtatsDemande.EN_MODIFICATION,
        EtatsDemande.COMPLETEE,
        EtatsDemande.ANNULEE,
      ],
      etatsDemandeInactifs: [EtatsDemande.EN_REDACTION, EtatsDemande.DEPOSEE],
    }),
    computed: {
      itemsEtatsActifs(): SelectAutocompleteItem[] {
        return this.obtenirItemsEtatsDemande(this.etatsDemandeActifs);
      },
      itemsEtatsInactifs(): SelectAutocompleteItem[] {
        return this.obtenirItemsEtatsDemande(this.etatsDemandeInactifs, true);
      },
      itemsSelectDemande(): SelectAutocompleteItem[] {
        return this.itemsEtatsActifs.concat(this.itemsEtatsInactifs);
      },
    },
    created() {
      this.valeurInterne = this.value;
    },
    methods: {
      obtenirItemsEtatsDemande(
        etats: string[],
        desactive = false
      ): SelectAutocompleteItem[] {
        return etats.map(
          (etat) =>
            ({
              texte: this.$t(`demande.etats.${etat}`),
              valeur: etat,
              disabled: desactive,
            } as SelectAutocompleteItem)
        );
      },
    },
  });
</script>
