<template>
  <v-autocomplete
    ref="select-autocomplete__selecteur"
    :value="value"
    :label="label"
    :items="itemsAffiches"
    item-text="texte"
    item-value="valeur"
    :disabled="disabled"
    :error-messages="errors"
    :loading="loading"
    :no-data-text="texteAucuneDonnees"
    v-bind="$attrs"
    v-on="$listeners"
    @change="onValueChange"
    @blur="onBlur"
  />
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import Vue, { PropType } from 'vue';
  import SelectAutocompleteItem from './select-autocomplete-item';

  export default Vue.extend({
    name: 'SelectAutocomplete',
    components: {},
    mixins: [Validable],
    inheritAttrs: false,
    props: {
      alphabetique: {
        type: Boolean,
        default: false,
      },
      items: {
        type: Array as PropType<Array<SelectAutocompleteItem>>,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Object],
        default: '',
      },
      setDefaultValue: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      itemsAffiches(): SelectAutocompleteItem[] {
        const itemsAffiches = this.items?.slice();
        if (this.alphabetique) {
          itemsAffiches.sort((a, b) => a.texte.localeCompare(b.texte));
        }
        return itemsAffiches;
      },
      texteAucuneDonnees(): string {
        if (this.loading) {
          return this.$t(
            'commun.components.select-autocomplete.en-chargement'
          ).toString();
        }
        return this.$t('commun.components.select-autocomplete.vide').toString();
      },
    },
    watch: {
      items(nouveauxItems: Array<SelectAutocompleteItem>): void {
        this.updateDefaultValue(nouveauxItems);
      },
    },
    created() {
      this.updateDefaultValue(this.items);
    },
    methods: {
      updateDefaultValue(items: SelectAutocompleteItem[]) {
        if (items.length === 0) {
          return;
        }

        if (this.setDefaultValue && items.length === 1) {
          this.$emit('input', items[0].valeur);
          return;
        }
      },
      onBlur() {
        const nouveauxItemsContientValeur: boolean = this.items.some(
          (item: SelectAutocompleteItem) => item.valeur === this.value
        );
        if (this.value && !nouveauxItemsContientValeur) {
          this.$emit('input', undefined);
        }
      },
    },
  });
</script>
