<template>
  <section-formulaire :titre="libelleTitreSectionIdentification">
    <champ-formulaire
      validation="requis"
      :nom="$t('demande.modification.champs.session.nom').toString()"
    >
      <select-autocomplete
        v-model="demandeCourante.codeSession"
        slot-scope="{ errors }"
        :errors="errors"
        :label="libelleSessionEntree"
        :items="itemsSessions"
      />
    </champ-formulaire>
    <champ-formulaire
      validation="requis"
      :nom="$t('demande.modification.champs.cours.nom').toString()"
    >
      <select-autocomplete
        v-model="resumeDetailCoursSelectionne"
        slot-scope="{ errors }"
        alphabetique
        :errors="
          !estModifiable
            ? [$t('validation.cours-modifiable')].concat(errors)
            : [].concat(errors)
        "
        :placeholder="
          $t('demande.modification.champs.cours.placeholder').toString()
        "
        :label="$t('demande.modification.champs.cours.nom').toString()"
        :loading="resumesDetailsCoursEnChargement"
        :items="itemsDetailsCours"
        :set-default-value="setDefaultValueCours"
        @update:search-input="onUpdateRechercheCours"
        @change="onChangeResumeDetailsCours"
      />
    </champ-formulaire>
  </section-formulaire>
</template>

<script lang="ts">
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import { SectionFormulaire } from '@/commun/components/champ-formulaire/section-formulaire';
  import { SelectAutocomplete } from '@/commun/components/champ-formulaire/select-autocomplete';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';
  import DetailsCours from '@/commun/services/set/model/resume-details-cours';
  import Session from '@/commun/services/set/model/session';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { TypesDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import Vue, { PropType } from 'vue';
  import { IdentiteRoles } from '@/domain/models/identite';

  const NombreCaracteresSigleMatiere = 3;

  export default Vue.extend({
    name: 'SectionIdentificationCours',
    components: {
      ChampFormulaire,
      SectionFormulaire,
      SelectAutocomplete,
    },
    mixins: [SectionFormulaireCours],
    props: {
      detailCours: {
        type: Object as PropType<DetailsCours>,
        default: undefined,
      },
      estModifiable: {
        type: Boolean,
        default: true,
      },
      sessions: {
        type: Array as PropType<Array<Session>>,
        required: true,
      },
      typeDemande: {
        type: String,
        default: TypesDemande.MODIFICATION_COURS,
        validator(valeur: string) {
          return (Object.values(TypesDemande) as string[]).includes(valeur);
        },
      },
      coursActifs: {
        type: Boolean,
        default: true,
      },
      setDefaultValueCours: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      demandeCourante: {} as DemandeCours,
      resumeDetailCoursSelectionne: undefined as ResumeDetailsCours | undefined,
      rechercheEnCours: false,
    }),
    computed: {
      libelleTitreSectionIdentification(): string {
        let titreSectionIdentification = '';
        switch (this.typeDemande) {
          case TypesDemande.REACTIVATION_COURS:
            titreSectionIdentification = this.$t(
              'demande.modification.formulaire.section.cours-reactivation'
            ).toString();
            break;
          case TypesDemande.MODIFICATION_COURS:
            titreSectionIdentification = this.$t(
              'demande.modification.formulaire.section.cours-modifie'
            ).toString();
            break;
          case TypesDemande.DESACTIVATION_COURS:
            titreSectionIdentification = this.$t(
              'demande.modification.formulaire.section.cours-desactive'
            ).toString();
        }
        return titreSectionIdentification;
      },
      libelleSessionEntree(): string {
        let precision = '';
        switch (this.typeDemande) {
          case TypesDemande.MODIFICATION_COURS:
            precision = this.$t(
              'demande.modification.champs.session.precision.modification'
            ).toString();
            break;
          case TypesDemande.DESACTIVATION_COURS:
            precision = this.$t(
              'demande.modification.champs.session.precision.desactivation'
            ).toString();
            break;
          case TypesDemande.REACTIVATION_COURS:
            precision = this.$t(
              'demande.modification.champs.session.precision.reactivation'
            ).toString();
        }
        return this.$t('demande.modification.champs.session.nom', {
          precision,
        }).toString();
      },
      itemsSessions(): SelectAutocompleteItem[] {
        return this.sessions.map(
          (session: Session) =>
            ({
              texte: `${session.codeSession} - ${session.description}`,
              valeur: session.codeSession,
            }) as SelectAutocompleteItem
        );
      },
      sessionCourante(): Session {
        return this.$store.getters.sessionCourante;
      },
      itemsDetailsCours(): SelectAutocompleteItem[] {
        return this.resumesDetailsCours.map(
          (resumeDetailsCours: ResumeDetailsCours) =>
            ({
              texte: `${resumeDetailsCours.sigleMatiere}-${resumeDetailsCours.numeroCours} ${resumeDetailsCours.titreCours}`,
              valeur: resumeDetailsCours,
            }) as SelectAutocompleteItem
        );
      },
      resumesDetailsCours(): ResumeDetailsCours[] {
        return this.$store.getters.resumeDetailsCours;
      },
      resumesDetailsCoursEnChargement(): boolean {
        return this.$store.state.Etudes.resumeDetailsCoursEnChargement;
      },
      roles(): IdentiteRoles[] {
        return this.$store.getters.roles;
      },
      facultesRattachement(): string[] {
        return this.$store.state.GestionOffreFormation.identite
          .facultesRattachement;
      },
    },
    watch: {
      resumeDetailCoursSelectionne: {
        immediate: true,
        handler(valeur: ResumeDetailsCours) {
          if (!valeur) {
            return;
          }
          this.demandeCourante.sigleCours = valeur.sigleMatiere;
          this.demandeCourante.numeroCours = valeur.numeroCours;
        },
      },
      detailCours: {
        immediate: true,
        async handler(valeur: DetailsCours) {
          if (valeur) {
            await this.rechercherDetailsCours(
              this.detailCours.sigleMatiere,
              this.detailCours.codeSession
            );
            const detailCours: DetailsCours = this.detailCours;
            this.resumeDetailCoursSelectionne = this.resumesDetailsCours.find(
              (resume: ResumeDetailsCours) => {
                return resume.idDetailsCours === detailCours.idDetailsCours;
              }
            );
          }
        },
      },
    },

    methods: {
      onUpdateRechercheCours(payload: string) {
        if (payload && payload.length === NombreCaracteresSigleMatiere) {
          this.rechercherDetailsCours(
            payload,
            this.demandeCourante.codeSession || this.sessionCourante.codeSession
          );
        }
      },
      async rechercherDetailsCours(
        sigle: string,
        codeSession: string
      ): Promise<any> {
        if (this.rechercheEnCours) return;

        this.rechercheEnCours = true;

        this.$store.commit('setResumeDetailsCours', []);

        if (this.roles.includes(IdentiteRoles.GESTIONNAIRE)) {
          return await this.$store
            .dispatch('rechercherDetailsCours', {
              codesession: codeSession,
              siglematiere: sigle.toUpperCase(),
              format: 'resume',
              actifs: this.coursActifs,
            })
            .then(() => (this.rechercheEnCours = false));
        } else {
          const promises: Promise<any>[] = [];

          for (const faculte of this.facultesRattachement) {
            promises.push(
              this.$store.dispatch('rechercherDetailsCours', {
                codesession: codeSession,
                siglematiere: sigle.toUpperCase(),
                format: 'resume',
                actifs: this.coursActifs,
                codefaculteresponsable: faculte,
              })
            );
          }
          return await Promise.all(promises).then(
            () => (this.rechercheEnCours = false)
          );
        }
      },
      async onChangeResumeDetailsCours(
        payload: ResumeDetailsCours
      ): Promise<void> {
        if (payload) {
          await this.$store.dispatch(
            'obtenirDetailsCours',
            payload.idDetailsCours
          );
        }
      },
    },
  });
</script>
