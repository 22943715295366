<template>
  <v-menu
    v-model="menuAffichee"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <champ-formulaire :validation="validation">
        <v-text-field
          v-model="date"
          slot-scope="{ errors }"
          :error-messages="errors"
          clearable
          readonly
          :label="label"
          append-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
          @change="onChangerDate"
        ></v-text-field>
      </champ-formulaire>
    </template>
    <v-date-picker
      v-model="date"
      min="1950-01-01"
      @change="onChangerDate"
    ></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
  import Vue from 'vue';
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';

  export default Vue.extend({
    name: 'DatePicker',
    components: {
      ChampFormulaire,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      validation: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        menuAffichee: false,
        date: this.value,
      };
    },
    methods: {
      onChangerDate(valeur: string): void {
        this.$emit('input', valeur);
        this.menuAffichee = false;
      },
    },
  });
</script>
