<template>
  <v-text-field
    class="ma-0 pa-0"
    :value="value"
    :error-messages="errors"
    :label="label"
    :placeholder="
      $t(
        'demande.formulaires.champs.courriel-approbateur-departement.placeholder'
      )
    "
    v-bind="$attrs"
    v-on="$listeners"
    @change="onValueChange"
  />
</template>

<script lang="ts">
  import { Validable } from '@/commun/components/champ-formulaire/mixins/validable';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'CourrielTextfield',
    mixins: [Validable],
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },
  });
</script>
