export enum ChampsInformationBase {
  Faculte,
  Session,
  Titre,
  Sigle,
  Numero,
  UniteResponsable,
  CourrielDirecteur,
  CourrielResponsableFacultaire,
}
