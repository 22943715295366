<template>
  <div>
    <template v-for="(section, sectionIdx) in sectionsVisibles">
      <v-divider
        v-if="doitAjouterLigneAvantSection(sectionIdx)"
        :key="sectionIdx + 'divider-avant'"
      />
      <v-expansion-panels
        v-if="section.titre"
        :key="sectionIdx + 'sections'"
        :value="sectionsOuvertes"
        :flat="true"
        multiple
        class="consultation-demande-section"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            style="padding-left: 16px"
            class="text-left consultation-demande-section__titre"
          >
            <span>
              {{ section.titre }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table class="consultation-demande-liste">
              <template #default>
                <thead>
                  <div>
                    <v-btn
                      v-if="section.estSectionDocument"
                      :loading="telechargementEnCours"
                      @click="onToutTelecharger"
                    >
                      {{
                        $t(
                          'demande.programme.documents-joints.tout-telecharger'
                        )
                      }}
                      <template #loader>
                        <v-progress-circular
                          :value="pourcentageTelechargement"
                          color="primary"
                          width="2"
                          style="font-size: 12px"
                          >{{ pourcentageTelechargement }}%</v-progress-circular
                        >
                      </template>
                    </v-btn>
                  </div>
                </thead>
                <thead>
                  <tr>
                    <th
                      v-for="(header, headerIdx) in section.headers"
                      :key="headerIdx + 'header'"
                    >
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(ligne, ligneIdx) in section.lignes">
                    <consultation-demande-tableau-ligne
                      v-if="!ligne.cachee"
                      :key="ligneIdx"
                      :ligne="ligne"
                    />
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-simple-table
        v-else
        :key="sectionIdx + 'sections'"
        class="consultation-demande-liste"
      >
        <template #default>
          <thead>
            <tr>
              <th
                v-for="(header, headerIdx) in section.headers"
                :key="headerIdx + 'header'"
                class="text-left"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(ligne, ligneIdx) in section.lignes">
              <consultation-demande-tableau-ligne
                v-if="!ligne.cachee"
                :key="ligneIdx"
                :ligne="ligne"
                :section-expension="false"
              />
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>

<script lang="ts">
  import Faculte from '@/commun/services/set/model/faculte';
  import Unite from '@/commun/services/set/model/unites';
  import ConsultationDemandeTableauLigne from '@/components/faculte/demandes/consultation/ligne/consultation-demande-tableau-ligne.vue';
  import DemandeCours from '@/domain/models/demande-cours';
  import { DemandeDocument } from '@/domain/models/demande-document';
  import Individu from '@/domain/models/individu';
  import Vue, { PropType } from 'vue';
  import demandeAConsultation from './demande-a-consultation';
  import DemandeProgramme from '@/domain/models/demande-programme';
  import { ConsultationDemande, ConsultationDemandeSection } from '.';
  import { enregistrerOuOuvrirFichier } from '@/commun/utils/file-utils';
  import JSZip from 'jszip';
  import FileSaver from 'file-saver';
  import Demande from '@/domain/models/demande';

  export default Vue.extend({
    name: 'ConsultationDemandeListe',
    components: { ConsultationDemandeTableauLigne },
    props: {
      demande: {
        type: Object as PropType<DemandeCours | DemandeProgramme>,
        required: true,
      },
      individuCreation: {
        type: Object as PropType<Individu>,
        required: true,
      },
      individuModification: {
        type: Object as PropType<Individu>,
        required: true,
      },
      facultes: {
        type: Array as PropType<Array<Faculte>>,
        required: true,
      },
      unites: {
        type: Array as PropType<Array<Unite>>,
        required: true,
      },
    },
    data() {
      return {
        telechargementEnCours: false,
        pourcentageTelechargement: 0,
      };
    },
    computed: {
      sectionsVisibles(): ConsultationDemandeSection[] {
        return this.consulationDemande.sections.filter((s) => !s.cachee);
      },
      consulationDemande(): ConsultationDemande {
        return demandeAConsultation(
          this.$i18n,
          this.demande,
          this.demandeDocumentsAvecActionLien,
          this.individuModification,
          this.individuCreation,
          this.unites.find(
            (unite: Unite) =>
              unite.codeUnitePedagogique ===
              (this.demande as DemandeCours).uniteResponsable
          ),
          this.facultes.find(
            (faculte: Faculte) =>
              faculte.codeUnitePedagogique ===
              this.demande.codeFaculte?.toString()
          )
        );
      },
      sectionsOuvertes(): number[] {
        return this.consulationDemande?.sections
          .filter((s: any) => !s.cachee && !!s.titre)
          .map((_: unknown, i: number) => i);
      },
      demandeDocumentsAvecActionLien(): (DemandeDocument & {
        callback: () => void;
      })[] {
        return this.$store.getters.demandeCouranteDocumentsTrieesParDate.map(
          (d: DemandeDocument) => ({
            ...d,
            ...{
              callback: async () => {
                const blob = await this.$store.dispatch(
                  'telechargerDemandeDocumentFichier',
                  {
                    idDemande: d.idDemande,
                    idDocument: d.idDocument,
                  }
                );
                enregistrerOuOuvrirFichier(blob, d.nomFichier);
              },
            },
          })
        );
      },
    },
    created(): void {
      this.$store.dispatch('obtenirDemandeCouranteDocuments', this.demande.id);
    },
    methods: {
      async onToutTelecharger(): Promise<void> {
        this.telechargementEnCours = true;
        this.pourcentageTelechargement = 0;
        const degreeAvancement = Math.floor(
          98 /
            this.$store.state.GestionOffreFormation.demandeCouranteDocuments
              .length
        );
        const nomDossierZip = `Demande_${
          (this.demande as Demande).numeroDemande
        }`;
        const zip = new JSZip();

        for (const d of this.$store.state.GestionOffreFormation
          .demandeCouranteDocuments) {
          const blob = await this.$store.dispatch(
            'telechargerDemandeDocumentFichier',
            {
              idDemande: d.idDemande,
              idDocument: d.idDocument,
            }
          );
          await zip.folder(nomDossierZip)?.file(d.nomFichier, blob);
          this.pourcentageTelechargement += degreeAvancement;
        }
        this.pourcentageTelechargement = 98;
        await zip.generateAsync({ type: 'blob' }).then((content) => {
          FileSaver.saveAs(content, `${nomDossierZip}.zip`);
        });
        this.telechargementEnCours = false;
      },

      doitAjouterLigneAvantSection(idx: number): boolean {
        if (!idx) return false;

        return (
          !!this.sectionsVisibles[idx]?.titre ||
          (!this.sectionsVisibles[idx].titre &&
            !!this.sectionsVisibles[idx - 1].titre)
        );
      },
    },
  });
</script>

<style lang="scss">
  @import '~@/commun/styles/variables-layout.scss';

  .consultation-demande-section {
    &__titre {
      padding-left: 1rem;
    }
  }

  .consultation-demande-liste table {
    border-top: 0;
    width: 100%;
  }

  .consultation-demande-liste .v-data-table__wrapper {
    width: 100%;
  }
</style>
