import HttpWrapper from '@/commun/http/http-wrapper';
import ServiceEtudesContainer from '@/setup/services-etudes-container';
import ServicesGestionOffreFormationContainer from '@/setup/services-gestion-offre-formation-container';
import { Container } from 'inversify';

export const creerContainerGestionOffreFormation = (
  clientHttpGestionOffreFormation: HttpWrapper,
  clientHttpServiceEtudes: HttpWrapper
): Container => {
  const container: Container = new Container();
  ServicesGestionOffreFormationContainer.enregistrerServices(
    clientHttpGestionOffreFormation,
    container
  );
  ServiceEtudesContainer.enregistrerServices(
    clientHttpServiceEtudes,
    container
  );
  return container;
};
