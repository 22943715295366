var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('validation-provider',{attrs:{"custom-messages":_vm.messageErreurChargeTravail,"rules":`oneOf:0,${_vm.totalHeuresValides}`,"nom":_vm.$t('demande.formulaires.section.charge-de-travail'),"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('div',{staticClass:"d-flex flex-row align-center mt-4"},[_c('h4',{staticClass:"text-h6 mr-1"},[_vm._v(" "+_vm._s(_vm.$t('demande.formulaires.section.charge-de-travail'))+" ")]),_c('tooltip',{attrs:{"messages":[
        _vm.$t('demande.formulaires.champs.charge-de-travail.infobulle'),
      ]}})],1),_c('v-input',{staticClass:"d-flex",attrs:{"value":_vm.totalHeures,"errors":errors}},[_c('div',[_c('champ-formulaire',[_c('v-row',[_c('v-col',{attrs:{"xl":"10","lg":"10","md":"8","sm":"7"}},[_c('v-label',{staticClass:"theme--light text--lighten-2"},[_vm._v(_vm._s(_vm.$t( 'demande.formulaires.champs.charge-de-travail.cours-magistraux' )))])],1),_c('v-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"5"}},[_c('stepper-nombre',{attrs:{"precision":3,"min":0,"max":999.999,"nom":_vm.$t(
                  'demande.formulaires.champs.charge-de-travail.cours-magistraux'
                )},model:{value:(_vm.demandeCourante.heuresMagistrales),callback:function ($$v) {_vm.$set(_vm.demandeCourante, "heuresMagistrales", $$v)},expression:"demandeCourante.heuresMagistrales"}})],1)],1)],1),_c('champ-formulaire',[_c('v-row',[_c('v-col',{attrs:{"xl":"10","lg":"10","md":"8","sm":"7"}},[_c('v-label',{staticClass:"theme--light text--lighten-2"},[_vm._v(_vm._s(_vm.$t( 'demande.formulaires.champs.charge-de-travail.travaux-pratiques' )))])],1),_c('v-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"5"}},[_c('stepper-nombre',{attrs:{"precision":3,"min":0,"max":999.999,"nom":_vm.$t(
                  'demande.formulaires.champs.charge-de-travail.travaux-pratiques'
                )},model:{value:(_vm.demandeCourante.heuresTravaux),callback:function ($$v) {_vm.$set(_vm.demandeCourante, "heuresTravaux", $$v)},expression:"demandeCourante.heuresTravaux"}})],1)],1)],1),_c('champ-formulaire',[_c('v-row',[_c('v-col',{attrs:{"xl":"10","lg":"10","md":"8","sm":"7"}},[_c('v-label',{staticClass:"theme--light text--lighten-2"},[_vm._v(_vm._s(_vm.$t( 'demande.formulaires.champs.charge-de-travail.travaux-personnels' )))])],1),_c('v-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"5"}},[_c('stepper-nombre',{attrs:{"precision":3,"min":0,"max":999.999,"nom":_vm.$t(
                  'demande.formulaires.champs.charge-de-travail.travaux-personnels'
                )},model:{value:(_vm.demandeCourante.heuresPersonnelles),callback:function ($$v) {_vm.$set(_vm.demandeCourante, "heuresPersonnelles", $$v)},expression:"demandeCourante.heuresPersonnelles"}})],1)],1)],1),_c('hr'),_c('br'),_c('v-row',[_c('v-col',{class:`v-messages-total theme--light ${
            errors[0] ? 'error--text' : ''
          } `,attrs:{"xl":"10","lg":"10","md":"8","sm":"7 ","role":"alert"}},[_vm._v(" "+_vm._s(errors[0])+" ")]),_c('v-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"5"}},[_vm._v(" "+_vm._s(_vm.$t('demande.formulaires.total', { totalHeures: _vm.totalHeures, totalHeuresValides: _vm.totalHeuresValides, }))+" ")])],1)],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }