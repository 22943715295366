<template>
  <v-container>
    <h1>Not Found</h1>
  </v-container>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'NotFound',
  });
</script>
