<template>
  <v-btn @click="onClickCopierDemande">
    {{ $t('demande.gestion.traitement.copier.titre') }}
  </v-btn>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { ConsultationDemande } from '../../../faculte/demandes/consultation';

  export default Vue.extend({
    name: 'BoutonCopierDemandeTraitement',
    props: {
      consultationDemande: {
        type: Object as PropType<ConsultationDemande>,
        required: true,
      },
    },
    methods: {
      async onClickCopierDemande() {
        const container = document.createElement('div');
        container.id = 'container';
        document.body.appendChild(container);

        this.consultationDemande.sections
          .filter((s) => s.ordrePressePaper !== -1)
          .sort((a, b) => {
            if ((a.ordrePressePaper ?? 0) > (b.ordrePressePaper ?? 0)) return 1;
            if ((a.ordrePressePaper ?? 0) < (b.ordrePressePaper ?? 0))
              return -1;
            return 0;
          })
          .forEach((s, i) => {
            if (s.cachee) return;

            if (i !== 0) {
              const span = document.createElement('div');

              span.innerText = '\n';

              container.append(span);
            }

            if (s.titre) {
              const span = document.createElement('span');

              span.innerText = '------ ' + s.titre.toString() + ' ------';

              container.append(span);
            }

            s.lignes
              .sort((a, b) => {
                if ((a.ordrePressePaper ?? 0) > (b.ordrePressePaper ?? 0))
                  return 1;
                if ((a.ordrePressePaper ?? 0) < (b.ordrePressePaper ?? 0))
                  return -1;
                return 0;
              })
              .forEach((l, idx) => {
                if (
                  (l.cachee && !l.afficherCopieDemande) ||
                  typeof l.valeur === 'undefined' ||
                  l.valeur === ''
                ) {
                  return;
                }
                const div = document.createElement('div');
                const bold = document.createElement('b');
                const span = document.createElement('span');
                const br = document.createElement('br');

                bold.innerText = l.nom + ' : ';

                span.innerText =
                  l.valeur === false
                    ? 'Non'
                    : l.valeur === true
                    ? 'Oui'
                    : (l.valeur as string).toString();

                if (idx) {
                  div.append(br);
                }
                div.append(bold);
                div.append(span);
                container.append(div);
              });
          });

        const blobHtml = new Blob([container.innerHTML], { type: 'text/html' });
        const blobPlain = new Blob([container.innerText], {
          type: 'text/plain',
        });

        const { ClipboardItem } = window;
        await navigator.clipboard.write([
          new ClipboardItem({
            [blobHtml.type]: blobHtml,
            [blobPlain.type]: blobPlain,
          }),
        ]);

        document.body.removeChild(container);
      },
    },
  });
</script>
