<template>
  <v-alert
    v-if="afficher"
    border="left"
    colored-border
    elevation="1"
    type="warning"
  >
    <div>
      {{ $tc('demande.creation.procedures.avertissement', nombreProcedures) }}
      <ul>
        <li v-if="estEchelleReussiteEchec" ref="messageEchelleNotation">
          {{ texteReussiteEchec }}
        </li>
        <li v-if="estLangueAutre" ref="messageLangueAutre">
          {{ texteLangueAutre }}
        </li>
      </ul>
      <br />
      <span v-html="texteLien" />
    </div>
  </v-alert>
</template>

<script lang="ts">
  import { EchelleNotation } from '@/components/faculte/demandes/formulaires/champs/echelle-notation/echelle-notation';
  import DemandeCours from '@/domain/models/demande-cours';
  import { LangueEnseignement } from '@/domain/models/langue-enseignement';
  import Vue, { PropType } from 'vue';

  const URL_Procedures_Obligatoires_Creation_Cours =
    'https://www.bqp.ulaval.ca/fichiers/amelioration/fiches-cours/echelle-notation.pdf';

  export default Vue.extend({
    name: 'ProceduresObligatoiresCreationCours',
    inheritAttrs: false,
    props: {
      demandeCours: {
        type: Object as PropType<DemandeCours>,
        required: true,
      },
    },
    computed: {
      estEchelleReussiteEchec(): boolean {
        return (
          this.demandeCours.echelleNotation === EchelleNotation.REUSSITE_ECHEC
        );
      },
      estLangueAutre(): boolean {
        return (
          this.demandeCours.langueEnseignement === LangueEnseignement.AUTRES
        );
      },
      afficher(): boolean {
        return this.estEchelleReussiteEchec || this.estLangueAutre;
      },
      nombreProcedures(): number {
        let nombreProcedures = 0;
        nombreProcedures += this.estEchelleReussiteEchec ? 1 : 0;
        nombreProcedures += this.estLangueAutre ? 1 : 0;
        return nombreProcedures;
      },
      texteReussiteEchec(): string {
        return this.$t(
          'demande.creation.procedures.demande-derogation'
        ).toString();
      },
      texteLangueAutre(): string {
        return this.$t(
          'demande.creation.procedures.cours-langue-autre-francais'
        ).toString();
      },
      texteLien(): string {
        return this.$t('demande.creation.procedures.lien', {
          url: URL_Procedures_Obligatoires_Creation_Cours,
        }).toString();
      },
    },
  });
</script>
