import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';

export function resumeDetailsCoursMock(): ResumeDetailsCours[] {
  return [
    {
      sigleMatiere: 'IFT',
      codeSession: '202106',
      idCours: 'IFT-1000',
      idDetailsCours: 'IFT-1000-202106',
      numeroCours: '1800',
      titreCours: 'Logique et techniques de preuve',
    } as ResumeDetailsCours,
    {
      sigleMatiere: 'IFT',
      codeSession: '202106',
      idCours: 'IFT-3000',
      idDetailsCours: 'IFT-3000-202106',
      numeroCours: '1900',
      titreCours: 'Langages de programmation',
    } as ResumeDetailsCours,
  ];
}
