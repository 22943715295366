<template>
  <table class="entete-gestion-traitement pa-4">
    <tbody>
      <tr>
        <td class="text-h5">
          {{ demande.numeroDemande }} -
          {{ $t(`demande.types.${demande.typeDemande}`) }}
        </td>
        <td class="text-h5">
          {{ demande.cibleDemande.identifiantCible }}
        </td>
      </tr>
      <tr>
        <td>
          {{
            $t(`demande.gestion.traitement.entete.etat`, [
              $t(`demande.etats.${demande.etatDemande}`),
            ])
          }}
        </td>
        <td>
          {{
            $t('demande.gestion.traitement.entete.faculte', [
              demande.cibleDemande.faculteResponsableCible,
            ])
          }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import Demande from '@/domain/models/demande';

  export default Vue.extend({
    name: 'EnteteGestionTraitement',
    props: {
      demande: {
        type: Object as PropType<Demande>,
        required: true,
      },
    },
  });
</script>
<style lang="scss">
  @import '~@/commun/styles/variables-layout.scss';

  .entete-gestion-traitement {
    width: 100%;
  }
</style>
