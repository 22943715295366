import { DateUtils } from '@/commun/utils/date-utils';
import { DemandeDocument } from '@/domain/models/demande-document';
import DemandeProgramme, {
  DemandeProgrammeTypeModification,
} from '@/domain/models/demande-programme';
import Individu from '@/domain/models/individu';
import VueI18n from 'vue-i18n';
import { ConsultationDemande } from '.';
import Demande from '@/domain/models/demande';

const demandeProgrammeAConsultation = (
  i18n: VueI18n,
  demande: DemandeProgramme,
  demandeDocuments: (DemandeDocument & { callback: () => void })[],
  individuModification: Individu,
  individuCreation: Individu
): ConsultationDemande => {
  return {
    sections: [
      {
        titre: '',
        ordrePressePaper: 1,
        headers: [],
        lignes: [
          {
            nom: i18n.t('demande.consultation.champ.numero'),
            valeur: demande.numeroDemande,
          },
          {
            nom: i18n.t(`demande.consultation.champ.type`),
            valeur: i18n.t(`demande.types.${demande.typeDemande}`),
          },
          {
            nom: i18n.t(
              'demande.consultation.programme.champ.type-modification'
            ),
            valeur: i18n.t(
              `demande.type-modifications.${demande.typeModification}`
            ),
          },
          {
            nom: i18n.t('demande.consultation.champ.etat'),
            valeur: i18n.t(`demande.etats.${demande.etatDemande}`),
          },
          {
            cachee: true,
            afficherCopieDemande: true,
            nom: i18n.t('demande.consultation.champ.faculte-demandeur'),
            valeur: (demande as Demande).cibleDemande?.faculteResponsableCible,
          },
          {
            nom: i18n.t('demande.consultation.programme.champ.programme'),
            valeur: demande.codeProgramme,
          },
          {
            cachee: !demande.codeMajeure,
            nom: i18n.t('demande.consultation.programme.champ.code-majeure'),
            valeur: demande.codeMajeure,
          },
          {
            cachee: !demande.titreMajeure,
            nom: i18n.t('demande.consultation.programme.champ.titre-majeure'),
            valeur: demande.titreMajeure,
          },
          {
            nom: i18n.t('demande.consultation.champ.session'),
            valeur: demande.codeSession,
          },
        ],
      },
      {
        ordrePressePaper: 2,
        cachee:
          demande.typeModification ===
            DemandeProgrammeTypeModification.MAJEURE_NOUVELLE ||
          (!demande.indicateursModificationProgramme?.admissionExigences &&
            !demande.indicateursModificationProgramme?.admissionSessions &&
            !demande.indicateursModificationProgramme?.admissionSuspension),
        titre: i18n.t('demande.programme.modification-majeure.admission.titre'),
        headers: [],
        lignes: [
          {
            cachee:
              !demande.indicateursModificationProgramme?.admissionSessions,
            nom: i18n.t(
              'demande.programme.modification-majeure.admission.admission-sessions'
            ),
            valeur: i18n.t('commun.oui'),
          },
          {
            cachee:
              !demande.indicateursModificationProgramme?.admissionExigences,
            nom: i18n.t(
              'demande.programme.modification-majeure.admission.admission-exigences'
            ),
            valeur: i18n.t('commun.oui'),
          },
          {
            cachee:
              !demande.indicateursModificationProgramme?.admissionSuspension,
            nom: i18n.t(
              'demande.programme.modification-majeure.admission.admission-suspension'
            ),
            valeur: i18n.t('commun.oui'),
          },
        ],
      },
      {
        ordrePressePaper: 3,
        cachee:
          demande.typeModification ===
            DemandeProgrammeTypeModification.MAJEURE_NOUVELLE ||
          (!demande.indicateursModificationProgramme
            ?.descriptionOfficielleAutres &&
            !demande.indicateursModificationProgramme
              ?.descriptionOfficielleOrientations),
        titre: i18n.t(
          'demande.programme.modification-majeure.description.titre'
        ),
        headers: [],
        lignes: [
          {
            cachee:
              !demande.indicateursModificationProgramme
                ?.descriptionOfficielleOrientations,
            nom: i18n.t(
              'demande.programme.modification-majeure.description.description-officielle-orientations'
            ),
            valeur: i18n.t('commun.oui'),
          },
          {
            cachee:
              !demande.indicateursModificationProgramme
                ?.descriptionOfficielleAutres,
            nom: i18n.t(
              'demande.programme.modification-majeure.description.description-officielle-autres'
            ),
            valeur: i18n.t('commun.oui'),
          },
        ],
      },
      {
        ordrePressePaper: 4,
        cachee:
          demande.typeModification ===
            DemandeProgrammeTypeModification.MAJEURE_NOUVELLE ||
          (!demande.indicateursModificationProgramme?.composantesMineures &&
            !demande.indicateursModificationProgramme
              ?.composantesConcentrations),
        titre: i18n.t(
          'demande.programme.modification-majeure.composantes.titre'
        ),
        headers: [],
        lignes: [
          {
            cachee:
              !demande.indicateursModificationProgramme
                ?.composantesConcentrations,
            nom: i18n.t(
              'demande.programme.modification-majeure.composantes.composantes-concentrations'
            ),
            valeur: i18n.t('commun.oui'),
          },
          {
            cachee:
              !demande.indicateursModificationProgramme?.composantesMineures,
            nom: i18n.t(
              'demande.programme.modification-majeure.composantes.composantes-mineures'
            ),
            valeur: i18n.t('commun.oui'),
          },
        ],
      },
      {
        ordrePressePaper: 5,
        cachee:
          demande.typeModification ===
            DemandeProgrammeTypeModification.MAJEURE_NOUVELLE ||
          (!demande.indicateursModificationProgramme
            ?.nombreCreditsAugmentation &&
            !demande.indicateursModificationProgramme?.nombreCreditsReduction),
        titre: i18n.t('demande.programme.modification-majeure.credits.titre'),
        headers: [],
        lignes: [
          {
            cachee:
              !demande.indicateursModificationProgramme
                ?.nombreCreditsAugmentation,
            nom: i18n.t(
              'demande.programme.modification-majeure.credits.nombre-credits-augmentation'
            ),
            valeur: i18n.t('commun.oui'),
          },
          {
            cachee:
              !demande.indicateursModificationProgramme?.nombreCreditsReduction,
            nom: i18n.t(
              'demande.programme.modification-majeure.credits.nombre-credits-reduction'
            ),
            valeur: i18n.t('commun.oui'),
          },
        ],
      },
      {
        ordrePressePaper: 6,
        cachee:
          demande.typeModification ===
            DemandeProgrammeTypeModification.MAJEURE_NOUVELLE ||
          (!demande.indicateursModificationProgramme?.structureCours &&
            !demande.indicateursModificationProgramme
              ?.structureRepartitionCredits),
        titre: i18n.t('demande.programme.modification-majeure.structure.titre'),
        headers: [],
        lignes: [
          {
            cachee:
              !demande.indicateursModificationProgramme
                ?.structureRepartitionCredits,
            nom: i18n.t(
              'demande.programme.modification-majeure.structure.structure-repartition-credits'
            ),
            valeur: i18n.t('commun.oui'),
          },
          {
            cachee: !demande.indicateursModificationProgramme?.structureCours,
            nom: i18n.t(
              'demande.programme.modification-majeure.structure.structure-cours'
            ),
            valeur: i18n.t('commun.oui'),
          },
        ],
      },
      {
        ordrePressePaper: 7,
        cachee: false,
        titre: i18n.t('demande.programme.approbations.titre'),
        headers: [],
        lignes: [
          {
            nom: i18n.t('demande.programme.approbations.date-programme'),
            valeur: DateUtils.formaterIntlDateTime(
              demande.dateSeanceComiteProgramme,
              i18n.locale
            ),
          },
          {
            nom: i18n.t('demande.programme.approbations.courriel-facultaire'),
            valeur: demande.courrielApprobateurFacultaire,
          },
          {
            nom: i18n.t('demande.programme.approbations.date-facultaire'),
            valeur: DateUtils.formaterIntlDateTime(
              demande.dateSeanceConseilFacultaire,
              i18n.locale
            ),
          },
        ],
      },
      {
        ordrePressePaper: 8,
        cachee: false,
        titre: '',
        headers: [],
        lignes: [
          {
            nom: i18n.t('demande.consultation.programme.champ.justification'),
            valeur: demande.justification,
          },
        ],
      },
      {
        ordrePressePaper: 9,
        titre: i18n.t('demande.programme.demandeur.titre'),
        headers: [],
        lignes: [
          {
            nom: i18n.t('demande.programme.demandeur.nom'),
            valeur: individuCreation
              ? `${individuCreation.nom}, ${individuCreation.prenom}`
              : '',
          },
          {
            nom: i18n.t('demande.consultation.champ.poste-telephonique'),
            valeur: individuCreation ? individuCreation.posteTelephonique : '',
          },
          {
            nom: i18n.t('demande.consultation.champ.courriel'),
            valeur: individuCreation ? individuCreation.courriel : '',
          },
          {
            nom: i18n.t('demande.consultation.champ.date-creation'),
            valeur: DateUtils.formaterIntlDateTime(
              demande.audit?.horodateCreation,
              i18n.locale
            ),
          },
        ],
      },
      {
        ordrePressePaper: 10,
        cachee: false,
        titre: '',
        headers: [],
        lignes: [
          {
            nom: i18n.t('demande.consultation.champ.date-depot'),
            valeur: DateUtils.formaterIntlDateTime(
              demande.dateDepot,
              i18n.locale
            ),
          },
          {
            nom: i18n.t('demande.consultation.champ.commentaires'),
            valeur: demande.commentaireGestionnaire,
          },
        ],
      },
      {
        ordrePressePaper: 11,
        titre: i18n.t('demande.programme.dernier-intervenant.titre'),
        headers: [],
        lignes: [
          {
            nom: i18n.t('demande.programme.dernier-intervenant.nom'),
            valeur: individuModification
              ? `${individuModification.nom}, ${individuModification.prenom}`
              : '',
          },
          {
            nom: i18n.t(
              'demande.consultation.champ.poste-telephonique-dernier-intervenant'
            ),
            valeur: individuModification
              ? individuModification.posteTelephonique
              : '',
          },
          {
            nom: i18n.t(
              'demande.consultation.champ.courriel-dernier-intervenant'
            ),
            valeur: individuModification ? individuModification.courriel : '',
          },
          {
            nom: i18n.t(
              'demande.consultation.champ.date-derniere-modification'
            ),
            valeur: DateUtils.formaterIntlDateTime(
              demande.audit?.horodateModification,
              i18n.locale
            ),
          },
        ],
      },
      {
        cachee: !demandeDocuments.length,
        estSectionDocument: true,
        titre: i18n.t('demande.programme.documents-joints.titre'),
        headers: [
          i18n
            .t('demande.programme.documents-joints.colonne-fichiers')
            .toString(),
          i18n.t('demande.programme.documents-joints.colonne-date').toString(),
        ],
        lignes: demandeDocuments.map((dd) => ({
          nom: dd.nomFichier,
          valeur: DateUtils.formaterIntlDateTime(dd.horodateDepot, i18n.locale),
          type: 'lien',
          lienCallback: dd.callback,
        })),
      },
    ],
  };
};

export default demandeProgrammeAConsultation;
