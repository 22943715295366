<template>
  <v-menu key="text">
    <template #activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" aria-label="Menu" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        ref="demandes-tableau-actions--consulter"
        key="consulter"
        link
        @click="onClickConsulter"
      >
        <v-list-item-title>
          {{ $t('demande.tableau.actions.consulter') }}
        </v-list-item-title>
      </v-list-item>
      <div v-if="afficherBoutonModifier">
        <v-divider />
        <v-list-item
          key="modifier"
          ref="demandes-faculte-tableau-actions--modifier"
          link
          @click="onClickModifier"
        >
          <v-list-item-title>
            {{ $t('demande.tableau.actions.modifier') }}
          </v-list-item-title>
        </v-list-item>
      </div>
      <div v-if="afficherBoutonSupprimer">
        <v-divider />
        <v-list-item key="supprimer" @click.stop="onClickSupprimer">
          <v-list-item-title class="danger--text">
            {{ $t('demande.tableau.actions.supprimer') }}
          </v-list-item-title>
        </v-list-item>
        <v-dialog v-model="dialogSupprimerOuvert" max-width="40%">
          <Spinner v-if="suppressionEnCours"></Spinner>
          <v-card>
            <v-card-title>
              {{
                estDemandeAvecDocuments
                  ? $t('demande.suppression.confirmation-documents')
                  : $t('demande.suppression.confirmation')
              }}
            </v-card-title>
            <v-card-text>
              <p>
                {{ $t('demande.suppression.numero-demande') }} :
                {{ demande.numeroDemande }}
              </p>
              <p>
                {{ $t('demande.suppression.code-session') }} :
                {{ demande.codeSession }}
              </p>
              <p>
                {{ $t('demande.suppression.type-demande') }} :
                {{ $t(`demande.types.${demande.typeDemande}`) }}
              </p>
              <p>
                {{ libelleSigleCoursProgramme }} :
                {{ sigleCoursProgramme }}
              </p>
              <p>
                {{ libelleNumeroCoursMajeureProgramme }}
                :
                {{ numeroCoursMajeureProgramme }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                ref="demandes-tableau-actions--supprimer"
                color="danger--text"
                @click="onConfirmerSuppression"
              >
                {{ $t('commun.actions.supprimer') }}
              </v-btn>
              <v-btn @click="onClickAnnulerSuppression">
                {{ $t('commun.actions.annuler') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
  import {
    RequeteSnackbar,
    TypesMessagesSnackbar,
  } from '@/commun/components/snackbars/requete-snackbar';
  import { EtatsDemande, TypesDemande } from '@/domain/models/demande';
  import Demande from '@/domain/models/demande';
  import Routes from '@/router/routes';
  import Vue from 'vue';
  import DemandeProgramme, {
    DemandeProgrammeTypeModification,
  } from '@/domain/models/demande-programme';
  import DemandeCours from '@/domain/models/demande-cours';
  import { Spinner } from '@/commun/components/spinner';

  export default Vue.extend({
    name: 'DemandesTableauActions',
    components: {
      Spinner,
    },
    props: {
      demande: {
        type: Object as () => Demande,
        required: true,
      },
    },
    data: () => ({
      dialogSupprimerOuvert: false,
      estDemandeAvecDocuments: false,
      suppressionEnCours: false,
    }),
    computed: {
      sigleCoursProgramme(): string | undefined {
        if (this.demande.typeDemande === TypesDemande.MODIFICATION_PROGRAMME) {
          return (this.demande as DemandeProgramme).codeProgramme;
        }
        return (this.demande as DemandeCours).sigleCours;
      },
      libelleSigleCoursProgramme(): string {
        if (this.demande.typeDemande === TypesDemande.MODIFICATION_PROGRAMME) {
          return this.$t('demande.suppression.programme').toString();
        }
        return this.$t('demande.suppression.sigle-cours').toString();
      },

      numeroCoursMajeureProgramme(): string | undefined {
        if (this.demande.typeDemande === TypesDemande.MODIFICATION_PROGRAMME) {
          if (this.estMajeureExistante) {
            return (this.demande as DemandeProgramme).codeMajeure;
          }
          return (this.demande as DemandeProgramme).titreMajeure;
        }
        return this.numeroSerieCours();
      },
      libelleNumeroCoursMajeureProgramme(): string {
        if (this.demande.typeDemande === TypesDemande.MODIFICATION_PROGRAMME) {
          if (this.estMajeureExistante) {
            return this.$t('demande.suppression.majeure').toString();
          } else {
            return this.$t('demande.suppression.titre-majeure').toString();
          }
        }
        return this.$t('demande.suppression.Numero-cours').toString();
      },

      estMajeureExistante(): boolean {
        return (
          (this.demande as DemandeProgramme).typeModification ===
          DemandeProgrammeTypeModification.MAJEURE_EXISTANTE
        );
      },

      afficherBoutonSupprimer(): boolean {
        return (
          this.demande.etatDemande === EtatsDemande.EN_REDACTION ||
          this.demande.etatDemande === EtatsDemande.EN_MODIFICATION
        );
      },
      afficherBoutonModifier(): boolean {
        return (
          this.demande.etatDemande === EtatsDemande.EN_REDACTION ||
          this.demande.etatDemande === EtatsDemande.EN_MODIFICATION
        );
      },
    },
    methods: {
      numeroSerieCours(): string | undefined {
        if ((this.demande as DemandeCours).numeroCours) {
          return (this.demande as DemandeCours).numeroCours;
        } else {
          return (this.demande as DemandeCours).serieCours;
        }
      },

      onClickConsulter(): void {
        this.$router.push({
          name: Routes.ConsulterDemande,
          params: {
            idDemande: this.demande.id,
          },
        });
      },
      onClickModifier(): void {
        this.$router.push({
          name: Routes.ModifierDemande,
          params: {
            idDemande: this.demande.id,
          },
        });
      },
      async onClickSupprimer(): Promise<void> {
        await this.$store.dispatch(
          'obtenirDemandeCouranteDocuments',
          this.demande.id
        );
        this.estDemandeAvecDocuments =
          this.$store.state.GestionOffreFormation.demandeCouranteDocuments
            .length > 0;

        this.dialogSupprimerOuvert = true;

        this.$nextTick(() => {
          this.focusBoutonSupprimer();
        });
      },
      focusBoutonSupprimer(): void {
        const boutonSupprimer = this.$refs[
          'demandes-tableau-actions--supprimer'
        ] as Vue;
        if (boutonSupprimer) {
          setTimeout(() => {
            (boutonSupprimer.$el as HTMLElement).focus();
          });
        }
      },
      onClickAnnulerSuppression(): void {
        this.dialogSupprimerOuvert = false;
      },
      async onConfirmerSuppression(): Promise<void> {
        this.suppressionEnCours = true;
        if (this.estDemandeAvecDocuments) {
          await this.$store.dispatch(
            'supprimerDemandeDocuments',
            this.$store.state.GestionOffreFormation.demandeCouranteDocuments
          );
        }
        await this.$store
          .dispatch('supprimerDemande', this.demande.id)
          .then(() => {
            this.$store.dispatch(
              'notifierUtilisateur',
              new RequeteSnackbar(
                this.$t('demande.suppression.succes').toString(),
                TypesMessagesSnackbar.Succes
              )
            );
          });
        this.suppressionEnCours = false;
      },
    },
  });
</script>
