/* eslint-disable no-underscore-dangle */
import { RegleCourriel } from '@/commun/validations/regles/regle-courriel';
import { RegleEchec } from '@/commun/validations/regles/regle-echec';
import { RegleRequis } from '@/commun/validations/regles/regle-requis';
import { RegleValidation } from '@/commun/validations/regles/regle-validation';
import { Validations } from '@/commun/validations/validations';
import i18n from '@/plugins/i18n';
import { configure, extend } from 'vee-validate';
import {
  max,
  min_value as minValue,
  numeric,
  oneOf,
} from 'vee-validate/dist/rules';

export function ajouterValidationsCommunes(): void {
  configure({
    defaultMessage: (field, values) => {
      // eslint-disable-next-line no-param-reassign
      values._field_ = field;
      return i18n.t(`validation.${values._rule_}`, values).toString();
    },
  });

  const reglesCommunes = [
    new RegleEchec(),
    new RegleRequis(),
    new RegleCourriel(),
  ];
  ajouterValidations(reglesCommunes);

  extend(Validations.Parmis, oneOf);
  extend(Validations.Numerique, numeric);
  extend(Validations.MaximumCaracteres, max);
  extend(Validations.MinimumValeur, minValue);
}

export function ajouterValidations(
  validations: Array<RegleValidation<unknown>>
): void {
  validations.forEach((validation: RegleValidation) => {
    extend(validation.nom, validation.obtenirRegleValidation());
  });
}
