<template>
  <div>
    <champ-formulaire
      :validation="regleValidationRequis"
      :nom="$t('demande.formulaires.champs.section-connexe.nom')"
    >
      <radio-group-oui-non
        v-model="demandeCourante.sectionConnexe"
        slot-scope="{ errors }"
        class="mt-0"
        :errors="errors"
        :reinitialisable="radioReinitialisable"
        :libelle="$t('demande.formulaires.champs.section-connexe.libelle')"
      >
        <tooltip
          slot="label"
          :messages="[
            $t('demande.formulaires.champs.section-connexe.infobulle'),
          ]"
        />
      </radio-group-oui-non>
    </champ-formulaire>
    <champ-formulaire
      :validation="regleValidationRequis"
      colonnes-champ="auto"
      :nom="$t('demande.formulaires.champs.cours-reiterable.nom')"
    >
      <radio-group-oui-non
        v-model="demandeCourante.repetable"
        slot-scope="{ errors }"
        class="mt-0"
        :errors="errors"
        :reinitialisable="radioReinitialisable"
        :libelle="$t('demande.formulaires.champs.cours-reiterable.libelle')"
      >
        <tooltip
          slot="label"
          :messages="[
            $t('demande.formulaires.champs.cours-reiterable.infobulle'),
          ]"
        />
      </radio-group-oui-non>
    </champ-formulaire>

    <div v-if="afficherCredits">
      <stepper-credits
        v-model="demandeCourante.nombreCredits"
        :disabled="disableCredits"
      />
      <v-divider class="mt-2" />
      <charge-travail v-model="demandeCourante" />
    </div>
  </div>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { RadioGroupOuiNon } from '@/commun/components/champ-formulaire/radio-group-oui-non';
  import { Tooltip } from '@/commun/components/champ-formulaire/tooltip';
  import { StepperCredits } from '@/components/faculte/demandes/formulaires/champs/stepper-credits';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { SectionModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement';
  import ChargeTravail from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/charge-travail/charge-travail.vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'ModeEnseignementRegulierFormulaire',
    components: {
      ChargeTravail,
      ChampFormulaire,
      RadioGroupOuiNon,
      StepperCredits,
      Tooltip,
    },
    mixins: [SectionFormulaireCours, SectionModeEnseignement],
    props: {
      radioReinitialisable: {
        type: Boolean,
        default: true,
      },
    },
  });
</script>
