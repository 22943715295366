<template>
  <v-container v-if="afficherPage" :class="{ 'col-8': estTailleDesktop }">
    <formulaire @soumettre="onCreerDemande">
      <alerte
        type="info"
        :message="$t('demande.programme.avertissement').toString()"
      />
      <section-formulaire
        :titre="$t('demande.programme.programme-a-modifier').toString()"
      >
        <champ-formulaire
          validation="requis"
          :nom="$t('demande.modification.champs.session.nom').toString()"
        >
          <select-autocomplete
            v-model="demandeModificationProgramme.codeSession"
            slot-scope="{ errors }"
            :errors="errors"
            :label="
              $t(
                'demande.programme.formulaire.champs.session-vigueur'
              ).toString()
            "
            :items="itemsSessions"
          />
        </champ-formulaire>
        <section-identification-programme-majeure
          v-model="demandeModificationProgramme"
          :facultes="facultes"
          :facultes-rattachement="facultesRattachement"
          :roles="roles"
          :est-en-modification="estEnModification"
        />
      </section-formulaire>

      <section-formulaire class="mt-4" :titre="titreSectionApprobation">
        <section-modifications-apportees
          v-if="afficherSectionModificationsApportees"
          :indicateurs-programme="indicateursModificationProgramme"
          @changeIndicateursProgramme="onChangeIndicateursProgramme"
        />
        <p
          v-else
          style="font-size: 16px"
          v-html="$t('demande.programme.nouvelle-majeure.information')"
        />
      </section-formulaire>
      <section-approbation
        v-model="demandeModificationProgramme"
        :indicateurs-programme="indicateursModificationProgramme"
      />
      <section-documents
        :demande-document-existants="demandeDocumentExistants"
        @change="nouveauxFichiers = $event"
        @supprimer-demande-document="documentExistantsASupprimer.push($event)"
        @supprimer-all-documents="supprimerAllDocuments"
      />
      <div
        :class="[
          estTailleDesktop ? 'flex-row' : 'flex-column',
          'd-flex',
          'mt-8',
        ]"
      >
        <v-btn
          ref="creation-demande-faculte-formulaire__enregistrer-et-continuer"
          class="primary"
          type="submit"
        >
          {{ $t('commun.actions.enregistrer-et-continuer') }}
        </v-btn>
        <v-btn
          :class="[estTailleDesktop ? 'ml-4' : 'mt-6']"
          @click="onClickAnnuler"
        >
          {{ $t('commun.actions.annuler') }}
        </v-btn>
      </div>
    </formulaire>
  </v-container>
</template>

<script lang="ts">
  import Alerte from '@/commun/components/champ-formulaire/alerte/alerte.vue';
  import SectionFormulaire from '@/commun/components/champ-formulaire/section-formulaire/section-formulaire.vue';
  import Formulaire from '@/commun/components/formulaire/formulaire.vue';
  import SectionApprobation from '@/components/faculte/demandes/formulaires/programme/section-approbation/section-approbation.vue';
  import SectionModificationsApportees from '@/components/faculte/demandes/formulaires/programme/section-modifications-apportees/section-modifications-apportees.vue';
  import Vue, { PropType } from 'vue';
  import SelectAutocompleteItem from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete-item';
  import Session from '@/commun/services/set/model/session';
  import {
    DemandeProgramme,
    DemandeProgrammeTypeModification,
    IndicateursModificationProgramme,
  } from '@/domain/models/demande-programme';
  import { EtatsDemande, TypesDemande } from '@/domain/models/demande';
  import Faculte from '@/commun/services/set/model/faculte';
  import { IdentiteRoles } from '@/domain/models/identite';
  import { SectionIdentificationProgrammeMajeure } from '@/components/faculte/demandes/formulaires/programme/section-identification-programme-majeure';
  import ChampFormulaire from '@/commun/components/champ-formulaire/champ-formulaire.vue';
  import SelectAutocomplete from '@/commun/components/champ-formulaire/select-autocomplete/select-autocomplete.vue';
  import { SectionDocuments } from '../creation-cours/section-documents';
  import { DemandeDocument } from '@/domain/models/demande-document';

  export default Vue.extend({
    name: 'FormulaireModificationProgramme',
    components: {
      SectionApprobation,
      SectionModificationsApportees,
      SectionFormulaire,
      Formulaire,
      Alerte,
      SectionIdentificationProgrammeMajeure,
      ChampFormulaire,
      SelectAutocomplete,
      SectionDocuments,
    },
    props: {
      roles: {
        type: Array as PropType<Array<IdentiteRoles>>,
        required: true,
      },
      facultes: {
        type: Array as PropType<Array<Faculte>>,
        required: true,
      },
      facultesRattachement: {
        type: Array as PropType<Array<string>>,
        required: true,
      },
      demandeEnModification: {
        type: Object as PropType<DemandeProgramme>,
        default: undefined,
      },
      sessions: {
        type: Array as PropType<Array<Session>>,
        required: true,
      },
    },
    data: () => ({
      demandeModificationProgramme: {
        typeDemande: TypesDemande.MODIFICATION_PROGRAMME,
        typeModification: DemandeProgrammeTypeModification.MAJEURE_EXISTANTE,
        etatDemande: EtatsDemande.EN_REDACTION,
      } as DemandeProgramme,
      indicateursProgramme: {} as IndicateursModificationProgramme,
      nouveauxFichiers: new Array<File>(0),
      documentExistantsASupprimer: new Array<DemandeDocument>(0),
      enregistrementEnCours: false,
      documentsExistantsTriees: [] as DemandeDocument[],
      afficherPage: false,
    }),
    computed: {
      titreSectionApprobation(): string {
        if (
          this.demandeModificationProgramme.typeModification ===
          DemandeProgrammeTypeModification.MAJEURE_EXISTANTE
        ) {
          return this.$t(
            'demande.programme.modification-majeure.modification-a-apporter'
          ).toString();
        } else {
          return this.$t(
            'demande.programme.nouvelle-majeure.document-a-joindre'
          ).toString();
        }
      },
      itemsSessions(): SelectAutocompleteItem[] {
        return this.sessions.map(
          (session: Session) =>
            ({
              texte: `${session.codeSession} - ${session.description}`,
              valeur: session.codeSession,
            }) as SelectAutocompleteItem
        );
      },
      indicateursModificationProgramme(): IndicateursModificationProgramme {
        return this.indicateursProgramme;
      },
      afficherSectionModificationsApportees(): boolean {
        return (
          this.demandeModificationProgramme.typeModification ===
          DemandeProgrammeTypeModification.MAJEURE_EXISTANTE
        );
      },
      estTailleDesktop(): boolean {
        return this.$vuetify.breakpoint.smAndUp;
      },
      estEnModification(): boolean {
        return Boolean(this.demandeEnModification);
      },
      demandeDocumentExistants(): DemandeDocument[] {
        return this.documentsExistantsTriees.filter(
          (dcd: DemandeDocument) =>
            !this.documentExistantsASupprimer
              .map((das: DemandeDocument) => das.idDocument)
              .includes(dcd.idDocument)
        );
      },
    },
    async created() {
      this.afficherPage = false;
      await this.$store.commit('demandeCouranteDocuments', []);
      if (this.estEnModification) {
        this.demandeModificationProgramme = { ...this.demandeEnModification };
        this.indicateursProgramme = {
          ...this.demandeModificationProgramme
            ?.indicateursModificationProgramme,
        };

        await this.$store.dispatch(
          'obtenirDemandeCouranteDocuments',
          this.demandeModificationProgramme.id
        );
        this.documentsExistantsTriees = ([] as DemandeDocument[]).concat(
          this.$store.getters.demandeCouranteDocumentsTrieesParDate
        );
      }
      this.afficherPage = true;
    },
    methods: {
      supprimerAllDocuments() {
        this.documentExistantsASupprimer = this.documentsExistantsTriees;
        this.nouveauxFichiers = new Array<File>(0);
      },
      onChangeIndicateursProgramme(
        payload: IndicateursModificationProgramme
      ): void {
        this.indicateursProgramme = { ...payload };
        this.demandeModificationProgramme.indicateursModificationProgramme = {
          ...payload,
        };
      },
      onClickAnnuler() {
        this.$emit('annulerFormulaire');
      },
      async onCreerDemande(): Promise<void> {
        if (this.enregistrementEnCours) return;

        this.enregistrementEnCours = true;

        if (
          this.demandeModificationProgramme.typeModification ===
          DemandeProgrammeTypeModification.MAJEURE_NOUVELLE
        ) {
          this.enleverChampsSpecifiqueAuMajeurExistant();
        } else {
          this.enleverChampsSpecifiqueAuNouvelleMajeure();
        }

        this.$emit('enregistrer', {
          demande: this.demandeModificationProgramme,
          nouveauxFichiers: this.nouveauxFichiers,
          documentExistantsASupprimer: this.documentExistantsASupprimer,
        });

        this.enregistrementEnCours = false;
      },
      enleverChampsSpecifiqueAuNouvelleMajeure(): void {
        delete this.demandeModificationProgramme.titreMajeure;
      },
      enleverChampsSpecifiqueAuMajeurExistant(): void {
        delete this.demandeModificationProgramme
          .indicateursModificationProgramme;
        delete this.demandeModificationProgramme.codeMajeure;
      },
    },
  });
</script>
