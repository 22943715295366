<template>
  <div>
    <v-breadcrumbs class="pl-0" :items="breadcrumbsItems" />
    <v-container :class="{ 'col-7': $vuetify.breakpoint.smAndUp }">
      <formulaire-modification-cours
        v-if="afficherFormulaire"
        :demande-en-modification="demande"
        :sessions="sessions"
        :session-courante="sessionCourante"
        :matieres="matieres"
        :facultes="facultes"
        :unites-responsables="unitesResponsables"
        :facultes-rattachement="facultesRattachement"
        :roles="roles"
        @annulerFormulaire="$emit('annuler')"
        @annulerRechercheDetailsCours="onAnnulerRechercheDetailsCours"
        @creerDemande="onCreerDemande"
      />
    </v-container>
  </div>
</template>

<script lang="ts">
  import Faculte from '@/commun/services/set/model/faculte';
  import Matiere from '@/commun/services/set/model/matiere';
  import Session from '@/commun/services/set/model/session';
  import Unite from '@/commun/services/set/model/unites';
  import FormulaireModificationCours from '@/components/faculte/demandes/formulaires/modification-cours/formulaire-modification-cours.vue';
  import { EtatsDemande } from '@/domain/models/demande';
  import DemandeCours from '@/domain/models/demande-cours';
  import Routes from '@/router/routes';
  import Vue, { PropType } from 'vue';
  import { IdentiteRoles } from '@/domain/models/identite';
  import { DemandeDocument } from '@/domain/models/demande-document';

  export default Vue.extend({
    name: 'CreerDemandeModificationCours',
    components: { FormulaireModificationCours },
    props: {
      demande: {
        type: Object as PropType<DemandeCours>,
        default: undefined,
      },
    },
    data: () => ({
      afficherFormulaire: false,
    }),
    computed: {
      breadcrumbsItems(): Array<unknown> {
        return [
          {
            disabled: false,
            text: this.$i18n.t(
              'demande.formulaires.navigation-liste-de-demandes'
            ),
            exact: true,
            to: { name: Routes.Demandes },
          },
          {
            text: this.$i18n.t('demande.types.MODIFICATION_COURS'),
          },
        ];
      },
      sessions(): Session[] {
        return this.$store.getters.sessionsFuturesHoraireNonPublie;
      },
      sessionCourante(): Session {
        return this.$store.getters.sessionCourante;
      },
      facultes(): Faculte[] {
        return this.$store.getters.facultes;
      },
      matieres(): Matiere[] {
        return this.$store.state.Etudes.matieres;
      },
      unitesResponsables(): Unite[] {
        return this.$store.state.Etudes.unites;
      },
      roles(): IdentiteRoles[] {
        return this.$store.getters.roles;
      },
      facultesRattachement(): string[] {
        return this.$store.state.GestionOffreFormation.identite
          .facultesRattachement;
      },
    },
    async created(): Promise<void> {
      await Promise.all([
        this.$store.dispatch('obtenirSessionCourante'),
        this.$store.dispatch('obtenirSessions'),
        this.$store.dispatch('obtenirMatieres'),
        this.$store.dispatch('obtenirUnites'),
      ]);
      if (this.demande) {
        const idDetailCours = `${this.demande.sigleCours}-${this.demande.numeroCours}-${this.sessionCourante.codeSession}`;
        await this.$store.dispatch('obtenirDetailsCours', idDetailCours);
      } else {
        await this.$store.dispatch('supprimerResumesDetailsCours');
        await this.$store.dispatch('supprimerDetailsCours');
      }

      this.afficherFormulaire = true;
    },
    methods: {
      async onAnnulerRechercheDetailsCours(): Promise<void> {
        await this.$store.dispatch('supprimerResumesDetailsCours');
      },
      async onCreerDemande({
        demande,
        nouveauxFichiers,
        documentExistantsASupprimer,
      }: {
        demande: DemandeCours;
        nouveauxFichiers: File[];
        documentExistantsASupprimer: DemandeDocument[];
      }): Promise<void> {
        const demandeACreer = demande;
        const proprietesARetirer = [
          'nombreCredits',
          'nombreUnitesEducationContinue',
        ];

        proprietesARetirer.forEach((propriete: string) => {
          const clePropriete = propriete as keyof DemandeCours;
          if (clePropriete in demandeACreer) {
            delete demandeACreer[clePropriete];
          }
        });

        demandeACreer.etatDemande = EtatsDemande.EN_REDACTION;

        this.$emit('enregistrer', {
          demande: demandeACreer,
          nouveauxFichiers,
          documentExistantsASupprimer,
        });
      },
    },
  });
</script>
