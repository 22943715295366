<template>
  <footer class="normes-ul-pied-page charcoal">
    <div class="pied-page-contenu">
      <section class="coordonnees">
        <div class="bloc-carte">
          <a href="https://www.ulaval.ca/plan-du-campus">
            <img
              src="@/components/cadre/banniere-ulaval/normes-ul/img/carte-ul.png"
              :alt="$t('cadre.bqp.pied-page.coordonnees.carte.alt')"
              class="carte"
            />
          </a>
        </div>
        <div class="bloc-adresse">
          <p class="titre-section">
            <strong>{{ $t('cadre.bqp.bureau-qualite-programmes') }}</strong>
          </p>
          <p class="adresse">
            {{ $t('cadre.bqp.pied-page.coordonnees.pavillon') }}<br />
            {{ $t('cadre.bqp.pied-page.coordonnees.rue') }}<br />
            {{ $t('cadre.bqp.pied-page.coordonnees.local') }}<br />
            {{ $t('cadre.bqp.pied-page.coordonnees.universite-laval') }}<br />
            {{ $t('cadre.bqp.pied-page.coordonnees.code-postal') }}
          </p>
          <p class="telephone">
            {{ $t('cadre.bqp.pied-page.coordonnees.telephone.titre') }}
            <span>{{
              $t('cadre.bqp.pied-page.coordonnees.telephone.numero')
            }}</span>
          </p>
          <p class="telecopieur">
            {{ $t('cadre.bqp.pied-page.coordonnees.telecopieur.titre') }}
            <span>
              {{ $t('cadre.bqp.pied-page.coordonnees.telecopieur.numero') }}
            </span>
          </p>
          <p class="courriel">
            {{ $t('cadre.bqp.pied-page.coordonnees.courriel.titre') }}
            <a
              :href="`mailto:${$t(
                'cadre.bqp.pied-page.coordonnees.courriel.adresse'
              )}`"
            >
              {{ $t('cadre.bqp.pied-page.coordonnees.courriel.adresse') }}
            </a>
          </p>
        </div>
      </section>
      <section class="liens"></section>
      <section class="communication">
        <a
          :href="`mailto:${$t(
            'cadre.bqp.pied-page.coordonnees.courriel.adresse'
          )}`"
          class="bouton"
        >
          {{ $t('cadre.bqp.pied-page.demande-information') }}
        </a>
      </section>
    </div>
    <section class="copyright">
      <div>
        <div>
          <span>
            {{ $t('cadre.bqp.pied-page.copyright.universite-laval') }}
          </span>
          <span>
            {{ $t('cadre.bqp.pied-page.copyright.droits-reserves') }}
          </span>
        </div>
        <div>
          <span>
            <a href="https://www.ulaval.ca/conditions" target="_blank">
              {{ $t('cadre.bqp.pied-page.copyright.conditions-utilisation') }}
            </a>
          </span>
          <span>
            <a
              href="https://www.ulaval.ca/prevention-de-la-fraude-en-ligne"
              target="_blank"
            >
              {{ $t('cadre.bqp.pied-page.copyright.fraude') }}
            </a>
          </span>
          <span>
            <a href="https://www.ulaval.ca/confidentialite" target="_blank">
              {{ $t('cadre.bqp.pied-page.copyright.confidentialite') }}
            </a>
          </span>
        </div>
      </div>
    </section>
  </footer>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'FooterGof',
  });
</script>
