var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.afficherPage)?_c('div',[_c('alerte',{attrs:{"type":"info","message":_vm.$t(
        'demande.modification.formulaire.avertissement-desactivation'
      ).toString()}}),_c('formulaire',{ref:"formulaire",staticClass:"p-0",on:{"soumettre":_vm.onSoumettre}},[_c('section-identification-cours',{attrs:{"sessions":_vm.sessions,"type-demande":_vm.typeDemande,"est-modifiable":_vm.coursEstModifiable,"detail-cours":_vm.detailsCours},on:{"annulerRechercheDetailsCours":_vm.onAnnulerRechercheDetailsCours},model:{value:(_vm.demandeDesactivationCours),callback:function ($$v) {_vm.demandeDesactivationCours=$$v},expression:"demandeDesactivationCours"}}),(_vm.afficherFormulaire)?_c('div',[_c('section-formulaire',{staticClass:"mt-8",attrs:{"titre":_vm.$t('demande.formulaires.section.approbation')}},[_c('champ-formulaire',{attrs:{"validation":`max:${_vm.maximumCaracteresCourrielDirecteur}|email`,"nom":_vm.$t(
              'demande.formulaires.champs.courriel-approbateur-departement.nom'
            )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return _c('courriel-textfield',{attrs:{"label":_vm.$t(
                'demande.formulaires.champs.courriel-approbateur-departement.nom'
              ),"counter":_vm.maximumCaracteresCourrielDirecteur,"errors":errors},model:{value:(_vm.demandeDesactivationCours.courrielApprobateurDepartement),callback:function ($$v) {_vm.$set(_vm.demandeDesactivationCours, "courrielApprobateurDepartement", $$v)},expression:"demandeDesactivationCours.courrielApprobateurDepartement"}})}}],null,false,630943162)},[_c('tooltip',{attrs:{"slot":"contenu-droite","messages":[
              _vm.$t(
                'demande.formulaires.champs.courriel-approbateur-facultaire.infobulle'
              ),
            ]},slot:"contenu-droite"})],1),_c('champ-formulaire',{attrs:{"validation":`requis|max:${_vm.maximumCaracteresCourrielApprobateurFacultaire}|email`,"nom":_vm.$t(
              'demande.formulaires.champs.courriel-approbateur-facultaire.nom'
            )},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return _c('courriel-textfield',{attrs:{"label":_vm.$t(
                'demande.formulaires.champs.courriel-approbateur-facultaire.nom'
              ),"counter":_vm.maximumCaracteresCourrielApprobateurFacultaire,"errors":errors},model:{value:(_vm.demandeDesactivationCours.courrielApprobateurFacultaire),callback:function ($$v) {_vm.$set(_vm.demandeDesactivationCours, "courrielApprobateurFacultaire", $$v)},expression:"demandeDesactivationCours.courrielApprobateurFacultaire"}})}}],null,false,4185097802)},[_c('tooltip',{attrs:{"slot":"contenu-droite","messages":[
              _vm.$t(
                'demande.formulaires.champs.courriel-approbateur-facultaire.infobulle'
              ),
            ]},slot:"contenu-droite"})],1)],1),_c('section-information-complementaire',{attrs:{"champs-affiches":_vm.champsInformationComplementaire},model:{value:(_vm.demandeDesactivationCours),callback:function ($$v) {_vm.demandeDesactivationCours=$$v},expression:"demandeDesactivationCours"}}),_c('section-documents',{attrs:{"demande-document-existants":_vm.demandeDocumentExistants},on:{"change":function($event){_vm.nouveauxFichiers = $event},"supprimer-demande-document":function($event){return _vm.documentExistantsASupprimer.push($event)},"supprimer-all-documents":_vm.supprimerAllDocuments}})],1):_vm._e(),_c('div',{class:[
        _vm.estTailleDesktop ? 'flex-row' : 'flex-column',
        'd-flex',
        'mt-8',
      ]},[_c('v-btn',{ref:"creation-demande-faculte-formulaire__enregistrer-et-continuer",staticClass:"primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.enregistrer-et-continuer'))+" ")]),_c('v-btn',{class:[_vm.estTailleDesktop ? 'ml-4' : 'mt-6'],on:{"click":_vm.onClickAnnuler}},[_vm._v(" "+_vm._s(_vm.$t('commun.actions.annuler'))+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }