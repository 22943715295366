<template>
  <div>
    <v-breadcrumbs class="pl-0" :items="breadcrumbsItems" />
    <v-container v-if="!afficherFormulaire" fill-height>
      <v-row align="center" justify="center">
        <v-col align="center">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
    </v-container>
    <creation-demande-faculte-formulaire
      v-if="afficherFormulaire"
      :demande-en-modification="demande"
      :facultes="facultes"
      :session-courante="sessionCourante"
      :matieres="matieres"
      :unites-responsables="unitesResponsables"
      :facultes-rattachement="facultesRattachement"
      :roles="roles"
      @annuler="$emit('annuler')"
      @enregistrer="$emit('enregistrer', $event)"
    />
  </div>
</template>

<script lang="ts">
  import Faculte from '@/commun/services/set/model/faculte';
  import Matiere from '@/commun/services/set/model/matiere';
  import Session from '@/commun/services/set/model/session';
  import Unite from '@/commun/services/set/model/unites';
  import { CreationDemandeFaculteFormulaire } from '@/components/faculte/demandes/formulaires';
  import DemandeCours from '@/domain/models/demande-cours';
  import Routes from '@/router/routes';
  import Vue, { PropType } from 'vue';
  import { IdentiteRoles } from '@/domain/models/identite';

  export default Vue.extend({
    name: 'CreerDemandeCreationCours',
    components: {
      CreationDemandeFaculteFormulaire,
    },
    props: {
      demande: {
        type: Object as PropType<DemandeCours>,
        default: undefined,
      },
    },
    data: () => ({
      afficherFormulaire: false,
    }),
    computed: {
      facultes(): Faculte[] {
        return this.$store.getters.facultes;
      },
      matieres(): Matiere[] {
        return this.$store.state.Etudes.matieres;
      },
      sessionCourante(): Session {
        return this.$store.getters.sessionCourante;
      },
      unitesResponsables(): Unite[] {
        return this.$store.state.Etudes.unites;
      },
      roles(): IdentiteRoles[] {
        return this.$store.getters.roles;
      },
      facultesRattachement(): string[] {
        return this.$store.state.GestionOffreFormation.identite
          .facultesRattachement;
      },
      breadcrumbsItems(): Array<unknown> {
        return [
          {
            disabled: false,
            text: this.$i18n.t(
              'demande.formulaires.navigation-liste-de-demandes'
            ),
            exact: true,
            to: { name: Routes.Demandes },
          },
          {
            text: this.$i18n.t('demande.types.CREATION_COURS'),
          },
        ];
      },
    },
    async created() {
      await Promise.all([
        this.$store.dispatch('obtenirMatieres'),
        this.$store.dispatch('obtenirUnites'),
        this.$store.dispatch('obtenirSessionCourante'),
      ]);
      this.afficherFormulaire = true;
    },
  });
</script>
