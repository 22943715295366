var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('radio-group',_vm._g(_vm._b({ref:"echelle-notation-radio-group",attrs:{"nom":_vm.nom,"validation":_vm.validation,"libelle":_vm.$t('demande.formulaires.champs.echelle-notation.nom').toString(),"value":_vm.value}},'radio-group',_vm.$attrs,false),{
    ..._vm.$listeners,
    input: (event) => _vm.$emit('input', event),
  }),[_vm._l((_vm.echellesNotation),function(echelleNotation){return _c('v-radio',{key:echelleNotation,staticStyle:{"width":"fit-content"},attrs:{"label":_vm.$t(`demande.formulaires.champs.echelle-notation.${echelleNotation}`),"value":`${echelleNotation}`}})}),_c('tooltip',{attrs:{"slot":"label","messages":[
      _vm.$t('demande.formulaires.champs.echelle-notation.infobulle-1'),
      _vm.$t('demande.formulaires.champs.echelle-notation.infobulle-2'),
    ]},slot:"label"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }