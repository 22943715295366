import { BeforeRequestHook, Options } from 'ky';
import { AuthModule } from '@/commun/services/auth/AuthModule';

let accessToken: string | undefined;

export const creerHookAuthentification =
  (authModule: AuthModule): BeforeRequestHook =>
  async (request: Request) => {
    await authModule.gererPromesseRedirection();

    const estAuthentifie = await authModule.estAuthentifie();
    if (!estAuthentifie) {
      await authModule.login();
    }

    accessToken = authModule.getToken();
    if (accessToken !== undefined) {
      request.headers.set('Authorization', `Bearer ${accessToken}`);
    }
  };

export const enregistrerHookAuthentification = (
  authModule: AuthModule
): Options => ({
  hooks: {
    beforeRequest: [creerHookAuthentification(authModule)],
  },
});
