<template>
  <div>
    <h3 class="text-h5">
      {{ $t('demande.formulaires.section.mode-enseignement') }}
    </h3>
    <champ-formulaire v-if="afficherAlert">
      <alerte
        type="info"
        no-margin
        :message="
          $t(
            'demande.modification.formulaire.avertissement-champs-optionnels'
          ).toString()
        "
      />
    </champ-formulaire>
    <mode-enseignement-select
      ref="selectModeEnseignement"
      v-model="demandeCourante.modeEnseignement"
      :disabled="disableModeEnseignement"
      validation="requis"
      class="mt-4"
      @input="onChangementModeEnseignement"
    />

    <v-fade-transition mode="out-in">
      <mode-enseignement-regulier-formulaire
        v-if="estRegulier"
        key="1"
        v-model="demandeCourante"
        :radio-reinitialisable="radioReinitialisable"
        :validation-requis="validationRequis"
        :afficher-credits="afficherCredits"
        :disable-credits="disableCredits"
      />
      <mode-enseignement-stage-formulaire
        v-if="estStage"
        key="2"
        v-model="demandeCourante"
        :radio-reinitialisable="radioReinitialisable"
        :validation-requis="validationRequis"
        :afficher-credits="afficherCredits"
        :disable-credits="disableCredits"
      />
      <mode-enseignement-stage-residence-medecine-formulaire
        v-if="estStageResidenceMedecine"
        key="3"
        v-model="demandeCourante"
        :radio-reinitialisable="radioReinitialisable"
        :validation-requis="validationRequis"
        :afficher-credits="afficherCredits"
        :disable-credits="disableCredits"
      />
      <mode-enseignement-education-continue-formulaire
        v-if="estEducationContinue"
        key="4"
        v-model="demandeCourante"
        :radio-reinitialisable="radioReinitialisable"
        :validation-requis="validationRequis"
        :afficher-credits="afficherCredits"
        :disable-credits="disableCredits"
      />
    </v-fade-transition>
    <v-divider class="mt-2" />

    <slot
      v-if="afficherChamp(champsInformationModeEnseignement.EchelleNotation)"
      name="echelle-notation"
    />

    <champ-formulaire
      v-if="afficherChamp(champsInformationModeEnseignement.CoursEnFrancais)"
    >
      <cours-en-francais-radio-group
        v-model="demandeCourante.langueEnseignement"
        :radio-reinitialisable="radioReinitialisable"
      />
    </champ-formulaire>
  </div>
</template>

<script lang="ts">
  import { ChampFormulaire } from '@/commun/components/champ-formulaire';
  import { CoursEnFrancaisRadioGroup } from '@/components/faculte/demandes/formulaires/champs/cours-en-francais';
  import { SectionFormulaireCours } from '@/components/faculte/demandes/formulaires/creation-cours/section-formulaire-cours';
  import { ChampsInformationModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/champs-information-mode-enseignement';
  import { ModeEnseignementSelect } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/mode-enseignement-select';
  import { SectionModeEnseignement } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement';
  import { ModeEnseignementEducationContinueFormulaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/education-continue';
  import { ModeEnseignementRegulierFormulaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/regulier';
  import { ModeEnseignementStageResidenceMedecineFormulaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/residence-medecine';
  import { ModeEnseignementStageFormulaire } from '@/components/faculte/demandes/formulaires/creation-cours/section-information-mode-enseignement/sections-mode-enseignement/stage';
  import { DemandeCours } from '@/domain/models/demande-cours';
  import { ModesEnseignement } from '@/domain/models/mode-enseignement';
  import Vue, { PropType } from 'vue';
  import { Alerte } from '@/commun/components/champ-formulaire/alerte';

  export default Vue.extend({
    name: 'InformationModeEnseignement',
    components: {
      ChampFormulaire,
      CoursEnFrancaisRadioGroup,
      ModeEnseignementEducationContinueFormulaire,
      ModeEnseignementRegulierFormulaire,
      ModeEnseignementStageFormulaire,
      ModeEnseignementStageResidenceMedecineFormulaire,
      ModeEnseignementSelect,
      Alerte,
    },
    mixins: [SectionFormulaireCours, SectionModeEnseignement],
    props: {
      afficherAlert: {
        type: Boolean,
        default: false,
      },
      champsAffiches: {
        type: Array as PropType<Array<ChampsInformationModeEnseignement>>,
        default: () => Object.values(ChampsInformationModeEnseignement),
      },
      disableModeEnseignement: {
        type: Boolean,
        default: false,
      },
      maxCredits: {
        type: Number,
        default: 999,
      },
      minCredits: {
        type: Number,
        default: 0,
      },
      radioReinitialisable: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      demandeCourante: {} as DemandeCours,
      champsInformationModeEnseignement: ChampsInformationModeEnseignement,
    }),
    computed: {
      afficherSectionConnexe(): boolean {
        return (
          this.$data.demandeCourante.modeEnseignement ===
          ModesEnseignement.Regulier
        );
      },
      afficherTempsComplet(): boolean {
        return (
          this.$data.demandeCourante.modeEnseignement ===
          ModesEnseignement.Stage
        );
      },
      estRegulier(): boolean {
        return (
          this.$data.demandeCourante.modeEnseignement ===
          ModesEnseignement.Regulier
        );
      },
      estStage(): boolean {
        return (
          this.$data.demandeCourante.modeEnseignement ===
          ModesEnseignement.Stage
        );
      },
      estStageResidenceMedecine(): boolean {
        return (
          this.$data.demandeCourante.modeEnseignement ===
          ModesEnseignement.StageResidenceMedecine
        );
      },
      estEducationContinue(): boolean {
        return (
          this.$data.demandeCourante.modeEnseignement ===
          ModesEnseignement.EducationContinue
        );
      },
    },
    methods: {
      afficherChamp(champ: ChampsInformationModeEnseignement): boolean {
        return this.champsAffiches?.some(
          (champAffiche) => champ === champAffiche
        );
      },
      onChangementModeEnseignement(): void {
        this.reinitialiserValeurs();
        this.$emit('input', this.demandeCourante);
      },
      reinitialiserValeurs(): void {
        const demandeFiltree = Object.assign({}, this.demandeCourante);
        demandeFiltree.nombreCredits = null;
        demandeFiltree.heuresMagistrales = null;
        demandeFiltree.heuresPersonnelles = null;
        demandeFiltree.heuresTravaux = null;
        demandeFiltree.nombreUnitesEducationContinue = null;
        demandeFiltree.sectionConnexe = null;
        demandeFiltree.attributStage = null;
        demandeFiltree.tempsComplet = null;
        demandeFiltree.repetable = null;
        this.demandeCourante = demandeFiltree;
      },
    },
  });
</script>
