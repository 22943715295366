import Session from '@/commun/services/set/model/session';

export const sessionsMock = (): Session[] => [
  {
    codeSession: '202109',
    dateDebutSession: {
      annee: 2021,
      jour: 26,
      mois: 8,
    },
    dateFinSession: {
      annee: 2021,
      jour: 20,
      mois: 12,
    },
    description: 'Automne 2021',
    courante: true,
    horairePublie: true,
  },
  {
    codeSession: '202201',
    dateDebutSession: {
      annee: 2021,
      jour: 26,
      mois: 8,
    },
    dateFinSession: {
      annee: 2021,
      jour: 20,
      mois: 12,
    },
    description: 'Hiver 2022',
    courante: false,
    horairePublie: false,
  },
];
