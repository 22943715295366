import { DictionnaireUnitesMere } from '@/commun/services/set/model/dictionnaire-unites-mere';
import Faculte from '@/commun/services/set/model/faculte';
import ResumeDetailsCours from '@/commun/services/set/model/resume-details-cours';
import ResumeDetailsProgrammes from '@/commun/services/set/model/resume-details-programmes';
import Session, {
  CODE_DERNIERE_SESSION_INDETERMINEE,
} from '@/commun/services/set/model/session';
import Unite, { TypeUnite } from '@/commun/services/set/model/unites';
import EtudesState from '@/commun/store/modules/etudes/etudes-state';

const EtudesGetters = {
  facultes(state: EtudesState): Faculte[] {
    return state.unites
      .filter((unite: Unite) => unite.type === TypeUnite.FACULTE)
      .map(
        (unite: Unite) =>
          new Faculte(
            unite.codeUnitePedagogique,
            unite.nomUnitePedagogique,
            unite.codeUnitePedagogiqueMere,
            unite.affichableDansListeDeValeurs,
            unite.type,
            (unite as Faculte).nomLongFaculte
          )
      );
  },
  sessionCourante(state: EtudesState): Session | undefined {
    return state.sessionCourante;
  },
  sessionsFuturesHoraireNonPublie(state: EtudesState): Session[] {
    const codeSessionCourante = state.sessionCourante
      ? state.sessionCourante.codeSession
      : '0';
    return state.sessions.filter(
      (session: Session) =>
        !session.horairePublie &&
        session.codeSession > codeSessionCourante &&
        session.codeSession != CODE_DERNIERE_SESSION_INDETERMINEE
    );
  },
  resumeDetailsCours(state: EtudesState): ResumeDetailsCours[] {
    return state.resumeDetailsCours;
  },
  resumeDetailsProgrammes(state: EtudesState): ResumeDetailsProgrammes[] {
    return state.resumeDetailsProgrammes;
  },
  dictionnaireUnitesResponsablesMeres(
    state: EtudesState
  ): DictionnaireUnitesMere {
    return state.unites.reduce((r, a: Unite) => {
      // eslint-disable-next-line no-param-reassign
      r[a.codeUnitePedagogiqueMere] = r[a.codeUnitePedagogiqueMere] || [];
      r[a.codeUnitePedagogiqueMere].push(a);
      return r;
    }, Object.create(null));
  },
};

export default EtudesGetters;
