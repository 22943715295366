export enum TypesDemande {
  CREATION_COURS = 'CREATION_COURS',
  MODIFICATION_COURS = 'MODIFICATION_COURS',
  DESACTIVATION_COURS = 'DESACTIVATION_COURS',
  REACTIVATION_COURS = 'REACTIVATION_COURS',
  MODIFICATION_PROGRAMME = 'MODIFICATION_PROGRAMME',
}

export enum EtatsDemande {
  DEPOSEE = 'DEPOSEE',
  EN_MODIFICATION = 'EN_MODIFICATION',
  EN_TRAITEMENT = 'EN_TRAITEMENT',
  EN_REDACTION = 'EN_REDACTION',
  COMPLETEE = 'COMPLETEE',
  ANNULEE = 'ANNULEE',
}
interface Audit {
  horodateCreation?: string;
  horodateModification?: string;
  idIndividuCreation?: string;
  idIndividuModification?: string;
}
interface CibleDemande {
  identifiantCible?: string;
  titreCible?: string;
  faculteResponsableCible?: string;
}
interface Demande {
  id: string;
  cibleDemande?: CibleDemande;
  audit?: Audit;
  numeroDemande?: number;
  codeSession: string;
  codeFaculte?: number;
  etatDemande: string;
  dateDepot?: string;
  idBilletJira?: string;
  informationSupplementaire?: string;
  commentaireGestionnaire?: string;
  courrielApprobateurDepartement?: string;
  courrielApprobateurFacultaire?: string;
  repetable?: boolean | null;
  demandeur?: string;
  typeDemande: string;
  jsonCibleDemande?: string;
}

export default Demande;
