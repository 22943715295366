import Demande from '@/domain/models/demande';

export enum DemandeProgrammeTypeModification {
  MAJEURE_NOUVELLE = 'NOUVELLE_MAJEURE',
  MAJEURE_EXISTANTE = 'MAJEURE_EXISTANTE',
}

export interface IndicateursModificationProgramme {
  admissionSessions?: boolean;
  admissionExigences?: boolean;
  admissionSuspension?: boolean;
  descriptionOfficielleOrientations?: boolean;
  descriptionOfficielleAutres?: boolean;
  composantesConcentrations?: boolean;
  composantesMineures?: boolean;
  nombreCreditsAugmentation?: boolean;
  nombreCreditsReduction?: boolean;
  structureRepartitionCredits?: boolean;
  structureCours?: boolean;
}
export interface DemandeProgramme extends Demande {
  typeDemande: string;
  typeModification: DemandeProgrammeTypeModification;
  indicateursModificationProgramme?: IndicateursModificationProgramme;
  dateSeanceComiteProgramme: string;
  dateSeanceConseilFacultaire?: string;
  justification?: string;
  codeProgramme: string;
  codeMajeure?: string;
  titreMajeure?: string;
}
export default DemandeProgramme;
