import { TypeUnite } from '@/commun/services/set/model/unites';
import Faculte from '@/commun/services/set/model/faculte';

export const facultesMock = (): Faculte[] => [
  new Faculte('00', 'Aucune faculté désignée', '00', true, TypeUnite.FACULTE),
  new Faculte(
    '25',
    'Bureau formation à distance',
    '00',
    true,
    TypeUnite.FACULTE,
    'BFAD-Bureau de la formation à distance'
  ),
  new Faculte(
    '34',
    'Musique',
    '00',
    true,
    TypeUnite.FACULTE,
    'Faculté de musique'
  ),
  new Faculte(
    '36',
    'Sciences et génie',
    '00',
    true,
    TypeUnite.FACULTE,
    'Faculté de sciences et génie'
  ),
];
