<template>
  <v-toolbar class="elevation-0">
    <v-container fluid class="pa-0">
      <v-layout class="column">
        <div class="d-flex align-center">
          <v-toolbar-title>{{ $t('demande.tableau.titre') }}</v-toolbar-title>
          <v-row>
            <v-spacer />
            <v-col class="demandes-tableau-entete-desktop__recherche">
              <v-text-field
                :value="filtreRecherche"
                :hide-details="true"
                :label="$t('demande.tableau.actions.filtrer')"
                dense
                @input="$emit('update:filtre-recherche', $event)"
              />
            </v-col>
            <v-col v-if="afficherBoutonCreerDemande" cols="auto">
              <v-btn
                ref="bouton-creer-demande"
                class="ml-6"
                @click="onClickCreerDemande"
                >{{ $t('demande.tableau.actions.creer-demande') }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-layout>
    </v-container>
  </v-toolbar>
</template>

<script lang="ts">
  import { EnteteDemandesTableau } from '@/components/demandes/tableau/entete';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'DemandesTableauEnteteDesktop',
    mixins: [EnteteDemandesTableau],
    props: {
      filtreRecherche: {
        type: String,
        default: '',
      },
    },
  });
</script>

<style lang="scss">
  .demandes-tableau-entete-desktop {
    &__recherche {
      align-self: end;
    }
  }
</style>
